import { Container, Graphics, Point, Polygon } from 'pixi.js';
import { ModifyHandle, ModifyHandleShape } from '../modifyHandle';
import { AreaFormData } from './AreaFormData';
import { VisualItem } from '../editableVisuals/visualItem';



export class AreaForm extends VisualItem<AreaFormData> {

    private polygon: Polygon;
    private modifiers: Array<{vertex: ModifyHandle, followingDivider: ModifyHandle}> = [];
    private polyGraphics: Graphics;


    constructor(
        areaFormData: AreaFormData,
        visualsContainer: Container,
        sensorsContainer: Container,
        mainHandlesContainer: Container,
        onSelect: (item: AreaForm) => void, //when areaForm wants to be selected
        onUpdated: (areaFormData: AreaFormData) => void, //when image is changed/updated internally
    ) {
        super(
            areaFormData,
            visualsContainer,
            sensorsContainer,
            mainHandlesContainer,
            onSelect,
            onUpdated
        );

        this.polyGraphics = new Graphics();
        this.visualContainer.addChild(this.polyGraphics);

        const drawCenter = () => {
            let gr = new Graphics();
            gr.lineStyle({ width: 1, color: 65280, native: true });
            gr.drawCircle(0, 0, 2);
            this.visualContainer.addChild(gr);
        };
        drawCenter();

        this.setPolygon(new Polygon(areaFormData.points.slice()));  //since they come out of store, they're readonly
    }


    setPolygon(polygon) {
        this.polygon = polygon;
        this.polygon.closeStroke = true;
        for (var hi = 0; hi < this.polygon.points.length / 2; hi++) {
            const handle = new ModifyHandle(hi);
            handle.onMouseDown = (handle: ModifyHandle, event) => this.onVertexHandleDown(handle, event);
            this.handlesContainer.addChild(handle);
            const divider = new ModifyHandle(hi, ModifyHandleShape.DOT);
            divider.onMouseDown = (divider: ModifyHandle, event) => this.handleDividerDown(divider, event);
            this.handlesContainer.addChild(divider);
            this.modifiers.push({vertex: handle, followingDivider: divider});
        }
        this.normalizeCenter();
    }


    private normalizeCenter() {
        let minX = Number.MAX_VALUE, maxX = Number.MIN_VALUE, minY = Number.MAX_VALUE, maxY = Number.MIN_VALUE;
        for (let vi = 0; vi < this.polygon.points.length / 2; vi++) { //min & max X & Y...
            const x = this.polygon.points[vi * 2];
            if (x < minX) minX = x;
            if (x > maxX) maxX = x;
            const y = this.polygon.points[vi * 2 + 1];
            if (y < minY) minY = y;
            if (y > maxY) maxY = y;
        }

        const centerX = (minX + maxX) / 2; //center X & Y
        const centerY = (minY + maxY) / 2;

        this.visualContainer.x += centerX; //move holder by center value
        this.visualContainer.y += centerY;

        for (let vi = 0; vi < this.polygon.points.length / 2; vi++) { //move all vertices back by center value
            this.polygon.points[vi * 2] -= centerX;
            this.polygon.points[vi * 2 + 1] -= centerY;
        }
        this.drawPolyAndHandles();
    }


    private drawPolyAndHandles(): void {
        this.polyGraphics.clear();
        this.polyGraphics.beginFill(0xBBBBBB, 1);
        this.polyGraphics.drawPolygon(this.polygon);
        this.polyGraphics.endFill();
        this.updateModifiersPositions();
    }


    updateModifiersPositions() {
        const globalPolygon = new Polygon();
        for (let pointIndex = 0; pointIndex < this.modifiers.length; pointIndex++) {
            let {vertex, followingDivider} = this.modifiers[pointIndex];
            const globalPoint = this.handlesContainer.toLocal(
                new Point(this.polygon.points[pointIndex * 2],  //point in viewport
                    this.polygon.points[pointIndex * 2 + 1]),
                this.visualContainer
            );
            vertex.position = globalPoint;
            globalPolygon.points.push(globalPoint.x, globalPoint.y);
            const nextVertexIndex = (pointIndex + 1) % (this.polygon.points.length / 2);
            followingDivider.position = this.handlesContainer.toLocal(
                new Point(
                    (this.polygon.points[pointIndex * 2] 
                        + this.polygon.points[nextVertexIndex * 2]) / 2,
                    (this.polygon.points[pointIndex * 2 + 1] +
                        this.polygon.points[nextVertexIndex * 2 + 1]) / 2
                ),
                this.visualContainer
            );
        }
        this.mouseSensor.hitArea = globalPolygon;
        if (this.isSelected) {
            this.selectionMarquee.clear();
            this.selectionMarquee.lineStyle(5, 16777215, 1, 0.5, false);
            this.selectionMarquee.drawPolygon(globalPolygon);
            this.selectionMarquee.lineStyle(1.5, 6724095, 1, 0.5, false);
            this.selectionMarquee.drawPolygon(globalPolygon);
        }
    }

    private onVertexHandleDown(handle: ModifyHandle, event) {
        if (!this.isSelected) this.onSelect(this);  //later for selection handling
        handle.onMouseMove = (handle: ModifyHandle, event) => this.onVertexHandleMove(handle, event);
        handle.onMouseUp = (handle: ModifyHandle, event) => this.onVertexHandleUp(handle, event);
    }


    private onVertexHandleUp(handle: ModifyHandle, event) {
        const myX = this.polygon.points[handle.index * 2];
        const myY = this.polygon.points[handle.index * 2 + 1];

        const isNear = (index: number) => {
            const otherX = this.polygon.points[index * 2];
            const otherY = this.polygon.points[index * 2 + 1];
            const distance = Math.sqrt(Math.pow(otherX - myX, 2) + Math.pow(otherY - myY, 2));
            return distance < 5;
        };

        const prevVertexIndex: number = (handle.index - 1 + this.modifiers.length) % this.modifiers.length;
        if (isNear(prevVertexIndex)) this.deleteVertex(prevVertexIndex);
        else {
            const nextVertexIndex = (handle.index + 1 + this.modifiers.length) % this.modifiers.length;
            if (isNear(nextVertexIndex)) this.deleteVertex(nextVertexIndex);
        }
        this.normalizeCenter();
    }


    private onVertexHandleMove(handle: ModifyHandle, event) {
        const pInViewport: Point = this.visualContainer.toLocal(event.data.global);
        this.polygon.points[handle.index * 2] = pInViewport.x;
        this.polygon.points[handle.index * 2 + 1] = pInViewport.y;
        this.drawPolyAndHandles();
    }


    private handleDividerDown(divider: ModifyHandle, event) {
        if (!this.isSelected) this.onSelect(this);  //later for selection handling
        divider.handleDraggingMouseUp(null);
        const newIndex = divider.index + 1;
        const newVertexHandle = new ModifyHandle(newIndex, ModifyHandleShape.SQUARE);
        newVertexHandle.onMouseDown = (handle: ModifyHandle, event) => this.onVertexHandleDown(handle, event);
        const vertex = this.visualContainer.toLocal(divider.position, this.handlesContainer);
        this.polygon.points.splice(
            newIndex * 2,
            0,
            vertex.x,
            vertex.y
        );
        const newDivider = new ModifyHandle(divider.index + 1, ModifyHandleShape.DOT);
        newDivider.onMouseDown = (divider: ModifyHandle, event) => this.handleDividerDown(divider, event);
        this.modifiers.splice(newIndex, 0,
            {vertex: newVertexHandle, followingDivider: newDivider});
        this.handlesContainer.addChild(newVertexHandle, newDivider);
        for (let hi = 0; hi < this.modifiers.length; hi++) {
            this.modifiers[hi].vertex.index = this.modifiers[hi].followingDivider.index = hi; //reset all indices of handles and dividers
        }
        this.drawPolyAndHandles();
        newVertexHandle.handleDragPointerdown(null);
    }


    private deleteVertex(index: number) {
        this.polygon.points.splice(index * 2, 2);
        this.modifiers[index].vertex.removeFromParent();
        this.modifiers[index].followingDivider.removeFromParent();
        this.modifiers.splice(index, 1);
        for (let hi = 0; hi < this.modifiers.length; hi++) {
            this.modifiers[hi].vertex.index = this.modifiers[hi].followingDivider.index = hi; //re-set indices of handles and dividers
        }
    }
}
