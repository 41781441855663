import {
    AbstractIdleState,
    AbstractPointerDownState,
    DisplayContext,
    DRAGGING_START_THRESHOLD,
    DraggingState,
    DrawRectState,
    InteractionState
} from './common';
import {FederatedPointerEvent, DisplayObject} from 'pixi.js';
import {difference, distance, Point, sum} from '../../geometry';
import type {Selection} from '../data';
import {Seat} from '../../../types';
import Flatten from '@flatten-js/core';


export class SelectionIdleState extends AbstractIdleState {

    onPointerDown(context: DisplayContext, origin: Point, event: FederatedPointerEvent): InteractionState {
        const selection = context.dataManager.getCurrentSelection();
        // Der Benutzer interagiert evtl. mit den handles der Auswahl.
        if (selection && (event.target instanceof DisplayObject)
            && (selection.id === ((event.target as unknown) as DisplayObject).name))
        {
            return new SelectionRotateState(origin, selection);
        }

        const pickedSeat = context.dataManager.pickSeat(context.scene.convertToViewportCoords(origin));

        return new SelectionPointerDownState(origin, selection, pickedSeat);
    }

    onEviction(context: DisplayContext): void {
        context.dataManager.clearCurrentSelection();
    }

}

class SelectionPointerDownState extends AbstractPointerDownState {

    constructor(
        origin: Point,
        private selection: Selection | undefined,
        private pickedSeat: Readonly<Seat> | undefined
    ) {
        super(origin);
    }

    onPointerUp(context: DisplayContext): InteractionState {
        // Wenn der Benutzer "ins Leere" geklickt hat, also im vorausgehenden state erfolgte das
        // pointer-down event nicht über einem Seat, dann die aktuelle Auswahl aufheben.
        if (!this.pickedSeat) {
            context.dataManager.clearCurrentSelection();
            return new SelectionIdleState();
        }

        // Den angeklickten Seat zur neuen Auswahl machen.
        context.dataManager.selectSeats([this.pickedSeat]);
        return new SelectionIdleState();
    }

    onPointerMove(context: DisplayContext, current: Point): InteractionState {
        // Nur wenn eine bestimmte Auslenkung festgestellt wird eine Aktion ausführen,
        // um unbeabsichtigte Manipulation durch "Zucken" zu unterdrücken.
        if (distance(this.origin, current) < DRAGGING_START_THRESHOLD) {
            return this;
        }

        // Wenn der Benutzer "ins Leere" geklickt hat, also im vorausgehenden state erfolgte das
        // pointer-down event nicht über einem Seat, gehen wir zur Marquee Auswahl über.
        if (!this.pickedSeat) {
            return new SelectionMarqueeState(this.origin, this.selection);
        }

        // Falls der angeklickte Seat kein Teil der aktuellen Auswahl ist diesen zur neuen Auswahl machen.
        if (!this.selection?.contains(this.pickedSeat)) {
            this.selection = context.dataManager.selectSeats([this.pickedSeat]);
        }

        // übergehen zum Verschieben der Auswahl
        return new SelectionMoveState(this.origin, this.selection);
    }
}

export class SelectionMarqueeState extends DrawRectState {

    constructor(origin: Point, private selection: Selection | undefined) {
        super(origin, 0xFF6666);
    }

    protected processRect(rect: Flatten.Box, context: DisplayContext): InteractionState {
        if (this.selection) {
            context.dataManager.clearCurrentSelection();
        }

        context.dataManager.selectSeatsByMarquee(rect);
        return new SelectionIdleState();
    }
}

class SelectionMoveState extends DraggingState {

    /**
     * @param origin Der Startpunkt der Verschiebung.
     * @param selection Die aktuelle Auswahl.
     */
    constructor(
        protected readonly origin: Point,
        private readonly selection: Selection
    ) {
        super(origin);
    }

    /**
     * Verschiebt die Auswahl entsprechen der aktuellen pointer Position.
     *
     * @param commitTransform Flag ob die Transformation der Auswahl auch angewendet werden soll,
     * i.d.R nur sinnvoll, wenn die Verschiebung "abgeschlossen" ist.
     */
    private moveSelection(context: DisplayContext, commitTransform = false): void {
        const localOrigin = context.scene.convertToViewportCoords(this.origin);
        const localCurrent = context.scene.convertToViewportCoords(this.current);

        // Da es unwahrscheinlich ist, dass der Benutzer beim Verschieben der Auswahl diese
        // genau auf den anchor geklickt hat, können wir nicht einfach die Auswahl auf die aktuelle
        // Positions des pointer verschieben, da dies den Effekt hätte, das die Auswahl
        // abrupt auf die Position des pointer springt. Stattdessen addieren wir einfach den
        // offset zwischen aktueller und anfänglicher Position zum anchor.
        const newPosition = sum(
            this.selection.anchor,
            difference(localCurrent, localOrigin)
        );

        // Evtl. ist snap-to-grid o.Ä. aktiv, sodass die neue Position ggf. eingeschränkt werden muss.
        this.selection.move(context.constrainPosition(newPosition));

        if (commitTransform) {
            this.selection.commitTransform();
        }
    }

    protected abort(context: DisplayContext): void {
        context.dataManager.clearCurrentSelection();
        context.scene.toggleSeatAndRowLabelDisplay(true);
    }

    protected finish(context: DisplayContext): InteractionState {
        this.moveSelection(context, true);
        context.scene.toggleSeatAndRowLabelDisplay(true);
        return new SelectionIdleState();
    }

    protected update(context: DisplayContext): InteractionState {
        context.scene.toggleSeatAndRowLabelDisplay(false);  //lieber bei onInstallation statt bei jedem update?
        this.moveSelection(context);
        return this;
    }

}

class SelectionRotateState extends DraggingState {

    /**
     * @param origin Der Startpunkt der Drehung.
     * @param selection Die aktuelle Auswahl.
     */
    constructor(
        protected readonly origin: Point,
        private readonly selection: Selection
    ) {
        super(origin);
    }

    /**
     * Dreht die Auswahl entsprechen der aktuellen pointer Position.
     *
     * @param commitTransform Flag ob die Transformation der Auswahl auch angewendet werden soll,
     * i.d.R nur sinnvoll, wenn die Drehung "abgeschlossen" ist.
     */
    private rotateSelection(context: DisplayContext, commitTransform = false): void {
        this.selection.rotate(this.origin, this.current);

        if (commitTransform) {
            this.selection.commitTransform();
        }
    }

    protected abort(context: DisplayContext): void {
        context.dataManager.clearCurrentSelection();
    }

    protected finish(context: DisplayContext): InteractionState {
        context.scene.toggleSeatAndRowLabelDisplay(true);
        this.rotateSelection(context, true);
        return new SelectionIdleState();
    }

    protected update(context: DisplayContext): InteractionState {
        context.scene.toggleSeatAndRowLabelDisplay(false);
        this.rotateSelection(context);
        return this;
    }
}
