import {RenderableSeat, SEAT_GRID_STEP_SIZE} from './common';
import {Seat} from '../../../types';
import {CurrentSelection, Selection} from './selection';
import {BitmapText, Container, Graphics, Sprite} from 'pixi.js';
import {createSeatSprite, Scene, SEAT_DEFAULT_COLOR, SEAT_RADIUS} from '../scene';
import {calculateBoundingBox, distance, Point, PointGrid} from '../../geometry';
import ObjectID from 'bson-objectid';
import {compact, forEach, filter, values} from 'lodash';
import Flatten from '@flatten-js/core';
import {DispatchInteractionEvent} from '../interaction/event';
import {LABEL_BITMAP_FONT} from '../fonts';
import {RowLabelVisibilityMode} from '../labels/rows';

const LABEL_PADDING = 5;

function makePreliminarySeatsLabelText(rows: number, columns: number): string {
    return `${rows} × ${columns}`;
}

export class DataManager {

    private readonly seats: Record<string, RenderableSeat> = {};

    private preliminarySeats: Seat[] = [];

    private currentSelection?: CurrentSelection;

    private readonly display = {
        preliminarySeats: new Container(),
        preliminarySeatsLabel: {
            root: new Container(),
            text: new BitmapText(makePreliminarySeatsLabelText(0, 0), {fontName: LABEL_BITMAP_FONT}),
            background: new Graphics()
        }
    }

    constructor(private readonly scene: Scene, private readonly dispatch: DispatchInteractionEvent) {
        this.scene.overlay.root.addChild(this.display.preliminarySeatsLabel.root);
        this.scene.viewport.overlay.addChild(this.display.preliminarySeats);

        this.display.preliminarySeatsLabel.root.addChild(this.display.preliminarySeatsLabel.background);
        this.display.preliminarySeatsLabel.root.addChild(this.display.preliminarySeatsLabel.text);

        this.display.preliminarySeats.visible = false;
        this.display.preliminarySeatsLabel.root.visible = false;
    }

    private updatePreliminarySeatsLabel(grid: PointGrid): void {
        this.display.preliminarySeatsLabel.root.visible = grid.rows > 4 && grid.columns > 4;

        const bb = calculateBoundingBox(Array.from(grid.points));
        const labelPos = this.scene.convertFromViewportCoords(bb.center);

        const text = this.display.preliminarySeatsLabel.text;
        text.text = makePreliminarySeatsLabelText(grid.rows, grid.columns);
        text.anchor.set(0.5);
        text.x = labelPos.x;
        text.y = labelPos.y;

        const background = this.display.preliminarySeatsLabel.background;
        background.clear();
        background.lineStyle(0);
        background.beginFill(SEAT_DEFAULT_COLOR);
        background.drawShape(text.getBounds().pad(LABEL_PADDING));
    }

    private createSeatGraphics(seat: Seat): Sprite {
        const sprite = createSeatSprite(seat.style, seat.color);
        sprite.position.copyFrom(seat);

        return sprite;
    }

    /**
     * Eine gegebene Funktion für jeden verwalteten Seat ausführen.
     */
    forEachSeat(callback: (seat: Seat) => void): void {
        forEach(this.seats, callback);
    }

    addSeats(seats: Iterable<Seat>) {
        for (const s of seats) {
            const seat = new RenderableSeat(s);
            this.seats[seat.id] = seat;
            this.scene.viewport.seats.addChild(seat.sprite);
        }
    }

    removeSeats(seats: Iterable<Seat>) {
        for (const s of seats) {
            this.scene.viewport.seats.removeChild(this.seats[s.id].sprite);
            delete this.seats[s.id];
        }
    }

    updateSeats(seats: Iterable<Seat>) {
        for (const s of seats) {
            // FIXME: absichern, falls nichts mit der ID gefunden wird? Aber Typsystem beschwert sich bisher nicht...?
            const seat = this.seats[s.id];
            if (!seat) continue; //perhaps a newly created seat
            seat.tags = s.tags;
            seat.position = {x: seat.x, y: seat.y};
            seat.color = s.color;
            seat.area = s.area;
            seat.label = s.label;
            seat.row = s.row;
            seat.enabled = s.enabled;
            seat.available = s.available;
            seat.seatingTypeId = s.seatingTypeId;
            seat.pricingCategoryId = s.pricingCategoryId;
            seat.blockId = s.blockId;
        }
    }

    addPreliminarySeats(origin: Point, control: Point): void {
        const localOrigin = this.scene.convertToViewportCoords(origin);
        const localControl = this.scene.convertToViewportCoords(control);

        this.removePreliminarySeats();
        this.display.preliminarySeatsLabel.root.visible = true;
        this.display.preliminarySeats.visible = true;

        const grid = new PointGrid(localOrigin, localControl, SEAT_GRID_STEP_SIZE);

        for (let p of grid.points) {
            const newId = ObjectID.generate();
            const seat: Seat = {
                id: newId,
                // FIXME: Durch echte Funktionalität ersetzen
                area: '',
                row: '',
                label: '',
                enabled: true,
                available: true,
                tags: [],
                style: 'AVAILABLE',
                color: SEAT_DEFAULT_COLOR,
                pricingCategoryId: '',
                seatingTypeId: '',
                blockId: '',
                ...p
            }

            this.preliminarySeats.push(seat);

            const sprite = this.createSeatGraphics(seat);

            this.display.preliminarySeats.addChild(sprite);
        }

        this.updatePreliminarySeatsLabel(grid);
    }

    requestAddSeats(origin: Point, control: Point): void {
        const localOrigin = this.scene.convertToViewportCoords(origin);
        const localControl = this.scene.convertToViewportCoords(control);
        const grid = new PointGrid(localOrigin, localControl, SEAT_GRID_STEP_SIZE);
        this.dispatch({type: 'SEATS_ADD_REQUESTED', pointGrid: grid});
    }

    removePreliminarySeats() {
        this.display.preliminarySeatsLabel.root.visible = false;
        this.display.preliminarySeats.visible = false;
        this.preliminarySeats = [];
        this.display.preliminarySeats.removeChildren();
    }

    //probably obsolete
    instantiatePreliminarySeats(): Seat[] {
        const seats = this.preliminarySeats;

        this.preliminarySeats = [];

        this.display.preliminarySeatsLabel.root.visible = false;
        this.display.preliminarySeats.visible = false;

        this.addSeats(seats);

        this.dispatch({type: 'SEATS_ADDED', seats});

        return seats;
    }

    pickSeat(at: Point): Readonly<Seat> | undefined {
        let minDistance = Number.MAX_VALUE;
        let closestSeat = undefined;

        forEach(this.seats, seat => {
            const d = distance(at, seat);

            if (d < minDistance) {
                minDistance = d;
                closestSeat = seat;
            }
        })

        if (closestSeat && minDistance < SEAT_RADIUS) {
            return closestSeat;
        }

        return undefined;
    }

    selectSeats(seats: Seat[]): Selection {
        const selectedSeats = compact(seats.map(s => this.seats[s.id]));

        if (!selectedSeats.length) {
            throw new Error('you need to select at least one seat');
        }

        this.currentSelection?.clear();

        this.currentSelection = new CurrentSelection(ObjectID.generate(), selectedSeats, this.scene, this.dispatch);

        this.dispatch({type: 'SEATS_SELECTED', seatIds: this.currentSelection.seats.map(s => s.id)});

        return this.currentSelection;
    }

    /**
     * Erzeugt eine Auswahl über alle Sitzplätze, die sich in einem Auswahlbereich befinden.
     *
     * @param box Der Auswahlbereich
     *
     * @return Die Auswahl über alle Sitzplätze im Auswahlbereich, oder undefined,
     *         wenn sich keine Sitzplätze im Auswahlbereich befinden.
     */
    selectSeatsByMarquee(box: Flatten.Box): Selection | undefined {
        // FIXME: Die brute-force Suche hier funktioniert ist aber ist bei größeren Mengen evtl. nicht performant genug.
        // Bei Performanceproblemen ggf. geeignete Datenstrukturen wie Spatial-Hash, Interval-Tree, etc... einsetzen.
        const seats = filter(this.seats, seat => {
            return box.xmin <= seat.x
                && box.xmax >= seat.x
                && box.ymin <= seat.y
                && box.ymax >= seat.y
        });

        if (seats.length) {
            return this.selectSeats(seats);
        }
    }

    getCurrentSelection(): Selection {
        return this.currentSelection;
    }

    clearCurrentSelection(): void {
        this.currentSelection?.clear();
        this.currentSelection = undefined;
        this.dispatch({type: 'SEATS_UNSELECTED'})
    }

    /**
     * Liefert die Daten der aktuell ausgewählten Sitzplätze.
     */
    getSelectedSeats(): Seat[] {
        return (this.currentSelection?.seats ?? []).map(s => ({
            id: s.id,
            area: s.area,
            row: s.row,
            label: s.label,
            enabled: s.enabled,
            available: s.available,
            tags: s.tags,
            style: s.style,
            color: s.color,
            x: s.x,
            y: s.y,
            seatingTypeId: s.seatingTypeId,
            pricingCategoryId: s.pricingCategoryId,
            blockId: s.blockId
        }));
    }

    /**
     * Entfernt die aktuell ausgewählten Sitzplätze aus dem Saalplan.
     */
    deleteSelectedSeats(): void {
        this.currentSelection?.deleteSeats();

        // FIXME: CurrentSelection.deleteSeats() löscht nur die Seats aus der Anzeige, aber sie müssen auch
        //        noch aus den Daten gelöscht werden. Idealerweise würde das in einem Aufwasch passieren.
        this.currentSelection?.seats.forEach(seat => {
            delete this.seats[seat.id];
        })

        this.currentSelection = undefined;
        this.dispatch({type: 'SEATS_UNSELECTED'});
    }

    /**
     * Die Reihen- und Seat-Labels aktualisieren.
     */
    refreshRowAndSeatLabels(rowLabelVisibilityMode: RowLabelVisibilityMode, showSeatLabels: boolean): void {
        const seats = values(this.seats);
        this.scene.updateRowLabels(seats, rowLabelVisibilityMode);
        this.scene.updateSeatLabels(seats, showSeatLabels);
    }

}
