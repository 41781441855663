import React, {useRef} from 'react';
import {Form} from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import {German} from 'flatpickr/dist/l10n/de.js';
import {ErrorMessage, Field} from 'formik';
import {get} from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const getAltFormat = (enableTime, noCalendar) => {
    if(enableTime){
        if(noCalendar){
            return 'H:i';
        } else {
            return 'd.m.Y H:i';
        }
    } else {
        return 'd.m.Y';
    }
};

const flatpickrOptions = (enableTime, noCalendar, isInvalid) => ({
    wrap: true,
    allowInput: true,
    enableTime,
    altInput: true,
    altFormat: getAltFormat(enableTime, noCalendar),
    altInputClass: classnames('form-control', {'is-invalid': isInvalid}),
    locale: German,
    noCalendar: noCalendar,
    time_24hr: true,
    position: noCalendar ? "above center" : "auto",
    minuteIncrement: 1,
});

export function FormikDateTimeInput({name, label, enableTime = false, noCalendar=false, inputRef, disabled, testid, onChange = () => {}}) {
    const refDateInput = useRef(null);

    const handleChange = (form) => ([date]) => {
        if(noCalendar){
            onChange(date)
        } else {
            form.setFieldTouched(name, date, false);
            form.setFieldValue(name, date);
        }
    };

    const isInvalid = (form) => get(form.errors, name) && get(form.touched, name);

    const clearDate = () => {
        refDateInput.current.flatpickr.clear();
    }

    return (
        <Field name={name}>
            {({field, form}) =>
                <Form.Group className='MuiFormControl-root-custom'>
                    {label && <label className='MuiFormLabel-root-custom'>{label}</label>}
                    {/*
                        Da die yup Validierung asynchron erfolgt erzwingen wir durch Kombination von value und
                        isInvalid() das erneute rendern des Pickers, da sonst Anzeige und Wert divergieren können,
                        bspw. Wert ausgewählt aber Feld als invalid markiert, weil vorher das Feld noch leer war.
                    */}
                    <Flatpickr data-testid={testid} key={name + '/' + isInvalid(form)}
                               value={field.value}
                               onChange={handleChange(form)}
                               options={flatpickrOptions(enableTime, noCalendar, isInvalid(form))}
                               ref={refDateInput}
                               className="date-time-body"
                    >
                        {/* <span className={'date-clear-icon'} onClick={clearDate}> X </span> */}
                        <input type="text" disabled={disabled} className="form-control" data-input/>
                        {(field.value) ? (
                            <IconButton
                                onClick={clearDate}
                                className={'date-clear-icon'}
                            >
                                <CancelIcon />
                            </IconButton>
                        ) : (
                            <IconButton
                                onClick={() => {
                                    refDateInput.current.flatpickr.open();
                                }}
                                className={'date-clear-icon'}
                            >
                                <CalendarMonthIcon />
                            </IconButton>
                        )}
                    </Flatpickr>

                    <small className="form-text text-danger"  ref={inputRef}>
                        <ErrorMessage name={field.name}/>
                    </small>
                    <input ref={inputRef} className="hide"></input>
                </Form.Group>
            }
        </Field>
    );
}

FormikDateTimeInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    enableTime: PropTypes.bool
};

export default FormikDateTimeInput;
