import React, { useState, useRef, useEffect } from 'react';
import Navbar from './Navbar';
import Menu from './Menu';
import MenuItem from './MenuItem';
import styles from './layout.module.scss';
import Sidebar from './Sidebar';
import { NavConfig } from './routeConfig';
import { useLocation } from 'react-router-dom';

export default ({ children }) => {
	const [navOpen, setNavOpen] = useState(false);
    const [activeRoute, setActiveRoute] = useState(null);
    const menuRef = useRef(null);
    const menuWrapRef = useRef(null);
    const location = useLocation();
    const isHideNavigation = location.pathname.includes('/editor/ui');

	const toggleNavOpen = () => setNavOpen(!navOpen);

    const handleOutsideClick = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setNavOpen(false);
        }
    };

    const handleOnClickIcon = (setIsExpanded, route) => {
        toggleNavOpen();
        setActiveRoute(route);
        setIsExpanded(false);
    }

    // Click on Nav bar wrap
    const onHandleTogleNav = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (!menuWrapRef.current.contains(event.target)) {
            toggleNavOpen();
        }
    }

    const handleActiveCategory = (route, setIsExpanded) => {
        setIsExpanded(true);
        setActiveRoute(route);
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    if (isHideNavigation) {
        return (
            <div style={{ overflow: 'hidden' }}>{children}</div>
        )
    }

    return (
        <div className={styles.App}>
            <Navbar className={styles.Navbar}>
                {/* More navbar items */}
            </Navbar>

            <Menu
                innerRef={menuRef}
                menuWrapRef={menuWrapRef}
                className={styles.Menu}
                isOpen={navOpen}
                toggle={() => toggleNavOpen()}
                onClick={onHandleTogleNav}
            >
                {NavConfig.map((menuConfig, index) => {
                    const isActive = activeRoute === menuConfig.route;
                    return (
                        <MenuItem
                            key={index}
                            route={menuConfig.route}
                            icon={menuConfig.icon}
                            label={menuConfig.label}
                            testid={menuConfig.testid}
                            forceClosed={navOpen}
                            setActiveCategory={handleActiveCategory}
                            handleOnClickIcon={handleOnClickIcon}
                            showIcon={menuConfig.showIcon}
                            isCategory={menuConfig.isCategory}
                        >
                            {isActive && menuConfig.children.map((child, childIndex) => {
                                return (
                                    <MenuItem
                                        key={childIndex}
                                        route={child.route}
                                        label={child.label}
                                        testid={child.testid}
                                    />
                                )
                            })}
                        </MenuItem>
                    );
                })}
            </Menu>

            <div className={['container', styles.FooterSpace].join(' ')}>
                {children}
            </div>

            <Sidebar />
        </div>
    )
};
