import { Container, Graphics, Point } from "pixi.js";
import { VisualItemData } from "./visualItemData";


export abstract class VisualItem<DataType extends VisualItemData> {

    private moveCenterOffset = new Point(0, 0);

    protected itemData: DataType;
    protected visualContainer: Container;
    protected mouseSensor: Container;
    protected handlesContainer: Container;
    protected isSelected = false;
    protected selectionMarquee: Graphics;



    constructor(
        itemData: DataType,
        visualsContainer: Container,
        sensorsContainer: Container,
        mainHandlesContainer: Container,
        protected onSelect: (item: VisualItem<DataType>) => void, //when image wants to be selected
        protected onUpdated: (item: DataType) => void //when image is changed/updated internally
    ) {
        this.itemData = {...itemData};
        this.visualContainer = new Container();
        visualsContainer.addChild(this.visualContainer);
        this.handlesContainer = new Container();
        this.selectionMarquee = new Graphics();
        this.handlesContainer.addChild(this.selectionMarquee);

        this.mouseSensor = new Container();
        this.mouseSensor.eventMode = "static";
        this.mouseSensor.onpointerdown = (event) => this.onItemPointerDown(event);
        sensorsContainer.addChild(this.mouseSensor);
        mainHandlesContainer.addChild(this.handlesContainer);
        this.setSelected(false);
    }


    abstract updateModifiersPositions();


    setSelected(select: boolean) {
        this.isSelected = select;
        this.selectionMarquee.visible = this.isSelected;
        this.handlesContainer.visible = this.isSelected;
        if (this.isSelected) this.updateModifiersPositions();
    }


    getItemData(): DataType {
        return this.itemData;
    }


    private onItemPointerDown(event) {
        if (!this.isSelected) this.onSelect(this);
        this.moveCenterOffset = this.visualContainer.toLocal(event.global);
        this.mouseSensor.onglobalpointermove = (event) => this.onItemDraggingMouseMove(event);
        this.mouseSensor.onpointerup = this.mouseSensor.onpointerupoutside = (
            event
        ) => this.onItemDraggingMouseUp(event);
    }


    private onItemDraggingMouseMove(event) {
        let pLocalMouse = this.visualContainer.toLocal(event.data.global);
        let pDiff = new Point();
        pLocalMouse.subtract(
            this.moveCenterOffset,
            pDiff
        );
        this.visualContainer.position.add(pDiff, this.visualContainer.position);
        this.updateModifiersPositions();
    }


    private onItemDraggingMouseUp(event) {
        this.mouseSensor.onpointerup =
            this.mouseSensor.onpointerupoutside =
            this.mouseSensor.onglobalpointermove =
            null;
        this.itemData.x = this.visualContainer.x;
        this.itemData.y = this.visualContainer.y;
        this.onUpdated(this.itemData);
    }


    dispose() {
        this.mouseSensor.removeFromParent();
        this.handlesContainer.removeFromParent();
        this.visualContainer.removeFromParent();
    }
}
