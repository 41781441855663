import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import { Seat, SeatRecord } from '../../../seating/types';
import { IPlacepool } from '../../../seating/types/Placepool';
import { ImageData } from '../../../seating/editor/display/images/ImageData';
import { InteractionMode } from '../../../seating/editor/display/interaction';
import { VenuePlanSettings } from '../../../seating/types/VenuePlanInformation';
import { AreaFormData } from '../../../seating/editor/display/areaForms/AreaFormData';
import { VenuePlan } from '../../../seating/types/VenuePlan';


const entityName = 'venueEditor';


interface ISliceData {
    isLoaded: boolean;
}



export interface IVenuePlanSliceData extends ISliceData {
    venuePlan: VenuePlan
}

export const venuePlan = createSlice({
    name: "venuePlan",
    initialState: {venuePlan: {}, isLoaded: false} as IVenuePlanSliceData,
    reducers: {
        loadVenuePlan: (state, {payload}) => {
            state.isLoaded = false;
            return state;
        },
        loadVenuePlanSuccess: (state, {payload}) => {
            return({
                venuePlan: {
                    id: payload.id,
                    name: payload.name,
                    backendName: payload.backendName
                },
                isLoaded: true
            });
        },
        loadVenuePlanError: (state, {payload}) => {
            state.isLoaded = false;
            return state;
        }
    }
});


export interface IVenuePlanSettingsSliceData extends ISliceData {
    venuePlanSettings: VenuePlanSettings
}

export const venuePlanSettings = createSlice({
    name: "venuePlanSettings",
    initialState: {venuePlanSettings: {}, isLoaded: false} as IVenuePlanSettingsSliceData,
    reducers: {
        loadVenuePlanSettings: (state, { payload: venuePlanId } : PayloadAction<string>) => {
            state.isLoaded = false;
            return state;
        },
        loadVenuePlanSettingsError: () => {},
        loadVenuePlanSettingsSuccess: (state, { payload: venuePlanSettings } : PayloadAction<VenuePlanSettings>) => {
            return ({
                venuePlanSettings,
                isLoaded: true
            });
        },
        updateVenuePlanSettings: (state, action: PayloadAction<{venuePlanId: string, venuePlanSettings: VenuePlanSettings}>) => {
            state.venuePlanSettings = action.payload.venuePlanSettings;
            return state;
        },
        updateVenuePlanSettingsSuccess: (state, { payload: venuePlanSettings } : PayloadAction<VenuePlanSettings>) => {
            return state;
        },
        updateVenuePlanSettingsError: (state, { payload: venuePlanSettings } : PayloadAction<VenuePlanSettings>) => {
            return state;
        }
    }
});


export const isApiRequestPending = createSlice({
    name: "isApiRequestPending",
    initialState: false,
    reducers: {
        setIsApiRequestPending: (state, {payload: isPending}: PayloadAction<boolean>) => {
            return isPending;
        }
    }
});


export const allSeats = createSlice({
    name: entityName,
    initialState: {} as SeatRecord,
    reducers: {
        loadAllSeats: () => {},
        loadAllSeatsError: () => {},
        loadAllSeatsSuccess: (state, { payload: seats } : PayloadAction<SeatRecord>) => {
            return seats;
        },
        updateSomeSeats: (state, { payload: updatedSeats } : PayloadAction<Seat[]>) => {
            //unbedingt hier neues Seat-Objekt erstellen, da z.B. VenuPLanDisplay teilweise RenderableSeat zurückkommt,
            //wo z.B. x,y nur noch readonly sind.            
            return updatedSeats.reduce((allSeats: SeatRecord, seat: Seat): SeatRecord => {
                let newSeat: Seat = {  //don't use spread op., it will stumble about readonlys x,y of position
                    id: seat.id,
                    tags: seat.tags,
                    x: seat.x,
                    y: seat.y,
                    color: seat.color,
                    style: seat.style,
                    area: seat.area,
                    label: seat.label,
                    row: seat.row,
                    enabled: seat.enabled,
                    available: seat.available,
                    seatingTypeId: seat.seatingTypeId,
                    pricingCategoryId: seat.pricingCategoryId,
                    blockId: seat.blockId,
//                    publicId: seat.publicId
                 };
                allSeats[seat.id] = newSeat;
                return allSeats;
            }, state);
        },
        updateSomeSeatsError: (state, { payload: {seats, error} }) => {
            return state;
        },
        updateSomeSeatsSuccess: (state, { payload }) => {
            return state;
        },
        addSeats: (state, { payload} : PayloadAction<Seat[]>) => {
            return state;
        },
        addSeatsSuccess: (state, { payload: newSeats } : PayloadAction<Seat[]>) => {
            state = newSeats.reduce((allSeats: SeatRecord, seat: Seat): SeatRecord => {
                allSeats[seat.id] = seat;
                return allSeats;
            }, {...state});
            return state;
        },
        addSeatsError: (state, { payload } : PayloadAction<Array<Seat>>) => {
            return state;
        },
        deleteSeats: (state, {payload: seatsToDelete} : PayloadAction<Seat[]>) => {
            const newState = {...state};
            seatsToDelete.forEach(seat => delete newState[seat.id]);
            return newState;
        },
        deleteSeatsSuccess: (state, { payload } : PayloadAction<Seat[]>) => {
            return state;
        },
        deleteSeatsError: (state, { payload } : PayloadAction<Seat[]>) => {
            return state;
        }
    }
});


export const newAddedSeats = createSlice({
    name: entityName,
    initialState: [],
    reducers: {
        addNewAddedSeats: (state, {payload: newSeats}: PayloadAction<Seat[]>) => {
            const newSeatsArray = state.slice().concat(newSeats);
            return newSeatsArray;
        },
        purgeNewAddedSeats: () => []
    }
})


export const isSeatsLoaded = createSlice({
    name: entityName,
    initialState: false,
    reducers: {
        setIsSeatsLoaded: (state, { payload: loaded } : PayloadAction<boolean> ) => {
            return loaded;
        }
    }
});


export const selectedSeatIds = createSlice({
    name: entityName,
    initialState: [] as string[],
    reducers: {
        setSelectedSeats: (state, { payload: seats } : PayloadAction<Seat[]>) => {
            if (!seats.length) return [];
            const selIds = seats.map(seat => seat.id);
            return selIds;
        }
    }
});


export const interactionMode = createSlice({
    name: entityName,
    initialState: "SELECT",
    reducers: {
        setInteractionMode: (state, { payload } : PayloadAction<InteractionMode>) => payload
    }
});


export const moveOnGrid = createSlice({
    name: entityName,
    initialState: false,
    reducers: {
        setMoveOnGrid: (state, { payload } : PayloadAction<boolean>) => payload
    }
});


export const placepoolDefinitions = createSlice({
    name: entityName,
    initialState: [],
    reducers: {
        loadPlacepoolDefinitions: () => [],
        loadPlacepoolDefinitionsError: () => [],
        loadPlacepoolDefinitionsSuccess: (state, { payload: placepools } : PayloadAction<IPlacepool[]>) => {
            // Als payload wird eine "normalizr" response erwartet
            const plplColors = [
                'FFD700', 'FF3333', '0044FF', 'A52A2A', '008000', '000080', 'FF00FF', '00FFFF',
                '6E0DD0', 'FFA07A', '008080', '800080', '7CFC00', '6A5ACD', '4682B4', '808000',
                'FFA500', '20B2AA', 'DB7093', 'B0C4DE', '32CD32', 'FA8072', 'ADFF2F', '9370DB',
                'FFFF00', '800000', '8A2BE2', 'FF4500', '4B0082', 'FF8C00', 'FF6347', '7B68EE',
                '808080'
            ];
            if (placepools) {
                for (let pi = 0; pi < placepools.length; pi++) {
                    placepools[pi].color = plplColors[pi];
                }
            }
            return placepools;
        }
    }
});


export const placeCategories = createSlice({
    name: entityName,
    initialState: [],
    reducers: {
        loadPlaceCategories: () => [],
        loadPlaceCategoriesError: (state, { payload }) => {
            console.log("error: " + payload);
            return [];
        },
        loadPlaceCategoriesSuccess: (state, { payload: placeCategories }) => {
            // Als payload wird eine "normalizr" response erwartet
            return placeCategories;
        }
    }
});


export const seatingTypes = createSlice({
    name: entityName,
    initialState: [],
    reducers: {
        loadSeatingTypes: () => [],
        loadSeatingTypesError: () => [],
        loadSeatingTypesSuccess: (state, { payload: seatingTypes }) => {
            // Als payload wird eine "normalizr" response erwartet
            return seatingTypes;
        }
    }
});


export const blocks = createSlice({
    name: entityName,
    initialState: [],
    reducers: {
        loadBlocks: () => [],
        loadBlocksError: () => [],
        loadBlocksSuccess: (state, { payload: blocks }) => {
            // Als payload wird eine "normalizr" response erwartet
            return blocks;
        }
    }
});


export const images = createSlice({
    name: entityName,
    initialState: [] as ImageData[],
    reducers: {
        loadImages: () => [],
        loadImagesError: () => [],
        loadImagesSuccess: (state, { payload: images } : PayloadAction<ImageData[]>) => {
            return images;
        },
        updateImages: (state, { payload: updatedImages } : PayloadAction<ImageData[]>) => {
            const newState = state.map(image => {
               const updatedImage = updatedImages.find(updated => updated.id === image.id);
               const newImage = updatedImage || image;
               return {...newImage};
            });
            return newState;
        },
        updateImagesError: (state, { payload } : PayloadAction<ImageData>) => {
            //TODO: show error and  reload editor
            return state;
        },
        updateImagesSuccess: (state, { payload } : PayloadAction<ImageData>) => {
            return state;
        },
        addImages: (state, { payload: newImages } : PayloadAction<ImageData[]>) => {
            return state.slice().concat(newImages);
        },
        addImagesSuccess: (state, { payload } : PayloadAction<ImageData>) => {
            return state;
        },
        addImagesError: (state, { payload } : PayloadAction<ImageData>) => {
            return state;
        },
        deleteImages: (state, { payload: imagesToDelete } : PayloadAction<ImageData[]>) => {
            const imageIdsToDelete: string[] = imagesToDelete.map(image => image.id);
            return state.filter(image => !(imageIdsToDelete.includes(image.id)));
        },
        deleteImagesSuccess: (state, { payload } : PayloadAction<ImageData>) => {
            return state;
        },
        deleteImagesError: (state, { payload } : PayloadAction<ImageData>) => {
            return state;
        }
    }
});


export interface IAreaFormsSliceData extends ISliceData {
    areaForms: Record<string, AreaFormData>,
    newAddedAreaForms: Record<string, AreaFormData>
};

export const areaForms = createSlice({
    name: "areaForms",
    initialState: {areaForms: {}, newAddedAreaForms: {}, isLoaded: false} as IAreaFormsSliceData,
    reducers: {
        loadAreaForms: (state) => ({...state, isLoaded: false}),
        loadAreaFormsError: state => state,
        loadAreaFormsSuccess: (state, {payload: loadedAreaForms} : PayloadAction<Record<string, AreaFormData>>) => {
            return {
                areaForms: loadedAreaForms,
                newAddedAreaForms: {},
                isLoaded: true
            }
        },
        addAreaForm: (state, {payload: newAreaForm}: PayloadAction<AreaFormData>) => state,
        addAreaFormError: state => state,
        addAreaFormSuccess: (state, {payload: newAreaForm}: PayloadAction<AreaFormData>) => (
            {
                ...state,
                areaForms: {...state.areaForms, [newAreaForm.id]: newAreaForm},
                newAddedAreaForms: {...state.newAddedAreaForms, [newAreaForm.id]: newAreaForm}
            }
        ),
        removeFromNewAddedAreaForms: (state, {payload: areaForms}: PayloadAction<Record<string, AreaFormData>>) => {
            for (const ai in areaForms) delete state.newAddedAreaForms[ai];
            return state;
        },
        updateAreaForms: (state, {payload: updatedAreaForms}: PayloadAction<AreaFormData[]>) => (
            {
                ...state,
                areaForms: updatedAreaForms.reduce((areaForms: Record<string, AreaFormData>, updAF) => {
                    areaForms[updAF.id] = updAF;
                    return areaForms;
                }, state.areaForms)
            }
        ),
        updateAreaFormsError: (state, { payload } : PayloadAction<AreaFormData>) => {
            return state;
        },
        updateAreaFormsSuccess: (state, { payload } : PayloadAction<AreaFormData>) => {
            return state;
        },
        deleteAreaForms: (state, { payload: areaFormsToDelete } : PayloadAction<AreaFormData[]>) => {
            state.areaForms = areaFormsToDelete.reduce((areaForms, delAF) => {
                delete areaForms[delAF.id];
                return areaForms;
            }, state.areaForms);
            return state;
        },
        deleteAreaFormsSuccess: (state, { payload } : PayloadAction<AreaFormData>) => {
            return state;
        },
        deleteAreaFormsError: (state, { payload } : PayloadAction<AreaFormData>) => {
            return state;
        }
    }
});


export const fatalAPIError = createSlice({
    name: entityName,
    initialState: '',
    reducers: {
        setError: (state, { payload: errorMessage } : PayloadAction<string>) =>  {
            return errorMessage;
        }
    }
});
