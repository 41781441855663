import React from 'react';
import { Field, getIn, FormikProps, FieldProps } from 'formik';
import {
    FormControl,
    InputLabel,
    Select as MuiSelect,
    MenuItem,
    FormHelperText
} from '@mui/material';
import { isEmpty } from 'lodash';
import CheckIcon from '@mui/icons-material/Check';

interface Option {
    id: string;
    name: string;
}

interface FormikSelectProps {
    name: string;
    label: string;
    setActiveKey?: string;
    options: Option[];
    emptyValueLabel?: string;
    isShowEmptyValue?: boolean;
    displayEmpty?: boolean;
    disabled?: boolean;
}

interface FormValues {
    [field: string]: string;
}

export const FormikSelect: React.FC<FormikSelectProps> = ({
    name,
    label,
    options,
    emptyValueLabel = 'Bitte wählen...',
    isShowEmptyValue = true,
    displayEmpty = true,
    disabled,
    setActiveKey='id',
    ...rest
}) => {
    return (
        <Field name={name}>
            {({ field, form }: FieldProps<string, FormikProps<FormValues>>) => {
                const errorMessage = getIn(form.errors, field.name);
                const isInvalid = getIn(form.touched, field.name) && !!errorMessage;

                return (
                    <FormControl fullWidth error={isInvalid} variant="outlined">
                        <InputLabel>{label}</InputLabel>
                        <MuiSelect
                            {...field}
                            {...rest}
                            label={label}
                            displayEmpty={displayEmpty}
                            renderValue={(selected) => {
                                if (!selected) {
                                    return <em>{emptyValueLabel}</em>;
                                }
                                const selectedOption = options.find(option => option.id === selected);
                                return selectedOption ? selectedOption.name : selected;
                            }}
                            disabled={!!disabled}
                            onChange={(event) => {
                                const selectedValue = event.target.value === '' ? null : event.target.value;
                                form.setFieldValue(name, selectedValue);
                            }}
                        >
                            {isShowEmptyValue && (
                                <MenuItem value="">
                                    <em>{emptyValueLabel}</em>
                                </MenuItem>
                            )}
                            {options.map(({ name, id }) => {
                                return (
                                    <MenuItem key={id} value={id}>
                                        {field.value === id && <CheckIcon />}
                                        {name}
                                    </MenuItem>
                                )
                            })}
                        </MuiSelect>
                        {isInvalid && <FormHelperText>{errorMessage}</FormHelperText>}
                    </FormControl>
                );
            }}
        </Field>
    )

};

export default FormikSelect
