import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { FormikTextInputGroup } from '../common/formik/FormikTextInputGroup';
import { Field } from 'formik';
import { FormikCheckbox } from '../common/formik/FormikCheckbox';
import FormikRadio, { ItemsProps } from '../common/formik/FormikRadio';


interface MainPricingComponentFormProps {
    helpTextsVisible: boolean;
}

const MainPricingComponentForm: React.FC<MainPricingComponentFormProps> = ({
    helpTextsVisible
}) => {
    const items: ItemsProps[] = [
        {
            id: 'INVOICE_DISPLAY_TYPE_NONE',
            value: 'INVOICE_DISPLAY_TYPE_NONE',
            name: 'Nicht auf Rechnung ausweisen',
            component: ''
        },
        {
            id: 'INVOICE_DISPLAY_TYPE_WITHOUT_AMOUNT',
            value: 'INVOICE_DISPLAY_TYPE_WITHOUT_AMOUNT',
            name: 'Auf Rechnung ausweisen, ohne Betrag',
            component: ''
        },
        {
            id: 'INVOICE_DISPLAY_TYPE_WITH_AMOUNT',
            value: 'INVOICE_DISPLAY_TYPE_WITH_AMOUNT',
            name: 'Auf Rechnung ausweisen, mit Betrag',
            component: ''
        },
    ];
    return (
        <div className="pricingComponent">
            <h3 className="mb-3 font-weight-bold">Hauptkomponente</h3>
            <p>
                Die Summe der einzelnen bisherigen Leistungskomponenten ergibt im Regelfall nicht 100% des in der
                Preismatrix eingetragenen Bruttopreises. <br/>
                Um die Differenz zwischen dem Bruttopreis des Preisfeldes in der Matrix und der Summe der Komponenten
                verbuchen zu können, muss es einen "betragsflexiblen" Posten geben.
                Dieser ist diese <span className="font-italic">Hauptkomponente</span>.
            </p>
            <Row>
                <Col sm={4}>
                    <FormikTextInputGroup label="Bezeichnung" name={`mainPricingComponent.name`} testid="name_mainComponent" readOnly={true} />
                </Col>
                <Col sm={4}>
                    <FormikTextInputGroup label="Kurzbezeichnung" name={`mainPricingComponent.shortName`} testid="shortName_mainComponent"/>
                </Col>
                <Col sm={4} className="align-self-center">
                    <label><span>Betrag (brutto)</span></label>
                    <p className="text-muted font-italic">wird durch Eintrag in Preismatrix berechnet</p>
                </Col>

                <Col sm={4}>
                    <FormikTextInputGroup label="Produktbuchungsgruppe"
                                          name={`mainPricingComponent.productBookingGroup`} testid="productBookingGroup_mainComponent"/>
                </Col>

                <Col sm={4}>
                    <FormikTextInputGroup label="MwSt.-Produktbuchungsgruppe"
                                          name={`mainPricingComponent.productBookingGroupVatKey`} testid="vatKey_mainComponent"/>
                </Col>
                <Col sm={4}>
                    <FormikTextInputGroup type="number" label="Mehrwertsteuersatz"
                                          name={`mainPricingComponent.productBookingGroupVatValue`} testid="vatValue_mainComponent"/>
                </Col>
                <Col sm={4}>
                    <FormikCheckbox label="Event-Suffix anhängen?"
                                        classNameCheckbox="pricingcomponent-suffix-checkbox"
                                        name="mainPricingComponent.productBookingGroupAppendEventSuffix"
                                        helpTextsVisible={helpTextsVisible}
                                        helpText="Soll beim Verkauf dieser Leistung der bei dem Event gepflegte
                                        'Fibu-Suffix für Übergabe an ERP' an den Wert dieses Feldes angehängt werden?"/>
                </Col>

                <Col sm={4}>
                    <FormikCheckbox label="Event-Suffix anhängen?"
                                        classNameCheckbox="pricingcomponent-suffix-checkbox"
                                        name="mainPricingComponent.productBookingGroupVatKeyAppendEventSuffix"
                                        helpTextsVisible={helpTextsVisible}
                                        helpText="Soll beim Verkauf dieser Leistung der bei dem Event gepflegte
                                        'Fibu-Suffix für Übergabe an ERP' an den Wert dieses Feldes angehängt werden?"/>
                </Col>

                <Col sm={4}>
                    <FormikTextInputGroup label="Beschreibung Produktbuchungsgruppe"
                                          name={`mainPricingComponent.productBookingGroupDescription`}/>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <Form.Group>
                        <FormikRadio
                            name="mainPricingComponent.invoiceDisplayType"
                            items={items}
                        />
                    </Form.Group>
                </Col>
            </Row>
        </div>
    );
}

export default MainPricingComponentForm;
