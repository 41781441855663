import React, {useCallback, useEffect, useState} from 'react';
import * as yup from 'yup';
import {Formik} from 'formik';
import {Card, Col, Form as BootstrapForm, Row} from 'react-bootstrap';
import {FormikTextInputGroup} from '../common/formik/FormikTextInputGroup';
import * as PropTypes from 'prop-types';
import FeedbackButton from '../common/FeedbackButton';
import {connect} from "react-redux";
import Footer from "../layout/Footer";
import LayoutEditor from "../layoutEditor/LayoutEditor";
import Api from "../../api";
import FormikSelect from "../common/formik/FormikSelect";
import {forOwn} from "lodash";
import ConfirmModal from "../common/modal/ConfirmModal";
import styles from "../form.module.scss";

const api = new Api();

const getInitialValues = () => (
    {
        name: '',
        template: '',
        width: 210,
        height: 297,
        type: '',
        elements: [],
        archived: false,
    }
);

const FORM_SCHEMA = yup.object().shape({
    name: yup.string().required(),
    template: yup.string().when('type', (type, schema) => {
        if (type === 'HTML') {
            return schema.required();
        }
        return schema.nullable();
    })
});

const Form = ({ticketLayout, onSubmit, submitPending, helpTextsVisible}) => {
    const [layoutElements, setLayoutElements] = useState(ticketLayout.elements ?? []);
    const [previewBusy, setPreviewBusy] = useState(false);
    const [config, setConfig] = useState({types: []});
    const [ticketLayoutTypes, setTicketLayoutTypes] = useState([]);
    const [ticketLayoutType, setTicketLayoutType] = useState(ticketLayout.type);
    const [isArchived, setIsArchived] = useState(ticketLayout.archived);

    useEffect(() => {
        api.ticketLayoutConfig().then(result => {
            setConfig({...result, pending: false});
        }).catch(errResponse => {
        });
    }, []);

    useEffect(() => {
        const types = [];
        if (config.types) {
            forOwn(config.types, function (value, key) {
                types.push({
                    id: key,
                    name: value.label,
                });
            });
        }

        types.push({
            id: 'HTML',
            name: 'Custom (HTML)',
        });

        setTicketLayoutTypes(types);
    }, [config]);

    const handleSubmit = (ticketLayout, options) => {
        onSubmit({...ticketLayout, elements: layoutElements}, options);
    };

    const handlePreview = useCallback(() => {
        setPreviewBusy(true);

        api.previewTicketLayout({...ticketLayout, type: ticketLayoutType, elements: layoutElements})
            .then(result => {
                setPreviewBusy(false);

                const file = new Blob([result.data], {type: "application/pdf"});
                const fileURL = URL.createObjectURL(file);
                const pdfWindow = window.open();
                pdfWindow.location.href = fileURL;
            }).catch(err => {
            setPreviewBusy(false);
        });
    }, [ticketLayout, layoutElements, ticketLayoutType]);

    const ArchivedStatus = ({setFieldValue}) => {
        const confirmChangeStatus = () => () => {
            let newStatus = !isArchived
            setIsArchived(newStatus);
            setFieldValue('archived', newStatus);
        };
        return (
            <ConfirmModal title="Status ändern"
                          body={(isArchived) ? `Ticketlayout wieder reaktivieren?`: `Ticketlayout ins Archiv verschieben?`}
                          cancelLabel="Abbrechen"
                          confirmLabel="Bestätigen"
            >
                {confirm => (
                    <BootstrapForm.Check type="checkbox"
                                         id="archived"
                                         label="Ticketlayout archivieren"
                                         onChange={(e) => confirm(confirmChangeStatus(e))}
                                         name="archived"
                                         checked={isArchived}
                    />
                )}
            </ConfirmModal>
        )
    }

    return <Formik
        initialValues={{...getInitialValues(), ...ticketLayout}}
        validationSchema={FORM_SCHEMA}
        onSubmit={handleSubmit}
    >
        {formik => {
            if (!formik.values.type) {
                return <>
                    <form onSubmit={formik.handleSubmit} className={styles.formCotainer}>
                        <div className={styles.formBox}>
                            <h2 className={styles.formTitle}>Allgemein</h2>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <FormikSelect name="type" label="Papierformat" options={ticketLayoutTypes} />
                                </Col>
                            </Row>
                        </div>
                        <Footer>
                            <FeedbackButton to={`/base/ticket-layout`}>
                                Abbrechen
                            </FeedbackButton>
                        </Footer>
                    </form>
                </>;
            }

            if (formik.values.type && ticketLayoutType !== formik.values.type) {
                setTicketLayoutType(formik.values.type);
            }

            if (formik.values.type && formik.values.type !== 'HTML') {
                const typeConfig = config.types[formik.values.type];

                if (typeConfig && formik.values.width !== typeConfig.width) {
                    formik.setFieldValue('width', typeConfig.width);
                }

                if (typeConfig && formik.values.height !== typeConfig.height) {
                    formik.setFieldValue('height', typeConfig.height);
                }
            }

            return (
                <form onSubmit={formik.handleSubmit} className={styles.formCotainer}>
                    <div className={styles.formBox}>
                        <h2 className={styles.formTitle}>Allgemein</h2>
                        <Row className="mt-3">
                            <Col className="col-md-4">
                                <FormikSelect name="type" label="Papierformat" options={ticketLayoutTypes}
                                                disabled={formik.values.id}/>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col className="col-md-4">
                                <FormikTextInputGroup label="Titel" name="name"
                                                        helpTextsVisible={helpTextsVisible}
                                                        helpText="Titel des Ticketlayouts"/>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-md-4">
                                <ArchivedStatus setFieldValue={formik.setFieldValue}/>
                            </Col>
                        </Row>

                        {formik.values.type === 'HTML' &&
                            <Row className="mt-3">
                                <div>
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td><b>Placeholder</b></td>
                                            <td><b>(Swagger-)Field</b></td>
                                        </tr>
                                        <tr>
                                            <td>{'{{TicketID}}'}</td>
                                            <td>ticket.id</td>
                                        </tr>
                                        <tr>
                                            <td>{'{{UserID}}'}</td>
                                            <td>ticket.ticketHolderId</td>
                                        </tr>
                                        <tr>
                                            <td>{'{{QRCode}}'}</td>
                                            <td>ticket.barcode</td>
                                        </tr>
                                        <tr>
                                            <td>{'{{EventserieName}}'}</td>
                                            <td>eventseries.name</td>
                                        </tr>
                                        <tr>
                                            <td>{'{{EventID}}'}</td>
                                            <td>event.eventId</td>
                                        </tr>
                                        <tr>
                                            <td>{'{{EventTitel}}'}</td>
                                            <td>event.title.de</td>
                                        </tr>
                                        <tr>
                                            <td>{'{{EventUntertitel}}'}</td>
                                            <td>event.subtitle.de</td>
                                        </tr>
                                        <tr>
                                            <td>{'{{EventStartDatum}}'}</td>
                                            <td>event.eventStartDate.date</td>
                                        </tr>
                                        <tr>
                                            <td>{'{{EventStartUhrzeit}}'}</td>
                                            <td>event.eventStartDate.time</td>
                                        </tr>
                                        <tr>
                                            <td>{'{{EventEndDatum}}'}</td>
                                            <td>event.eventEndDate.date</td>
                                        </tr>
                                        <tr>
                                            <td>{'{{EventEndUhrzeit}}'}</td>
                                            <td>event.eventEndDate.time</td>
                                        </tr>
                                        <tr>
                                            <td>{'{{EventEinlasszeitPunktVonDatum}}'}</td>
                                            <td>event.doorsOpenAt.date</td>
                                        </tr>
                                        <tr>
                                            <td>{'{{EventEinlasszeitPunktVonUhrzeit}}'}</td>
                                            <td>event.doorsOpenAt.time</td>
                                        </tr>
                                        <tr>
                                            <td>{'{{BildEventserie}}'}</td>
                                            <td>eventSeries.imageurl</td>
                                        </tr>
                                        <tr>
                                            <td>{'{{Preis}}'}</td>
                                            <td>ticket.grossAmount</td>
                                        </tr>
                                        <tr>
                                            <td>{'{{Leistungsvorlagentext}}'}</td>
                                            <td>ticket.purchaseableItemTemplateAdditionalText</td>
                                        </tr>
                                        <tr>
                                            <td>{'{{Spielstaette}}'}</td>
                                            <td>ticket.event.venue.name</td>
                                        </tr>
                                        <tr>
                                            <td>{'{{SpielstaetteAdresse}}'}</td>
                                            <td>ticket.event.venue.contact.address1</td>
                                        </tr>
                                        <tr>
                                            <td>{'{{SpielstaetteAdresszusatz}}'}</td>
                                            <td>ticket.event.venue.contact.address2</td>
                                        </tr>
                                        <tr>
                                            <td>{'{{SpielstaettePostleitzahl}}'}</td>
                                            <td>ticket.event.venue.contact.postalCode</td>
                                        </tr>
                                        <tr>
                                            <td>{'{{SpielstaetteStadt}}'}</td>
                                            <td>ticket.event.venue.contact.city</td>
                                        </tr>
                                        <tr>
                                            <td>{'{{SpielstaetteLand}}'}</td>
                                            <td>ticket.event.venue.contact.country</td>
                                        </tr>
                                        <tr>
                                            <td>{'{{PricingClass}}'}</td>
                                            <td>ticket.pricingClass</td>
                                        </tr>
                                        <tr>
                                            <td>{'{{TextVorlaeufigerZeitpunkt}}'}</td>
                                            <td>event.eventDatePreliminaryText</td>
                                        </tr>
                                        <tr>
                                            <td>{'{{Blockgruppe}}'}</td>
                                            <td>ticket.blockGroup</td>
                                        </tr>
                                        <tr>
                                            <td>{'{{Block}}'}</td>
                                            <td>ticket.block.label.deValue</td>
                                        </tr>
                                        <tr>
                                            <td>{'{{Eingangsinfo}}'}</td>
                                            <td>block.blockEntryDescription</td>
                                        </tr>
                                        <tr>
                                            <td>{'{{Reihe}}'}</td>
                                            <td>ticket.place.rowLabel</td>
                                        </tr>
                                        <tr>
                                            <td>{'{{Platz}}'}</td>
                                            <td>ticket.place.seatLabel</td>
                                        </tr>
                                        <tr>
                                            <td>{'{{OrderId}}'}</td>
                                            <td>ticket.order.id</td>
                                        </tr>
                                        <tr>
                                            <td>{'{{Vorname}}'}</td>
                                            <td>ticket.ticketHolder.firstName</td>
                                        </tr>
                                        <tr>
                                            <td>{'{{Nachname}}'}</td>
                                            <td>ticket.ticketHolder.lastName</td>
                                        </tr>
                                        <tr>
                                            <td>{'{{FirmenName}}'}</td>
                                            <td>ticket.ticketHolder.companyName</td>
                                        </tr>
                                        <tr>
                                            <td>{'{{BusinessContact}}'}</td>
                                            <td>ticket.ticketHolder.companyName or ticket.ticketHolder.firstName
                                                ticket.ticketHolder.lastName
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{'{{PersonalizationName}}'}</td>
                                            <td>ticket.personalizationFullName</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Row>}
                        {formik.values.type === 'HTML' && <Row className="mt-3">
                            <Col className="col-md-8">
                                <FormikTextInputGroup type="textarea" label="Vorlage" name="template"
                                                        helpTextsVisible={helpTextsVisible}
                                                        helpText="HTML Vorlage des Ticketlayouts"/>
                            </Col>
                        </Row>}
                        {formik.values.type === 'HTML' && <Row className="mt-3">
                            <Col className="col-md-2">
                                <FormikTextInputGroup label="Breite (mm)" name="width"
                                                        helpTextsVisible={helpTextsVisible}
                                                        helpText="Breite des Tickets in mm"/>
                            </Col>
                            <Col className="col-md-2">
                                <FormikTextInputGroup label="Höhe (mm)" name="height"
                                                        helpTextsVisible={helpTextsVisible}
                                                        helpText="Höhe des Tickets in mm"/>
                            </Col>
                        </Row>}


                        {formik.values.type !== 'HTML' && <Row className="mt-3">
                            <Col className="col-md-10">
                                <LayoutEditor width={formik.values.width}
                                                height={formik.values.height}
                                                layoutElements={layoutElements}
                                                setLayoutElements={setLayoutElements}/>
                            </Col>
                            <Col className="col-md-2">
                                Hier können Sie die Vorlage für alle Dokumente dieses Typs anpassen.
                                In Texten stehen Ihnen folgende Platzhalter zur Verfügung:
                                {config.types[formik.values.type] &&
                                    <div className="mt-2">
                                        {config.types[formik.values.type].placeholders.map(value =>
                                            <span> {'{{'}{value}{'}}'} </span>
                                        )}
                                    </div>}
                            </Col>
                        </Row>}
                    </div>
                    <Footer>
                        {formik.values.type !== 'HTML' && <div className="ticket-layout-preview-buttons">
                            <FeedbackButton className="preview-button" busy={previewBusy} variant="outlined"
                                            onClick={() => handlePreview()}>
                                <i className="fa fa-file-pdf-o ng-scope"/>
                                Vorschau
                            </FeedbackButton>
                        </div>}
                        <FeedbackButton to={`/base/ticket-layout`}>
                            Abbrechen
                        </FeedbackButton>
                        <FeedbackButton
                            type="submit"
                            busy={submitPending}
                        >
                            Speichern
                        </FeedbackButton>
                    </Footer>
                </form>
            )
        }}
    </Formik>
};


Form.propTypes = {
    onSubmit: PropTypes.any,
    submitPending: PropTypes.any,
    blockGroup: PropTypes.any,
    requestErrors: PropTypes.any
};

const mapStateToProps = (state, props) => {

    const helpTextsVisible = state.helpTextsToggle;

    return {
        helpTextsVisible
    }
};

export default connect(mapStateToProps, null)(Form);



