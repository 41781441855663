import * as yup from 'yup';
import {urlError, urlPattern} from "../venueEvent/schema";

export const VoucherEvents = yup.object().shape({
        name: yup.string().required(),
        id: yup.string().required(),
    }
);

export const FORM_SCHEMA = yup.object().shape({
    slug: yup.string().required(),
    name: yup.string().required(),
    shortName: yup.string().required(),
    domain: yup.string().required().matches(
        // Regular expression for domain validation
        /^((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i,
        'Ungültiger Domainname'
    ),
    imprintUrl: yup.string().required(),
    taxId: yup.string().required(),
    googleTagManagerId: yup.string().nullable(),
    gtcUrl: yup.string().matches(urlPattern, urlError).required(),
    privacyPolicyUrl: yup.string().matches(urlPattern, urlError).required(),
    websiteUrl: yup.string().matches(urlPattern, urlError).required(),
    paymentSettings: yup.object().nullable().shape({
        provider: yup.string().nullable().default(''),
        payone: yup.object().when('provider', {
            is: "payone",
            then: () => yup.object().shape({
                environment: yup.string().required().oneOf(['test', 'production']),
                merchantId: yup.string().max(255).required(),
                apiKey: yup.string().max(255).required(),
                apiSecret: yup.string().max(255).required(),
                webhookKey: yup.string().max(255).required(),
                webhookSecret: yup.string().max(255).required(),
                paymentProducts: yup.array().default([]).min(1, 'Es ist mindestens eine Zahlungsmethode erforderlich.')
            }).required(),
            otherwise: () => yup.object().shape({
                environment: yup.string(),
                merchantId: yup.string(),
                apiKey: yup.string(),
                apiSecret: yup.string(),
                webhookKey: yup.string(),
                webhookSecret: yup.string(),
                paymentProducts: yup.array().default([])
            }).nullable(),
        }),
        mollie: yup.object().when('provider', {
            is: "mollie",
            then: () => yup.object().shape({
                partnerId: yup.string().max(255).required(),
                apiKey: yup.string().max(255).required(),
                profileId: yup.string().max(255).required(),
                paymentMethods: yup.array().default([]).min(1, 'Es ist mindestens eine Zahlungsmethode erforderlich.')
            }).required(),
            otherwise: () => yup.object().shape({
                partnerId: yup.string(),
                apiKey: yup.string(),
                profileId: yup.string(),
                paymentMethods: yup.array().default([])
            }).nullable(),
        }),
    }),
    legalContact: yup.object().shape({
        salutation: yup.string().required(),
        firstName: yup.string().required().max(255),
        lastName: yup.string().required().max(255),
        address1: yup.string().required().max(255),
        address2: yup.string().max(255),
        companyName: yup.string().max(255),
        postalCode: yup.string().required().max(6),
        city: yup.string().required().max(48),
        country: yup.string().required(),
        phone: yup.object().shape({
            countryCode: yup.string().nullable(),
            phoneNumber: yup.string().matches(/^\d{1,12}$/, 'Invalid phone number').nullable()
        }),
        mobile: yup.object().shape({
            countryCode: yup.string().nullable(),
            phoneNumber: yup.string().matches(/^\d{1,12}$/, 'Invalid phone number').nullable()
        }),
        email: yup.string().email('Invalid email').required('Email is required')
    }),
    invoiceContact: yup.object().shape({
        salutation: yup.string().required(),
        firstName: yup.string().required().max(255),
        lastName: yup.string().required().max(255),
        address1: yup.string().required().max(255),
        address2: yup.string().max(255),
        companyName: yup.string().max(255),
        postalCode: yup.string().required().max(6),
        city: yup.string().required().max(48),
        country: yup.string().required(),
        phone: yup.object().shape({
            countryCode: yup.string().required(),
            phoneNumber: yup.string().matches(/^\d{1,12}$/, 'Invalid phone number').required()
        }),
        mobile: yup.object().shape({
            countryCode: yup.string().nullable(),
            phoneNumber: yup.string().matches(/^\d{1,12}$/, 'Invalid phone number').nullable(),
        }),
        email: yup.string().email('Invalid email').required('Email is required')
    }),
    mailSettings: yup.object().shape({
        description: yup.string().required().max(255),
        server: yup.string().required().max(255),
        port: yup.number().required().integer().min(0).max(65535),
        username: yup.string().required().max(255),
        password: yup.string().required().max(255),
        sender: yup.string().required().max(255).email(),
        replyTo: yup.string().nullable().max(255).email(),
        bcc: yup.string().nullable().max(255).email()
    }),
    voucherSettings: yup.object().shape({
        enabled: yup.boolean(),
        apiBaseUri: yup.string().when('enabled', {
            is: true,
            then: () => yup.string().url().required(),
            otherwise: () => yup.string().url().nullable(),
        }),
        apiPublicKey: yup.string().when('enabled', {
            is: true,
            then: () => yup.string().required(),
            otherwise: () => yup.string().nullable(),
        }),
        apiPrivateKey: yup.string().when('enabled', {
            is: true,
            then: () => yup.string().required(),
            otherwise: () => yup.string().nullable(),
        }),
        externalTenantId: yup.number().when('enabled', {
            is: true,
            then: () => yup.number().required(),
            otherwise: () => yup.number().nullable(),
        }),
        externalEventId: yup.string().when('enabled', {
            is: true,
            then: () => yup.string().required(),
            otherwise: () => yup.string().nullable(),
        }),
        events: yup.array().default([]).of(VoucherEvents),
    }).nullable()
});
