import React, { useEffect, useState } from "react";
import { Formik, Form } from 'formik';
import { useSelector} from "react-redux";
import * as yup from 'yup';
import { Dialog, DialogTitle, DialogContent, DialogActions, InputLabel } from '@mui/material';
import { IState, selectNewAddedAreaForms, selectPlaceCategories } from "../../state/entities/venueEditor/selectors";
import FeedbackButton from "../../components/common/FeedbackButton";
import { FormikTextInputGroup } from "../../components/common/formik/FormikTextInputGroup";
import FormikRadio, { ItemsProps } from '../../components/common/formik/FormikRadio';
import FormikAsyncTypeaheadInput from "../../components/common/formik/FormikAsyncTypeaheadInput";
import { Polygon } from "pixi.js";
import { Col, Row } from 'react-bootstrap';
import styles from "./AddBlockAreaDialog.module.scss";
import { AreaFormData, StandingBlockType } from "../editor/display/areaForms/AreaFormData";
import LoadingDialog from "../editor/LoadingDialog";

interface IAddBlockAreaDialogProps {
    open: boolean;
    shape: Polygon;
    onConfirm: (areaForm: AreaFormData) => void;
    onCancel: () => void;
}

const INITIAL_OUTER_VALUES = {
    title: '',
    shortTitle: '',
    ticketHint: '',
    capacity: null,
};

const INITIAL_INNER_VALUES = {
    name: '',
    ident: '',
    color: '#000000'
};

const FORM_OUTER_SCHEMA = yup.object({
    title: yup.string().required('Titel ist erforderlich'),
    capacity: yup.number().required('Zahl ist erforderlich').positive('Zahl muss positiv sein'),
});

const FORM_INNER_SCHEMA = yup.object().shape({
    name: yup.string().required(),
    ident: yup.string().required().min(1).max(5),
    color: yup.string().required()
});

const FormRadioItems: ItemsProps[] = [
    {
        value: StandingBlockType.STANDING_BLOCK,
        name: 'Stehplatz',
        component: ''
    },
    {
        value: StandingBlockType.FREE_SEAT_SELECTION,
        name: 'freie Platzwahl',
        component: ''
    },
];



const AddBlockAreaDialog: React.FC<IAddBlockAreaDialogProps> = ({
    open,
    shape,
    onConfirm,
    onCancel
}) => {
    const placeCategories = useSelector((state: IState) => selectPlaceCategories(state));
    const [isLoaderShowing, setIsLoaderShowing] = useState(false);
    const newAddedAreaForms = useSelector((state: IState) => selectNewAddedAreaForms(state));


    useEffect(() => {
        if (newAddedAreaForms.length) setIsLoaderShowing(false);
    }, [newAddedAreaForms])


    const onSubmitOuter = (values) => {
        setIsLoaderShowing(true);
        console.log('values outer', values);

        const data = {
            id: 'provisory',
            ...values,
            x: 0,
            y: 0,
            points: shape.points.slice(),
        } as AreaFormData;
        //dispatch to slice,
        //wait for success
        onConfirm(data);
    };
    
    // const onSubmitInner = (values, setErrors) => {
    //     console.log('values inner', values);
    //     //@ts-ignore
    //     dispatch(pricingCategory.actions.create({ entity: values, noRedirectAfterCreate: true }));
    //     setShowCategoryForm(false);
    // };

    return (
        <div>
            <Dialog open={open} fullWidth maxWidth={"lg"}>
                <DialogTitle>Neuen Stehplatzblock hinzufügen</DialogTitle>
                <DialogContent className={styles.dialogContent}>
                    <Formik
                        initialValues={INITIAL_OUTER_VALUES}
                        validationSchema={FORM_OUTER_SCHEMA}
                        onSubmit={onSubmitOuter}
                    >
                        {outerFormik => {
                            return (
                                <>
                                    <Form onSubmit={outerFormik.handleSubmit}>
                                        <Row className="mt-3">
                                            <Col className="col-md-4">
                                                <FormikTextInputGroup label="Titel des Blocks*" name="title" />
                                            </Col>
                                            <Col className="col-md-4">
                                                <FormikTextInputGroup label="Eingangshinweis zum Aufdruck auf das Ticket" name="ticketHint" />
                                            </Col>
                                            <Col className="col-md-4">
                                                <FormikTextInputGroup label="Kurztitel des Blocks" name="shortTitle" />
                                            </Col>
                                            <Col className="col-md-4">
                                                <FormikTextInputGroup label="Kapazität des Blockes" name="capacity" type="number" />
                                            </Col>
                                            <Col className="col-md-4 align-self-center">
                                                <FormikRadio
                                                    className={styles.radioGroup}
                                                    defaultValue="option1"
                                                    name="blockType"
                                                    items={FormRadioItems}
                                                />
                                            </Col>
                                            <Col className="col-md-4">                               
                                                <div className={styles.pricingCategoryBox}>
                                                    <FormikAsyncTypeaheadInput
                                                        formik={outerFormik}
                                                        label="Preiskategorie*"
                                                        id="pricingList"
                                                        onSearch={() => Promise.resolve(placeCategories)}
                                                        labelKey={o => o?.name}
                                                        minLength={0}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <Row>
                                        <Col className="col-md-12 justify-content-end d-flex mt-3" style={{ gap: '8px'}}>
                                            <FeedbackButton onClick={onCancel} variant={'outlined'}>Abbrechen</FeedbackButton>
                                            <FeedbackButton onClick={() => outerFormik.submitForm()}>Hinzufügen</FeedbackButton>
                                        </Col>
                                    </Row>
                                </>
                            )
                        }}
                    </Formik>        
                </DialogContent>
            </Dialog>
            <LoadingDialog isLoading={isLoaderShowing && open} />
        </div>
    );
};

export default AddBlockAreaDialog;
