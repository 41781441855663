import React from "react";
import styles from "./baseLayout.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { actions as venueEditorActions} from '../../state/entities/venueEditor';
import { IState, selectVenuePlanSettings } from "../../state/entities/venueEditor/selectors";
import { Box, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, MenuItem, Select } from "@mui/material";
import { cloneDeep } from "lodash";
import { RowLabelVisibilityMode, convert2EachRowLabelsVisibility, convert2RowLabelVisibilityMode } from "../editor/display/labels/rows";



interface ITabLabelsProps {
    venuePlanId: string;
}


const TabLabels: React.FC<ITabLabelsProps> = ({venuePlanId}) => {
    const dispatch = useDispatch();
    const venuePlanSettings = useSelector((state: IState) => selectVenuePlanSettings(state));


    const handleSelectEditorLabels = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newVenueSettings = cloneDeep(venuePlanSettings);
        const {showAtRowStart, showAtRowEnd} = convert2EachRowLabelsVisibility(event.target.value as RowLabelVisibilityMode);
        newVenueSettings.backendSettings.showRowNumberAtBeginningOfRow = showAtRowStart;
        newVenueSettings.backendSettings.showRowNumberAtEndOfRow = showAtRowEnd;
        //@ts-ignore
        dispatch(venueEditorActions.updateVenuePlanSettings({venuePlanId: venuePlanId, venuePlanSettings: newVenueSettings}));
    }


    const handleEditorSeatLabelsCBChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newVenueSettings = cloneDeep(venuePlanSettings);
        newVenueSettings.backendSettings.showSeatLabels = event.target.checked;
        //@ts-ignore
        dispatch(venueEditorActions.updateVenuePlanSettings({venuePlanId: venuePlanId, venuePlanSettings: newVenueSettings}));
    }

    const handleSelectFrontendLabels = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newVenueSettings = cloneDeep(venuePlanSettings);
        const {showAtRowStart, showAtRowEnd} = convert2EachRowLabelsVisibility(event.target.value as RowLabelVisibilityMode);
        newVenueSettings.frontendSettings.showRowNumberAtBeginningOfRow = showAtRowStart;
        newVenueSettings.frontendSettings.showRowNumberAtEndOfRow = showAtRowEnd;
        //@ts-ignore
        dispatch(venueEditorActions.updateVenuePlanSettings({venuePlanId: venuePlanId, venuePlanSettings: newVenueSettings}));
    }

    const handleFrontendSeatLabelsCBChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newVenueSettings = cloneDeep(venuePlanSettings);
        newVenueSettings.frontendSettings.showSeatLabels = event.target.checked;
        //@ts-ignore
        dispatch(venueEditorActions.updateVenuePlanSettings({venuePlanId: venuePlanId, venuePlanSettings: newVenueSettings}));
    }


    const showBackendRowLabelsMode = convert2RowLabelVisibilityMode(
        venuePlanSettings.backendSettings.showRowNumberAtBeginningOfRow,
        venuePlanSettings.backendSettings.showRowNumberAtEndOfRow
    )
    const showFrontendRowLabelsMode = convert2RowLabelVisibilityMode(
        venuePlanSettings.frontendSettings.showRowNumberAtBeginningOfRow,
        venuePlanSettings.frontendSettings.showRowNumberAtEndOfRow
    )


    return (
        <div className={styles.tabsContent}>
            <h2 className={styles.tabsContent__title}>Labels</h2>
            <Box sx={{ borderColor: 'grey.400', boxShadow: 1}}>
                <FormControl variant="outlined" fullWidth={true} size={"small"} sx={{paddingTop: "4px", paddingBottom: "4px"}}>
                    <Grid container alignItems="center" columns={24}>
                        <Grid item md={6}>
                            <FormLabel sx={{marginBottom: 0}} >Im Editor zeigen:</FormLabel>
                        </Grid>
                        <Grid item md={12}>
                            <Select variant="outlined"
                                labelId="editorRowLabel"
                                id="editorRow"
                                value={showBackendRowLabelsMode}
                                fullWidth
                                onChange={handleSelectEditorLabels}
                            >
                                <MenuItem value={"NONE"}>Keine Reihen-Labels</MenuItem>
                                <MenuItem value={"START_ONLY"}>Am Reihen-Beginn</MenuItem>
                                <MenuItem value={"END_ONLY"}>Am Reihen-Ende</MenuItem>
                                <MenuItem value={"ALL"}>Beide Reihen-Labels</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item md={6}>
                            <FormControlLabel sx={{marginBottom: 0, display: 'flex', justifyContent: 'center'}}
                                control={<Checkbox
                                    checked={venuePlanSettings.backendSettings.showSeatLabels}
                                    onChange={handleEditorSeatLabelsCBChange}
                                />}
                                label="Sitz-Labels"
                            />
                        </Grid>
                    </Grid>
                </FormControl>
            </Box>                    
            <Box sx={{ borderColor: 'grey.400', boxShadow: 1, marginTop: "10px"}}>
                <FormControl variant="outlined" fullWidth={true} size={"small"} sx={{paddingTop: "4px", paddingBottom: "4px"}}>
                    <Grid container alignItems="center" columns={24}>
                        <Grid item md={6}>
                            <FormLabel sx={{marginBottom: 0}} >Im Frontend zeigen:</FormLabel>
                        </Grid>
                        <Grid item md={12}>
                            <Select variant="outlined"
                                labelId="frontendRowLabel"
                                id="frontendRow"
                                value={showFrontendRowLabelsMode}
                                fullWidth
                                onChange={handleSelectFrontendLabels}
                            >
                                <MenuItem value={"NONE"}>Keine Reihen-Labels</MenuItem>
                                <MenuItem value={"START_ONLY"}>Am Reihen-Beginn</MenuItem>
                                <MenuItem value={"END_ONLY"}>Am Reihen-Ende</MenuItem>
                                <MenuItem value={"ALL"}>Beide Reihen-Labels</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item md={6}>
                            <FormControlLabel sx={{marginBottom: 0, display: 'flex', justifyContent: 'center'}}
                                control={<Checkbox
                                    checked={venuePlanSettings.frontendSettings.showSeatLabels}
                                    onChange={handleFrontendSeatLabelsCBChange}
                                />}
                                label="Sitz-Labels"
                            />
                        </Grid>
                    </Grid>
                </FormControl>
            </Box>            </div>
    );
};



export default TabLabels;