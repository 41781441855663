import settings from '../../assets/images/Navigation/settings.svg';
import preSales from '../../assets/images/Navigation/pre-sales.svg';
import afterSales from '../../assets/images/Navigation/after-sales.svg';

export const NavConfig = [
    {
        label: 'Settings',
        route: '/base',
        testid: 'baseData',
        icon: settings,
        showIcon: true,
        isCategory: true,
        children: [
            {
                label: 'Benutzerverwaltung',
                route: '/base/backend-user',
                testid: 'backendUser'
            },
            {
                label: 'Bestuhlungstypen',
                route: '/base/seating-type',
                testid: 'seatingType'
            },
            {
                label: 'Eventarten',
                route: '/base/event-category',
                testid: 'eventCategory'
            },
            {
                label: 'Künstler',
                route: '/base/artist',
                testid: 'artist'
            },
            {
                label: 'Mandanten',
                route: '/base/tenant',
                testid: 'tenant'
            },
            {
                label: 'Medien',
                route: '/base/media',
                testid: 'medien'
            },
            {
                label: 'Personenpools',
                route: '/base/target-group',
                testid: 'targetGroup'
            },
            {
                label: 'Platzpools',
                route: '/base/place-pool',
                testid: 'placePool'
            },
            {
                label: 'Preiskategorien',
                route: '/base/pricing-category',
                testid: 'pricingCategory'
            },
            {
                label: 'Preisklassen',
                route: '/base/pricing-class',
                testid: 'pricingClass'
            },
            {
                label: 'Spielstätten',
                route: '/base/venue',
                testid: 'venue'
            },
            {
                label: 'Ticketlayouts',
                route: '/base/ticket-layout',
                testid: 'ticketLayout'
            },
        ]
    },
    {
        label: 'Pre-Sales',
        route: '/event-management',
        testid: 'eventManagement',
        icon: preSales,
        showIcon: true,
        isCategory: true,
        children: [
            {
                label: 'Eventserien',
                route: '/event-management/event-series',
                testid: 'eventSeries'
            },
            {
                label: 'Events',
                route: '/event-management/event',
                testid: 'event'
            },
            {
                label: 'Leistungsvorlagen',
                route: '/event-management/purchasable-item-template',
                testid: 'purchasableItem'
            },
            {
                label: 'Meta Events',
                route: '/event-management/meta-event',
                testid: 'metaEvent'
            },
            {
                label: 'Preislisten',
                route: '/event-management/pricing-list',
                testid: 'pricingList'
            },
            {
                label: 'Vergangene Events',
                route: '/event-management/past-event',
                testid: 'vergangeneEvents'
            },
            {
                label: 'Vergangene Meta Events',
                route: '/event-management/past-meta-event',
                testid: 'pastMetaEvent'
            },
            {
                label: 'Verkaufsregeln',
                route: '/event-management/sales-rule',
                testid: 'salesRule'
            },
        ]
    },
    {
        label: 'After-Sales',
        route: '/sales',
        testid: 'sales',
        icon: afterSales,
        showIcon: true,
        isCategory: true,
        children: [
            {
                label: 'Bestellungen',
                route: '/sales/order',
                testid: 'order'
            },
            {
                label: 'Servicefälle',
                route: '/sales/service-case',
                testid: 'serviceCase'
            }
        ]
    }
];
