import {combineReducers} from 'redux';
import reduceReducers from "reduce-reducers";
import {all, takeEvery} from 'redux-saga/effects';
import loadingSlice from '../entity/slice/load';
import savingSlice from "../entity/slice/save";
import deletingSlice from "../entity/slice/delete";
import { loadAllSeatsSaga, loadPlaceCategoriesSaga, loadPlacepoolDefinitionsSaga,
    loadSeatingTypesSaga, updateSomeSeatsSaga, loadBlocksSaga, deleteSeatsSaga,
    loadImagesSaga, deleteImagesSaga, updateImagesSaga, addSeatsSaga, loadVenuePlanSettingsSaga,
    updateVenuePlanSettingsSaga, loadVenuePlanSaga, loadAreaFormsSaga, addAreaFormSaga
    } from './saga';
import { venuePlan, isApiRequestPending, allSeats, newAddedSeats, placepoolDefinitions,
    selectedSeatIds, interactionMode, moveOnGrid, placeCategories, seatingTypes, blocks, images,
    areaForms, fatalAPIError, venuePlanSettings, isSeatsLoaded } from './slice';
import { venueEditor as schema } from "../../schema";
import loadSaga from "../entity/saga/load";
import saveSaga from "../entity/saga/save";
import deleteSaga from "../entity/saga/delete";
import Api from "../../../api";

export const entityName = 'venueEditor';

const api = new Api();
const resource = api.createResource(entityName)

export const switchToSerialClient = () => {
    api.switchToSerialClient();
}

const loadSlice = loadingSlice(entityName);
const saveSlice = savingSlice(entityName);
const deleteSlice = deletingSlice(entityName);

export const actions = {
    ...venuePlan.actions,
    ...venuePlanSettings.actions,
    ...isApiRequestPending.actions,
    ...loadSlice.actions,
    ...deleteSlice.actions,
    ...saveSlice.actions,
    ...allSeats.actions,
    ...newAddedSeats.actions,
    ...isSeatsLoaded.actions,
    ...placepoolDefinitions.actions,
    ...placeCategories.actions,
    ...seatingTypes.actions,
    ...selectedSeatIds.actions,
    ...interactionMode.actions,
    ...moveOnGrid.actions,
    ...blocks.actions,
    ...images.actions,
    ...areaForms.actions,
    ...fatalAPIError.actions
};

export const reducer = reduceReducers({},
    combineReducers({
        loading: loadSlice.reducer,
        saving: saveSlice.reducer,
        deleting: deleteSlice.reducer,
        venuePlan: venuePlan.reducer,
        venuePlanSettings: venuePlanSettings.reducer,
        isApiRequestPending: isApiRequestPending.reducer,
        allSeats: allSeats.reducer,
        newAddedSeats: newAddedSeats.reducer,
        isSeatsLoaded: isSeatsLoaded.reducer,
        placepoolDefinitions: placepoolDefinitions.reducer,
        placeCategories: placeCategories.reducer,
        seatingTypes: seatingTypes.reducer,
        selectedSeatIds: selectedSeatIds.reducer,
        interactionMode: interactionMode.reducer,
        moveOnGrid: moveOnGrid.reducer,
        blocks: blocks.reducer,
        images: images.reducer,
        areaForms: areaForms.reducer,
        fatalAPIError: fatalAPIError.reducer
    }));

export const saga = function* saga() {
    yield all([
        takeEvery(actions.load, loadSaga({ actions, resource, schema })),
        takeEvery(actions.save, saveSaga({ actions, resource, schema })),
        takeEvery(actions.delete, deleteSaga({ actions, resource, schema })),
        takeEvery(actions.loadVenuePlan, loadVenuePlanSaga({ actions, api, schema })),
        takeEvery(actions.loadVenuePlanSettings, loadVenuePlanSettingsSaga({ actions, api, schema })),
        takeEvery(actions.updateVenuePlanSettings, updateVenuePlanSettingsSaga({ actions, api, schema })),
        takeEvery(actions.loadAllSeats, loadAllSeatsSaga({ actions, api, schema })),
        takeEvery(actions.updateSomeSeats, updateSomeSeatsSaga({ actions, api, schema })),
        takeEvery(actions.addSeats, addSeatsSaga({ actions, api, schema })),
        takeEvery(actions.deleteSeats, deleteSeatsSaga({ actions, api, schema })),
        takeEvery(actions.loadPlacepoolDefinitions, loadPlacepoolDefinitionsSaga({ actions, api, schema })),
        takeEvery(actions.loadPlaceCategories, loadPlaceCategoriesSaga({ actions, api, schema })),
        takeEvery(actions.loadSeatingTypes, loadSeatingTypesSaga({ actions, api, schema })),
        takeEvery(actions.loadBlocks, loadBlocksSaga({ actions, api, schema })),
        takeEvery(actions.loadImages, loadImagesSaga({ actions, api, schema })),
        takeEvery(actions.updateImages, updateImagesSaga({ actions, api, schema })),
        takeEvery(actions.deleteImages, deleteImagesSaga({ actions, api, schema })),
        takeEvery(actions.loadAreaForms, loadAreaFormsSaga({ actions, api, schema })),
        takeEvery(actions.addAreaForm, addAreaFormSaga({ actions, api, schema })),
    ])
};
