import React, { useState } from "react";
import styles from "./baseLayout.module.scss";
import { useSelector } from "react-redux";
import { IState, selectInteractionMode } from "../../state/entities/venueEditor/selectors";
import FeedbackButton from "../../components/common/FeedbackButton";
import { IImagesManager } from "../editor/display/images/imagesManager";
import { isEmpty } from 'lodash';
import FieldImage, { FieldValuesProps } from '../../components/common/FieldImage/FieldImage';
import ListingModal from "../../components/common/modal/ListingModal";
import { ImageData } from "../editor/display/images/ImageData";
import Api from "../../api";
import { convertImage2ImageData, convertImageData2Image } from "../../state/entities/venueEditor/saga";


const api = new Api();

export const switchToSerialClient = () => {
    api.switchToSerialClient();
}


interface ITabImagesProps {
    imagesManager: IImagesManager;
    venuePlanId: string;
}


const TabImages: React.FC<ITabImagesProps> = ({imagesManager, venuePlanId}) => {
    const [activeFieldName, setActiveFieldName] = useState('');
    const [imageFields, setImageFields] = useState([]);
    const [isShowBatchModal, setIsShowBatchModal] = useState(false);

    const interactionMode = useSelector((state:IState) => selectInteractionMode(state));


    const closeModal = () => {
        setIsShowBatchModal(false);
    }

    const onHandleShowModal = (e: React.MouseEvent<HTMLButtonElement>, fieldName: string) => {
        e.stopPropagation();
        setIsShowBatchModal(true);
        setActiveFieldName(fieldName);
    }

    const onHandleRemove = (e: React.MouseEvent<HTMLButtonElement>, fieldName: string) => {
        e.stopPropagation();
        setImageFields(prevState => prevState.filter(item => item.id !== fieldName));
    }
    
    const onHandleChooseImage = (item: FieldValuesProps) => {
        setIsShowBatchModal(false);
        imagesManager.prebuildNewImage(item.id, item.title, item.url).then((image: ImageData) => {
            const imageRequestData = convertImage2ImageData(image);
            imageRequestData.venuePlanId = venuePlanId;
            api.addImageToVenuePlan(imageRequestData).then((newImageData) => {
                const newImage = convertImageData2Image(newImageData);
                imagesManager.addItem(newImage);
            });
        });

        setActiveFieldName(item.id)
    }

    const handleMoveUp = () => {
        imagesManager.moveUpSelectedImage();
    }

    const handleMoveDown = () => {
        imagesManager.moveDownSelectedImage();
    }

    const handleMoveToTop = () => {
        imagesManager.moveToTopSelectedImage();
    }

    const handleMoveToBottom = () => {
        imagesManager.moveToBottomSelectedImage();
    }


    return (
        <div className={styles.tabsContent}>
            <h2 className={styles.tabsContent__title}>Grafiken</h2>
            <div className={styles.tabsContent__buttons}>
                 <div className={styles.tabsContent__buttonRow}>
                    <FeedbackButton value="AddImage"
                        onClick={handleMoveUp} disabled={interactionMode !== "IMAGES"}
                        variant="outlined" className={styles.tabsContent__fixedButton}>
                        Ebene höher
                    </FeedbackButton>
                    <FeedbackButton value="AddImage" 
                        onClick={handleMoveDown} disabled={interactionMode !== "IMAGES"} 
                        variant="outlined" className={styles.tabsContent__fixedButton}>
                        Ebene tiefer
                    </FeedbackButton>
                </div>
                <div className={styles.tabsContent__buttonRow}>
                    <FeedbackButton value="AddImage" 
                        onClick={handleMoveToTop} disabled={interactionMode !== "IMAGES"} 
                        variant="outlined" className={styles.tabsContent__fixedButton}>
                        Oberste Ebene
                    </FeedbackButton>
                    <FeedbackButton value="AddImage" 
                        onClick={handleMoveToBottom} disabled={interactionMode !== "IMAGES"} 
                        variant="outlined" className={styles.tabsContent__fixedButton}>
                        Unterste Ebene
                    </FeedbackButton>
                </div>
             </div>
             {!isEmpty(imageFields) && imageFields.map((item) => {
                return (
                    <div key={item.id}>
                        <FieldImage
                            values={item}
                            fieldName={activeFieldName}
                            label={`Label Image ${item.title}`}
                            onHandleShowModal={(e) => onHandleShowModal(e, item.id)}
                            onHanldleRemove={(e) => onHandleRemove(e, item.id)}
                        />
                    </div>
                );
            })}
            <ListingModal
                show={isShowBatchModal}
                title="Bild wählen"
                onCancel={closeModal}
                onHandleChooseImage={onHandleChooseImage}
            />
        </div>
    );
};


export default TabImages;