import React, {useEffect, useState} from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap';
import PropTypes from "prop-types";
import FormPlacePools from "./FormPlacePools";
import {FormikTextInputGroup} from "../common/formik/FormikTextInputGroup";
import Modal from "react-bootstrap/Modal";
import FeedbackButton from '../common/FeedbackButton';
import {Field, Formik} from "formik";
import FormStandingBlocksAdd from "./FormStandingBlocksAdd";
import styles from "../form.module.scss";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FormikRadio from '../common/formik/FormikRadio';

const standingBlocksItems = [
    {
        id: 'standingPlace',
        value: 'standingPlace',
        name: 'Stehplatz',
        component: ''
    },
    {
        id: 'freeSeating',
        value: 'freeSeating',
        name: 'Freie Platzwahl',
        component: ''
    },
];

function FormStandingBlocks({formik, standingBlocks, loadStandingBlocksByVenueEventAndVenuePlan}) {
    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
    const [standingBlockToDelete, setStandingBlockToDelete] = useState(undefined);

    useEffect(() => {
        if (formik.values.venuePlan && formik.values.venuePlan.id) {
            loadStandingBlocksByVenueEventAndVenuePlan(formik.values.id, formik.values.venuePlan.id);
        }
    }, [formik.values.standingBlocks, formik.values.venuePlan]);

    const onSubmitStandingBlockAdd = (values, {resetForm}) => {
        if (values.standingBlock) {
            const block = standingBlocks.venuePlanStandingBlocks.find(item => {
                return item.id === values.standingBlock;
            });
            block && formik.setFieldValue('standingBlocks', [...formik.values.standingBlocks, {
                block: block,
                placePools: [],
                blockLabel: block.label.de,
                capacity: 1,
                type: "standingPlace",
                id: "",
                pricingCategory: block.pricingCategory,
            }]);
        }

        // Resetten der form, damit Werte auf Initial Values gesetzt werden
        resetForm();
    };

    const canDeleteBlock = (id) => {
        if (!standingBlocks || !Array.isArray(standingBlocks.venueEventStandingBlocks)) {
            return false;
        }

        const block = standingBlocks.venueEventStandingBlocks.find(item => {
            return item.id === id;
        });

        if (block && block.placePools) {
            const sum = block.placePools.reduce(function (sum, currentValue) {
                return sum + currentValue.bookedPlaces;
            }, 0);

            if (sum > 0) {
                return false;
            }
        }

        return true;
    };

    const deleteStandingBlock = (standingBlockIndex) => {
        let standingBlocks = formik.values.standingBlocks.filter((standingBlock, index) => {
            return (index !== standingBlockIndex)
        });
        formik.setFieldValue('standingBlocks', standingBlocks);
    };

    const showDeleteConfirmingDialog = (index) => {
        setShowDeleteConfirmDialog(true);
        setStandingBlockToDelete(index);
    };

    const handleCancelDelete = () => {
        setShowDeleteConfirmDialog(false);
    };

    const handleConfirmDelete = () => {
        setShowDeleteConfirmDialog(false);
        deleteStandingBlock(standingBlockToDelete);
    };

    const validateBlockCapacity = (formBlock, capacity) => {
        if (!standingBlocks || !Array.isArray(standingBlocks.venueEventStandingBlocks)) {
            return;
        }

        const block = standingBlocks.venueEventStandingBlocks.find(item => {
            return item.id === formBlock.id;
        });

        if (block) {
            let minCapacity = 0;

            if (block && block.bookedPlaces) {
                minCapacity = block.bookedPlaces
            }

            if (minCapacity > capacity) {
                return `Die Kapazität sollte größer oder gleich ${minCapacity} sein`
            }
        }
    };

    return (
        <>
            {formik.values.standingBlocks.map((element, blockIndex) => (
                <div className={styles.formBox} key={blockIndex}>
                    <h2 className={styles.formTitle}>Stehplatzblock {element.blockLabel} {element.pricingCategory && `(${element.pricingCategory.name})`}
                        {canDeleteBlock(element.id) &&
                            <FeedbackButton
                                title="Zuordnung entfernen"
                                className="list-link link-btn"
                                onClick={() => showDeleteConfirmingDialog(blockIndex)}
                            >
                                <DeleteOutlineOutlinedIcon className='table-icon' />
                            </FeedbackButton>
                        }
                    </h2>
                    <Row>
                        <Col className="col-md-4">
                            <FormikTextInputGroup
                                type="number"
                                min="0"
                                label="Anzahl Stehplätze"
                                name={'standingBlocks[' + blockIndex + '].capacity'}
                                validate={(capacity) => validateBlockCapacity(formik.values.standingBlocks[blockIndex], capacity)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-md-4">
                            <FormikRadio
                                name={'standingBlocks[' + blockIndex + '].type'}
                                items={standingBlocksItems}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-md-4">
                            <FormPlacePools
                                formik={formik}
                                blockIndex={blockIndex}
                                standingBlocks={standingBlocks}
                            />
                        </Col>
                    </Row>
                </div>
            ))}

            <div className={styles.formBox}>
                <h2 className={styles.formTitle}>Neuer Stehplatzblock</h2>
                <Row>
                    <Col className="col-md-4">
                        <Formik
                            initialValues={{standingBlock: ""}}
                            onSubmit={onSubmitStandingBlockAdd}
                        >
                            {(subformik) => {
                                return (
                                    <FormStandingBlocksAdd
                                        formik={formik}
                                        subformik={subformik}
                                        standingBlocks={standingBlocks}
                                    />
                                )
                            }}
                        </Formik>
                    </Col>
                </Row>
            </div>

            <Modal show={showDeleteConfirmDialog} onHide={handleCancelDelete}>
                <Modal.Header closeButton>
                    <Modal.Title>Platzpool löschen</Modal.Title>
                </Modal.Header>
                <Modal.Body>Möchten Sie die Zuordnung wirklich entfernen?</Modal.Body>
                <Modal.Footer>
                    <FeedbackButton variant="outlined" onClick={handleCancelDelete}>
                        Abbrechen
                    </FeedbackButton>
                    <FeedbackButton onClick={handleConfirmDelete}>
                        Entfernen
                    </FeedbackButton>
                </Modal.Footer>
            </Modal>
        </>
    )
}

FormStandingBlocks.propTypes = {
    formik: PropTypes.object,
    standingBlocks: PropTypes.object,
    loadStandingBlocksByVenueEventAndVenuePlan: PropTypes.func,
};

export default FormStandingBlocks;
