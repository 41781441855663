import React from 'react';
import {get, identity} from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ListViewItemCell from './ListViewItemCell';
import { Checkbox } from '@mui/material';
import classnames from 'classnames';
import styles from '../../../common/listView/listView.module.scss';

export const getElementValue = (element, field) => {
    return (field.transformValue || identity)(get(element, field.name), element)
}

const FilteringMultiSelectListRow = ({
    innerRef,
    element,
    index,
    fieldCheckbox,
    fieldBatchAction,
    selectElement,
    deselectElement,
    fields,
    columnVisibility,
    children,
    showBatchActions
}) => {

    const isSelected = element => fieldCheckbox ? element[fieldCheckbox.name] : false;
    const toggleElement = (element) => isSelected(element)
        ? deselectElement(element, fieldCheckbox)
        : selectElement(element, fieldCheckbox);

    const toggleBatchActionSelection = (element) => isSelectedForBatchAction(element)
        ? deselectElement(element, fieldBatchAction)
        : selectElement(element, fieldBatchAction);

    const isSelectedForBatchAction = element => fieldBatchAction ? element[fieldBatchAction.name] : false;

    const ListCell = ({field, element, className}) => {
        return <ListViewItemCell title="Clear" field={field} value={getElementValue(element, field)} className={className}/>;
    };

    return (
        <tr className="sortable-item-table" ref={innerRef}>
            {fieldCheckbox && <td>
                <Checkbox
                    id={`${fieldCheckbox.checkboxId}${element.id}${index}`}
                    label=""
                    checked={isSelected(element)}
                    onChange={() => toggleElement(element)}
                    className="d-inline"
                />

                {fieldCheckbox.showStatusIcon && fieldCheckbox.type === 'formSalesRules' && <i className={classNames('sales-rule-status fa mt-1', (element.status === 'aktiv' && (!element.quota || (element.quota && element.quota.availableCapacity > 0)) ? 'fa-play' : 'fa-stop'))}></i>}
                {fieldCheckbox.showStatusIcon && element.quota && element.quota.id && fieldCheckbox.type === 'formSalesRules' && <span
                    className={classNames('sales-rule-status', (element.quota.availableCapacity > 0 ? 'quota-active' : 'quota-inactive'))}
                    title={`${element.quota.name} ${element.quota.capacity - element.quota.availableCapacity}/${element.quota.capacity}`}
                >K</span>}

                {fieldCheckbox.showStatusIcon && element.bundle && element.bundle.id && fieldCheckbox.type === 'formSalesRules' && <span
                    className={classNames('sales-rule-status bundle', (element.bundle.enabled ? 'active' : 'inactive'))}
                    title={element.bundle.name}
                >B</span>}

                {fieldCheckbox.showStatusIcon && fieldCheckbox.type && fieldCheckbox.type === 'formBundles' && <span
                    title={element.enabledText}
                >
                    <i className={classNames('bundle-status fa mt-1', (element.enabledStatus ? 'fa-play' : 'fa-stop'))}></i>
                </span>}

                {fieldCheckbox.showStatusIcon && element.useFor && element.useFor === 'placePools' && <div title="Verkaufsregel benutzen für Platzpools" className="text-center">
                    <i className={'use-for-status fa mt-1 fa-map-o'}></i></div>}

                {fieldCheckbox.showStatusIcon && element.useFor && element.useFor === 'pricingList' && <div title="Verkaufsregel benutzen für Preisliste" className="text-center">
                    <i className={'use-for-status fa mt-1 fa-euro'}></i></div>}
            </td> }

            {fields.map((field) => {
                return (
                    !columnVisibility[field.name] ? (
                        <ListCell key={field.name} element={element} field={field}/>
                    ) : <ListCell key={field.name} element={element} field={''} className={classnames({[styles.ListViewItemCell]: columnVisibility})} />
                );
            })}
            {children && <td>{children({fields, element})}</td>}

            {fieldBatchAction && showBatchActions && <td>
                <Checkbox
                    id={`${element.id}`}
                    label=""
                    checked={isSelectedForBatchAction(element)}
                    onChange={() => toggleBatchActionSelection(element)}
                    className="d-inline"
                />
            </td> }
        </tr>
    )
};

FilteringMultiSelectListRow.propTypes = {
    elements: PropTypes.array,
    fieldCheckbox: PropTypes.object,
    fieldBatchAction: PropTypes.object,
    selectElement: PropTypes.func,
    deselectElement: PropTypes.func,
    fields: PropTypes.array,
    children: PropTypes.func,
    showBatchActions: PropTypes.bool
};


export default FilteringMultiSelectListRow;
