import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import FeedbackButton from '../FeedbackButton';

export default ({title, body, confirmLabel = 'Ja', cancelLabel = 'Nein', children}) => {
    const [isOpen, setOpen] = useState(false);
    const [confirmCallback, setConfirmCallback] = useState();

    const openDialogModal = callback => {
        setOpen(true);
        // Hooks-State-Setter erwarten einfache Werte oder functions der Form prevState => newState als Parameter
        // Wenn wir also einen callback als neuen value des States setzen wollen, müssen wir ihn in eine weitere
        // function einwickeln, so dass (prevState) => callback
        setConfirmCallback(() => callback);
    };

    const confirm = () => {
        setOpen(false);
        if (confirmCallback) {
            confirmCallback();
        }
    };
    const cancel = () => {
        setOpen(false);
    };

    return <>
        {children(openDialogModal)}
        <Modal show={isOpen} onHide={cancel}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            <Modal.Footer>
                <FeedbackButton variant="outlined" onClick={cancel}>{cancelLabel}</FeedbackButton>
                <FeedbackButton onClick={confirm}>{confirmLabel}</FeedbackButton>
            </Modal.Footer>
        </Modal>
    </>;


};
