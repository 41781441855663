import { Graphics } from 'pixi.js';


export enum ModifyHandleShape {
    SQUARE = 'square',
    DOT = 'dot'    
}


export class ModifyHandle extends Graphics {


    constructor(
        public index: number,
        shape: ModifyHandleShape = ModifyHandleShape.SQUARE
    ) {
        super();
        this.eventMode = "static";
        this.lineStyle(1, 16777215);
        this.beginFill(0x6699FF);
        if (shape === ModifyHandleShape.DOT) {
            this.drawCircle(0, 0, 4);
        } else {
            this.drawRect(-5, -5, 10, 10);
        }
        this.endFill();
        this.cursor = "pointer";
        this.onpointerdown = (event) => this.handleDragPointerdown(event);
    }

    public onMouseDown: (handle: ModifyHandle, event) => void = (handle, event) => {};
    public onMouseMove: (handle: ModifyHandle, event) => void = (handle, event) => {};
    public onMouseUp: (handle: ModifyHandle, event) => void = (handle, event) => {};


    handleDragPointerdown(event) {
        this.onMouseDown(this, event);
        this.onglobalpointermove = (event) => this.handleDraggingMouseMove(event);
        this.onpointerup = this.onpointerupoutside =
            (event) => this.handleDraggingMouseUp(event);
    }


    private handleDraggingMouseMove(event) {
        this.onMouseMove(this, event);
    }


    handleDraggingMouseUp(event) {
        this.onMouseUp(this, event);
        this.onpointerup =
            this.onpointerupoutside =
            this.onglobalpointermove =
            null;
    }
}
