import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {Formik} from 'formik';
import {get, sortBy, values} from 'lodash';

import {actions as eventSeriesActions} from '../../state/entities/eventSeries';
import {actions as venueActions} from '../../state/entities/venue';
import {actions as eventCategoriesActions} from '../../state/entities/eventCategory';
import {actions as venuePlanActions} from '../../state/entities/venuePlan';
import {actions as ticketLayoutActions} from '../../state/entities/ticketLayout';
import {actions as pricingClassActions} from '../../state/entities/pricingClass';
import { useLocation } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';
import TabsPanel from '../common/TabsPanel/TabsPanel';
import FormPricingClassesPriorities from '../venueEvent/FormPricingClassesPriorities';
import FormPersonalization from '../venueEvent/FormPersonalization';
import ErrorFocus from '../common/formik/ErrorFocus';
import FeedbackButton from '../common/FeedbackButton';
import Footer from '../layout/Footer';
import FormData from './FormData';
import * as PropTypes from 'prop-types';
import {MetaEvent} from './schema';
import {LoadingIndicator} from '../common/LoadingIndicator';
import Api from '../../api';
import FormDeliveryGroupsPriorities from '../venueEvent/FormDeliveryGroupsPriorities';
import FormSalesRules, {getDeliveryGroupsWithDescriptions} from "../venueEvent/FormSalesRules";
import FormQuotas from "../venueEvent/FormQuotas";
import styles from "../form.module.scss";

const api = new Api();

const INITIAL_VALUES = {
    id: '',
    ident: '',
    productBookingGroup: '',
    productBookingGroupVatKey: '',
    eventSeries: null,
    team1ImageUrl: '',
    team2ImageUrl: '',
    walletLogoUrl: '',
    walletHeaderUrl: '',
    title: {
        de: '',
        en: '',
    },
    subtitle: {
        de: '',
        en: '',
    },
    ticketSelectionExpiration: 15,
    salesInformation: {
        de: '',
        en: '',
    },
    description: {
        de: '',
        en: '',
    },
    tags: {
        de: [],
        en: [],
    },
    type: {
        de: '',
        en: '',
    },
    deepLink: '',
    queueItEventId: '',
    coronaRulesUrl: '',
    salesRules: [],
    quotas: [],
    maxTicketsSalesRulesGroups: [],
    salesRule: null,
    maxTicketsPerUser: '',
    venuePlan: null,
    pricingClassesPriorities: [],
    shippingCodesPriorities: [],
    venueEvents: [],
    salesChannelPersonalizations: [],
    productId: '',
    artists: [],
    isGoogleTrackingEnabled: false
};

const Form = ({
    onSubmit,
    submitPending,
    metaEvent,
    eventSeries,
    loadEventSeries,
    pricingClasses,
    loadPricingClasses,
    loadVenues,
    loadEventCategories,
    venue,
    eventCategory,
    ticketLayouts,
    loadVenuePlansByVenueId,
    venuePlan,
    loadTicketLayouts
}) => {
    const location = useLocation();
    const [currentTab, setCurrentTab] = useState(location?.location?.tab || 'home');
    const [allDeliveryGroups, setAllDeliveryGroups] = useState([]);
    const [databaseQuotas, setDatabaseQuotas] = useState({});
    const [newUpdateVersion, setNewUpdateVersion] = useState(0);

    const loadQuotasByEvent = (eventId) => {
        api.getQuotasData(eventId).then(result => {
            setDatabaseQuotas(result);
        });
    };

    useEffect(() => {
        loadEventSeries();
        loadPricingClasses();
        loadVenues();
        loadEventCategories();
        if (metaEvent.venue) {
            loadVenuePlansByVenueId(metaEvent.venue.id)
        }
        loadTicketLayouts();
        api.getDeliveryGroupsForMetaEvent().then(result => {
            const deliveryGroupsWithDescription = getDeliveryGroupsWithDescriptions(result)
            setAllDeliveryGroups(deliveryGroupsWithDescription);
        });
        // Wir wollen diesen Effekt explizit nur einmal "on-mount" durchführen, daher geben wir [] als dependency an.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setNewUpdateVersion(metaEvent.updateVersion);
    }, [metaEvent.updateVersion]);

    const ident = useRef(null);
    const title = useRef(null);
    const productBookingGroup = useRef(null);
    const productBookingGroupVatKey = useRef(null);
    const team1ImageUrl = useRef(null);
    const team2ImageUrl = useRef(null);
    const deepLink = useRef(null);
    const queueItEventId = useRef(null);
    const ticketLayout = useRef(null);

    const refsData = {
        ident,
        title,
        productBookingGroup,
        productBookingGroupVatKey,
        team1ImageUrl,
        team2ImageUrl,
        deepLink,
        queueItEventId
    }

    const [metaEventStatus, setMetaEventStatus] = useState({canModifyVenueEvents: null, pending: false});

    const loadMetaEventStatus = (formik) => {
        let firstVenueEvent = formik.values.venueEvents && formik.values.venueEvents.length ? formik.values.venueEvents[0] : null;
        let venuePlan = firstVenueEvent ? firstVenueEvent.venuePlan : null;
        let venuePlanId = venuePlan ? venuePlan.id : null;
        let metaEventId = formik.values.id ? formik.values.id : null;

        setMetaEventStatus({...metaEventStatus, pending: true});
        api.getMetaEventStatus(metaEventId, venuePlanId).then(result => {
            setMetaEventStatus({...result, pending: false});
        }).catch(errResponse => {
            setMetaEventStatus({...metaEventStatus, pending: false});
        });
    };

    const refsSalesRules = {
        ticketLayout
    }

    const handleSubmit = (metaEvent) => {
        let event = {
            ...metaEvent,
            // 15 minutes is default value
            ticketSelectionExpiration: metaEvent.ticketSelectionExpiration === '' ? 900 : metaEvent.ticketSelectionExpiration * 60,
            updateVersion: newUpdateVersion,
        };

        let rules = metaEvent.salesRules.map(rule => {
            // change this date when it sets in datepicker to +0 hours for send to api
            if (rule.activeMemberSinceAt && rule.activeMemberSinceAt instanceof Date) {
                const date = rule.activeMemberSinceAt;
                let newDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
                rule = {...rule, activeMemberSinceAt: newDate}
            }
            if (rule.useFor === '') {
                rule = {...rule, useFor: null};
            }
            if (rule.maxTicketsPerUser === '') {
                return {...rule, maxTicketsPerUser: null};
            } else {
                return rule;
            }
        });
        onSubmit({...event, salesRules: rules});
    };

    ticketLayouts.sort((a, b) => (a.name > b.name) ? 1 : -1);
    if (pricingClasses.length === 0) {
        return(
            <>
                <p>Keine Preisklassen gefunden</p>
                <LoadingIndicator/>
            </>
        )
    }
    if (allDeliveryGroups.length === 0) {
        return(
            <>
                <p>Keine Versandarten Vorauswahl gefunden</p>
                <LoadingIndicator/>
            </>
        )
    }

    return <Formik initialValues={{...INITIAL_VALUES, ...metaEvent}}
                   validationSchema={MetaEvent}
                   onSubmit={(event) => handleSubmit(event)}
    >
        {formik => {
            return (
                <form onSubmit={formik.handleSubmit} className={styles.formCotainer}>
                    <Tabs
                        id="uncontrolled-tab-example"
                        value={currentTab}
                        onChange={(event, newTab) => setCurrentTab(newTab)}
                    >
                        <Tab value="home" label="Stammdaten" />
                        <Tab value="salesRules" label="Verkaufsregeln" />
                        <Tab value="quotas" label="Kontingente" />
                        <Tab value="pricingClassPriorities" label="Preisklassenvorauswahl" />
                        <Tab value="personalization" label="Personalisierung" />
                        <Tab value="deliveryGroupsPriorities" label="Versandarten Vorauswahl" />
                        {!!metaEvent.id && <Tab value="placeStatus" label="Tickets aktualisieren" />}
                    </Tabs>
                    <div className={styles.tabsContentWrapper}>
                        <TabsPanel value={currentTab} index={'home'} data-event-key='home'>
                            <FormData
                                metaEvent={metaEvent}
                                formik={formik}
                                onSubmit={onSubmit}
                                formSchema={MetaEvent}
                                metaEventStatus={metaEventStatus}
                                venue={venue}
                                eventCategory={eventCategory}
                                venuePlan={venuePlan}
                                loadVenuePlansByVenueId={loadVenuePlansByVenueId}
                                loadMetaEventStatus={loadMetaEventStatus}
                                initialValues={INITIAL_VALUES}
                                eventSeries={eventSeries}
                                submitPending={submitPending}
                                refs={refsData}
                            />
                        </TabsPanel>
                        <TabsPanel value={currentTab} index={'salesRules'} data-event-key='salesRules'>
                            <FormSalesRules
                                ticketLayouts={ticketLayouts}
                                event={metaEvent}
                                onSubmit={onSubmit}
                                submitPending={submitPending}
                                initialValues={INITIAL_VALUES}
                                formSchema={MetaEvent}
                                formik={formik}
                                refs={refsSalesRules}
                            />
                        </TabsPanel>
                        <TabsPanel value={currentTab} index={'quotas'} data-event-key='quotas'>
                            <FormQuotas
                                event={metaEvent}
                                databaseQuotas={databaseQuotas}
                                loadQuotasByEvent={loadQuotasByEvent}
                                formik={formik}
                                refs={refsData}
                            />
                        </TabsPanel>
                        <TabsPanel value={currentTab} index={'pricingClassPriorities'} data-event-key='pricingClassPriorities'>
                            <FormPricingClassesPriorities
                                pricingClasses={pricingClasses}
                                pricingClassesPriorities={metaEvent.pricingClassesPriorities || []}
                                formik={formik}
                            />
                        </TabsPanel>
                        <TabsPanel value={currentTab} index={'personalization'} data-event-key='personalization'>
                            <FormPersonalization
                                salesChannels={formik.values.salesChannelPersonalizations}
                                formik={formik}
                            />
                        </TabsPanel>
                        <TabsPanel value={currentTab} index={'deliveryGroupsPriorities'} data-event-key='deliveryGroupsPriorities'>
                            <FormDeliveryGroupsPriorities
                                deliveryGroups={allDeliveryGroups}
                                deliveryGroupsPriorities={metaEvent.shippingCodesPriorities || []}
                                onSubmit={onSubmit}
                                submitPending={submitPending} initialValues={INITIAL_VALUES}
                                formSchema={MetaEvent}
                                formik={formik}
                                refs={refsData}
                            />
                        </TabsPanel>
                    </div>
                    <ErrorFocus setCurrentTab={setCurrentTab} refs={{...refsData, ...refsSalesRules}}/>
                    <Footer>
                        <FeedbackButton to={`/event-management/meta-event`}>
                            Abbrechen
                        </FeedbackButton>
                        <FeedbackButton
                            type="submit"
                            busy={submitPending || (formik.isSubmitting && formik.isValidating)}
                        >
                            Speichern
                        </FeedbackButton>
                    </Footer>
                </form>
            )
        }}
    </Formik>
};

Form.propTypes = {
    onSubmit: PropTypes.func,
    submitPending: PropTypes.bool,
    metaEvent: PropTypes.object,
    loadVenues: PropTypes.func,
    loadEventCategories: PropTypes.func,
    eventSeries: PropTypes.array,
    loadTicketLayouts: PropTypes.func,
    loadVenuePlansByVenueId: PropTypes.func,
    loadPricingClasses: PropTypes.func,
    venue: PropTypes.array,
    eventCategory: PropTypes.object,
    ticketLayouts: PropTypes.array,
    pricingClasses: PropTypes.array,
    venuePlan: PropTypes.array,
    metaEventStatus: PropTypes.object,
};

export default connect((state, ownProps) => {
    let eventSeries = state.entities.eventSeries.byId;
    const assignedEventSeries = get(ownProps, 'venueEvent.eventSeries');

    // Da die auswählbaren Eventserien asynchron geladen werden, sind diese noch nicht verfügbar, wenn das
    // Formular bereits angezeigt wird. daher mergen wir die ggf. dem Event bereits zugewiesene Eventserie
    // in die Auswahlmöglichkeiten, denn dann kann immer min. die bereits zugewiesene Eventserie angezeigt
    // und ausgewählt werden.
    if (assignedEventSeries) {
        eventSeries = {
            ...eventSeries,
            [assignedEventSeries.id]: assignedEventSeries
        };
    }

    const venues = Object.values(state.entities.venue.byId);
    const eventCategories = Object.values(state.entities.eventCategory.byId);

    return {
        eventSeries: sortBy(values(eventSeries), 'name'),
        venue: venues,
        eventCategory: eventCategories,
        ticketLayouts: Object.values(state.entities.ticketLayout.byId),
        venuePlan: Object.values(state.entities.venuePlan.byId),
        pricingClasses: Object.values(state.entities.pricingClass.byId)
    }
}, {
    loadEventSeries: eventSeriesActions.loadAll,
    loadVenues: venueActions.loadAll,
    loadEventCategories: eventCategoriesActions.loadAll,
    loadVenuePlansByVenueId: id => venuePlanActions.byVenue({id}),
    loadTicketLayouts: ticketLayoutActions.loadAll,
    loadPricingClasses: pricingClassActions.loadAll
})(Form)
