import React, { useEffect, useState } from 'react';
import {
    FormControl,
    OutlinedInput,
	InputAdornment,
	IconButton,
	InputLabel
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
/**
 * Create a new material input instance.
 *
 * @param  		{Object} 		props
 * @property 	{String} 		type 		The input type.
 * @property 	{Function} 		onChange 	The onChange callback.
 * @property 	{String|null} 	className 	CSS Selector classes.
 * @property 	{String|JSX} 	prepend 	Prepend the input.
 * @property 	{String|null} 	placeholder The inputs placeholder.
 * @property 	{String|null} 	value 		The current value.
 * @return 		{JSX}
 */
export const MaterialInput = ({
	className,
	type,
	label,
	onChange,
	value,
	startAdornment = true,
	showExternalLabel = false,
	disabled = false
}) => {
	const [ currentValue, setCurrentValue ] = useState(value || '');
	const onHandleChange = (event) => {
		event.preventDefault();
		onChange(event.target.value);
		setCurrentValue(event.target.value);
	}
	const onHanldleRemove = () => {
		setCurrentValue('');
		onChange('');
	}

	useEffect(() => {
		setCurrentValue(value);
	}, [value]);

	return (
		<FormControl
			fullWidth
			variant="outlined"
			className={className}
			placeholder="Placeholder"
		>
			{showExternalLabel && <InputLabel>{label}</InputLabel>}
			<OutlinedInput
				value={currentValue}
				type={type}
				disabled={disabled}
				onChange={onHandleChange}
				placeholder={label}
				startAdornment={ startAdornment &&
					<InputAdornment position="start">
						{currentValue && (
							<IconButton edge="start">
								<ArrowBackIcon sx={{ color: '#494949 !important'}} />
							</IconButton>
						)}
					</InputAdornment>
				}
				endAdornment={
					<InputAdornment position="end">
						{currentValue ? (
							<IconButton edge="end" onClick={onHanldleRemove}>
								<CloseIcon sx={{ color: '#494949 !important'}} />
							</IconButton>
						) : (
							<IconButton edge="end">
								<SearchIcon sx={{ color: '#494949 !important'}} />
							</IconButton>
						)}
					</InputAdornment>
				}
			>
			</OutlinedInput>
		</FormControl>
	);
};

export default MaterialInput;