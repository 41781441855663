import React, { useState } from 'react';
import { Formik } from 'formik';
import { FORM_SCHEMA } from './schema'
import { Col, Row } from 'react-bootstrap';
import { FormikTextInputGroup } from '../common/formik/FormikTextInputGroup';
import * as PropTypes from 'prop-types';
import FeedbackButton from '../common/FeedbackButton';
import Footer from "../layout/Footer";
import {connect} from "react-redux";
import FormPaymentSettings from "./FormPaymentSettings";
import FormMailSettings from "./FormMailSettings";
import FormVoucherSettings from "./FormVoucherSettings";
import FormContact from "../contact/FormContact";
import debounce from "debounce-promise";
import Api from "../../api";
import { LoadingIndicator } from "../common/LoadingIndicator";
import styles from "../form.module.scss";
import {Tab, Tabs} from "@mui/material";
import TabsPanel from "../common/TabsPanel/TabsPanel";

const INITIAL_VALUES = {
    slug: '',
    name: '',
    shortName: '',
    domain: '',
    imprintUrl: '',
    taxId: '',
    googleTagManagerId: '',
    gtcUrl: '',
    privacyPolicyUrl: '',
    websiteUrl: '',
    paymentSettings: {
        provider: '',
        payone: {
            environment: 'test',
            merchantId: '',
            apiKey: '',
            apiSecret: '',
            webhookKey: '',
            webhookSecret: '',
            paymentProducts: [],
        },
        mollie: {
            partnerId: '',
            apiKey: '',
            profileId: '',
            paymentMethods: [],
        }
    },
    legalContact: {
        salutation: null,
        firstName: null,
        lastName: null,
        address1: null,
        address2: null,
        companyName: null,
        postalCode: null,
        city: null,
        phone: {
            countryCode: null,
            phoneNumber: null
        },
        mobile: {
            countryCode: null,
            phoneNumber: null
        },
        country: 'DE',
        email: null
    },
    invoiceContact: {
        salutation: null,
        firstName: null,
        lastName: null,
        address1: null,
        address2: null,
        companyName: null,
        postalCode: null,
        city: null,
        country: null,
        phone: {
            countryCode: null,
            phoneNumber: null
        },
        mobile: {
            countryCode: null,
            phoneNumber: null
        },
        email: null
    },
    mailSettings: {
        description: '',
        server: '',
        port: '',
        username: '',
        password: '',
        sender: '',
        replyTo: null,
        bcc: null
    },
    voucherSettings: {
        enabled: false,
        apiBaseUri: null,
        apiPublicKey: null,
        apiPrivateKey: null,
        externalTenantId: null,
        externalEventId: null,
        events: []
    }
};

const api = new Api();

export const cleanupTenant = (tenant) => {
    if (tenant.paymentSettings && tenant.paymentSettings.provider === '') {
        return {...tenant, paymentSettings: {...tenant.paymentSettings, provider: null}}
    }

    return tenant;
};

const validateTenantSlugDebounced = debounce(slug => api.validateTenantSlug(slug), 500);

const Form = ({onSubmit, submitPending, tenant, helpTextsVisible}) => {
    const [filteredSlug, setFilteredSlug] = useState(tenant.slug ?? '');
    const [inputSlug, setInputSlug] = useState(tenant.slug ?? '');
    const [slugIsLoading, setSlugIsLoading] = useState(false);
    const [currentTab, setCurrentTab] = useState('home');

    const validateSlug = async (slug) => {
        if (!slug || inputSlug === slug) return;

        setInputSlug(slug);
        setFilteredSlug(slug);

        setSlugIsLoading(true);
        const result = await validateTenantSlugDebounced(slug);
        setSlugIsLoading(false);

        if (result) {
            if (result.tenantId && result.tenantId !== tenant.id) {
                return 'Der URL-Kürzel wird bereits verwendet'
            }
            if (result.errors && result.errors.length > 0) {
                return result.errors[0];
            }

            if (result.filteredSlug) {
                setFilteredSlug(result.filteredSlug);
            }
        }
    };

    return <Formik initialValues={{...INITIAL_VALUES, ...tenant}}
                   validationSchema={FORM_SCHEMA}
                   onSubmit={onSubmit}
    >
        {formik => {
            return (
                <form onSubmit={formik.handleSubmit} className={styles.formCotainer}>

                    <Tabs id="uncontrolled-tab-example" value={currentTab} onChange={(event, newTab) => setCurrentTab(newTab)}>
                        <Tab value="home" label="Stammdaten" />
                        <Tab value="payments" label="Zahlungen" />
                        <Tab value="smtp" label="E-Mail-Versand" />
                        <Tab value="api" label="APIs" />
                    </Tabs>

                    <div className={styles.tabsContentWrapper}>
                        <TabsPanel value={currentTab} index={'home'}>
                            <div className={styles.formBox}>
                                <h2 className={styles.formTitle}>Allgemein</h2>
                                <Row className="mt-3">
                                    <Col className="col-md-4">
                                        <FormikTextInputGroup label="Name des Mandanten*" name="name"
                                                              testid="name"/>
                                    </Col>
                                    <Col className="col-md-4">
                                        <FormikTextInputGroup label="URL-Kürzel*" name="slug" testid="slug"
                                                              validate={validateSlug}/>
                                        {slugIsLoading && <LoadingIndicator/>}
                                        {filteredSlug !== formik.values.slug && `URL-Kürzel, die in der Datenbank gespeichert werden sollen: "${filteredSlug}"`}

                                    </Col>
                                    <Col className="col-md-4">
                                        <FormikTextInputGroup
                                            label="Kurzbezeichnung des Mandanten*"
                                            name="shortName"
                                            testid="shortName"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col className="col-md-4">
                                        <FormikTextInputGroup
                                            label="Domain*"
                                            name="domain"
                                            testid="domain"
                                        />
                                    </Col>
                                    <Col className="col-md-4">
                                        <FormikTextInputGroup
                                            label="USt-ID*"
                                            name="taxId"
                                            testid="taxId"
                                            helpTextsVisible={helpTextsVisible}
                                        />
                                    </Col>
                                    <Col className="col-md-4">
                                        <FormikTextInputGroup
                                            label="URL zu Impressum*"
                                            name="imprintUrl"
                                            testid="imprintUrl"
                                            helpTextsVisible={helpTextsVisible}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col className="col-md-4">
                                        <FormikTextInputGroup
                                            label="Google Tagmanager Id"
                                            name="googleTagManagerId"
                                            testid="googleTagManagerId"
                                            helpTextsVisible={helpTextsVisible}
                                            helpText="Hier können Sie Ihre Google Tagmanager Id hinterlegen, wenn Sie das User-Verhalten mit Google im Frontend tracken wollen."
                                        />
                                    </Col>
                                    <Col className="col-md-4">
                                        <FormikTextInputGroup
                                            label="Link AGBs*"
                                            name="gtcUrl"
                                            placeholder="https://"
                                            helpText="Wird im Kaufprozess angezeigt."
                                        />
                                    </Col>
                                    <Col className="col-md-4">
                                        <FormikTextInputGroup
                                            label="Datenschutzbestimmungen*"
                                            name="privacyPolicyUrl"
                                            placeholder="https://"
                                            helpText="Wird im Kaufprozess angezeigt."
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col className="col-md-4">
                                        <FormikTextInputGroup
                                            label="Link Website*"
                                            name="websiteUrl"
                                            placeholder="https://"
                                            helpText="Wird im Kaufprozess angezeigt."
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <FormContact name="legalContact" title="Vertragsadresse" formik={formik} />
                            <FormContact name="invoiceContact" title="Rechnungsadresse" formik={formik} />
                        </TabsPanel>

                        <TabsPanel value={currentTab} index={'payments'}>
                            <FormPaymentSettings formik={formik} tenant={tenant}/>
                        </TabsPanel>
                        <TabsPanel value={currentTab} index={'smtp'}>
                            <FormMailSettings formik={formik} tenant={tenant}/>
                        </TabsPanel>
                        <TabsPanel value={currentTab} index={'api'}>
                            <FormVoucherSettings formik={formik} tenant={tenant}/>
                        </TabsPanel>
                    </div>

                    <Footer>
                        <FeedbackButton to={`/base/tenant`}>
                            Abbrechen
                        </FeedbackButton>
                        <FeedbackButton
                            type="submit"
                            busy={submitPending}
                        >
                            Speichern
                        </FeedbackButton>
                    </Footer>
                </form>
            )
        }}
    </Formik>
}

Form.propTypes = {
    onSubmit: PropTypes.any,
    submitPending: PropTypes.any,
    tenant: PropTypes.any,
    requestErrors: PropTypes.any
};


const mapStateToProps = (state, props) => {

    const helpTextsVisible = state.helpTextsToggle;

    return {
        helpTextsVisible
    }
};

export default connect(mapStateToProps)(Form);
