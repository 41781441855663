import React from 'react';
import {Card, Col, Row} from 'react-bootstrap';

import Api from '../../api';
import FeedbackButton from '../common/FeedbackButton';
import PropTypes from 'prop-types';
import {FormikCheckbox} from '../common/formik/FormikCheckbox';
import {FormikTextInputGroup} from '../common/formik/FormikTextInputGroup';
import MultiSelectList from '../common/form/MultiSelectList';
import HelpContent from '../common/help/HelpContent';
import styles from "../form.module.scss";

const api = new Api();

function FormZuko({formik, zukoZones, venueEvent, refs}) {

    const zukoZoneSelectFieldMap = {
        'entry': 'Zuko-Zone',
        'label': 'Zutrittszone im Ticketing'
    };


    const handleZukoZoneSelectionChange = (zukoZoneSelection) => {
        const selectedZukoZones = zukoZoneSelection.filter(selection => typeof (selection) === 'object');
        formik.setFieldValue('zukoZones', selectedZukoZones);
    };

    const sendTicketsToZuko = () => {
        api.regenerateZukoEntries(venueEvent.id)
    }

    return (
        <div className={styles.formBox}>
            <h2 className={styles.formTitle}>Zuko-Steuerung</h2>
            <Row className="mt-3">
                <Col className="pt-2 col-auto">
                    <p className="d-inline">Nehmen Sie hier die notwendigen Einstellungen vor, um das
                        Zutrittskontrollsystems für diesen Event zu steuern</p>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col className="pt-2 col-auto">
                    <FormikCheckbox label="Zuko für dieses Event nutzen"
                                    name="isZukoEnabled"/>
                </Col>
            </Row>
            {formik.values.isZukoEnabled && (
                <>
                    <Row className="mt-3">
                        <Col className="col-md-4">
                            <FormikTextInputGroup label="Zuko-Event-ID*" name="zukoEventId" inputRef={refs.zukoEventId} helpText="z.B. 1. Spieltag"/>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="col-md-4">
                            <h2 className="font-weight-bold pb-3 mb-3">Automatisch gestattete Zutrittszonen für alle Tickets dieses Events</h2>
                            <HelpContent as="p" className="text-muted">Absolut jedes Ticket für dieses Event erhält Zutritt zu den hier eingetragenen Zutrittszonen. Dieses Feld kann leer bleiben, wenn sie Zutrittszonen an allen Blöcken des Saalplanes eintragen.</HelpContent>
                            <MultiSelectList
                                fieldMap={zukoZoneSelectFieldMap}
                                elements={zukoZones}
                                selected={venueEvent.zukoZones}
                                onChange={selection => handleZukoZoneSelectionChange(selection)}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="pt-2 col-auto">
                            <HelpContent as="p" className="text-muted">Schaltet die Übertragung der Tickets an die Zuku ein bzw. pausiert sie.</HelpContent>
                            <FormikCheckbox label="Zuko Übertragung Aktiv"
                                            name="isZukoTransferEnabled"/>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="pt-2 col-auto">
                            <p className={venueEvent.zukoTransferLastToggledToActive === null ? 'hidden' : 'text-muted'}>Zuko zuletzt am {venueEvent.zukoTransferLastToggledToActive} scharf geschaltet</p>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="col-auto">

                            <FeedbackButton
                                onClick={() => sendTicketsToZuko()}
                            >
                                Alle Tickets dieses Events erneut an Zuko übermitteln
                            </FeedbackButton>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                         <Col className="col-auto">
                            <HelpContent as="p" className="text-muted">Hiermit werden alle bisherigen Tickets mit ggf zwischenzeitig aktualisierten Zutrittszonen erneut an die Zuko übermittelt.</HelpContent>
                         </Col>
                    </Row>
                </>
            )}
        </div>
    )
}

FormZuko.propTypes = {
    formik: PropTypes.object,
    zukoZones: PropTypes.array,
    venueEvent: PropTypes.object,
    refs: PropTypes.object
};

export default FormZuko;
