import React from 'react';
import {connect} from 'formik';

class ErrorFocus extends React.Component {
    constructor(props) {
        super(props);
        this.submitCount = 0;
    }

    componentDidUpdate(prevProps) {
        const {submitCount, isSubmitting, errors} = prevProps.formik;
        const keys = Object.keys(errors);

        if (keys.length > 0 && isSubmitting && this.submitCount < submitCount) {
            this.submitCount = submitCount;
            let errorElem = keys[0];
            let errorElement = '';
            if (errorElem === 'salesRules') {
                errorElement = this.props.refs.ticketLayout.current;
            } else {
                if(this.props.refs[errorElem]){
                    errorElement = this.props.refs[errorElem].current;
                }
            }

            if (errorElement) {
                let tab = errorElement.closest('[role="tabpanel"]');
                if (tab) {
                    if (tab.hasAttribute('hidden') && tab.dataset.eventKey) {
                        this.props.setCurrentTab(tab.dataset.eventKey);
                    }

                    let i = setInterval(function () {
                        if (!tab.hasAttribute('hidden')) {
                            errorElement.focus();
                            clearInterval(i)
                        }
                    }, 200);
                }
            }
        }
    }

    render() {
        return null;
    }
}

export default connect(ErrorFocus);
