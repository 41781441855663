import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FeedbackButton from "../../components/common/FeedbackButton";
import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogTitle, DialogContent, Grid, FormControl, InputLabel, OutlinedInput, Typography } from "@mui/material";
import MaterialDropdown from "../../components/layout/MaterialDropdown";
import { IState, selectBlocks, selectNewAddedSeats, selectPlaceCategories, selectSeatingTypes, selectSeatsByBlockAndRow } from "../../state/entities/venueEditor/selectors";
import { Seat, SeatRecord } from "../types";
import { PointGrid } from "../editor/geometry";
import { SEAT_DEFAULT_COLOR } from "../editor/display/scene";
import ObjectID from "bson-objectid";
import LoadingDialog from "../editor/LoadingDialog";



interface IAddSeatsDialogProps {
    open: boolean;
    pointGrid: Readonly<PointGrid>;
    onConfirm: (newSeats: SeatRecord) => void;
    onCancel: () => void;
}

const AddSeatsDialog: React.FC<IAddSeatsDialogProps> = ({open, pointGrid, onConfirm, onCancel}) => {
    const placeCategories = useSelector((state: IState) => selectPlaceCategories(state));
    const seatingTypes = useSelector((state: IState) => selectSeatingTypes(state));
    const blocks = useSelector((state: IState) => selectBlocks(state));
    const [placeCategoryId, setPlaceCategoryId] = useState<string>(placeCategories[0].id);
    const [seatingTypeId, setSeatingTypeId] = useState<string>(seatingTypes[0].id);
    const [blockId, setBlockId] = useState<string>(blocks[0].id);
    const [seatStartAt, setSeatStartAt] = useState<number>(1);
    const [rowStartAt, setRowStartAt] = useState<number>(1);
    //will be used later
    // const [rowSortType, setRowSortType] = useState<SortDirection>(SortDirection.ASCENDING);
    // const [seatSortType, setseatSortType] = useState<SortDirection>(SortDirection.ASCENDING);
    const [changedSeats, setChangedSeats] = useState<Seat[][]>([]);
    const seatsbyBlockAndRow = useSelector((state: IState) => selectSeatsByBlockAndRow(state));
    const newAddedSeats = useSelector((state: IState) => selectNewAddedSeats(state));
    const [isLoaderShowing, setIsLoaderShowing] = useState(false);



    useEffect(() => {
        if (!open)  return;
        //build grid of seats:
        const pointsByRow = pointGrid.getPointsByRow();
        const seatRows: Seat[][] = [];
        for (let row of pointsByRow) {
            const newRow: Seat[] = [];
            for (let point of row) {
                const seat: Seat = {
                    id: ObjectID.generate(),
                    row: '',
                    label: '',
                    enabled: true,
                    available: true,
                    tags: [],
                    style: 'AVAILABLE',
                    color: SEAT_DEFAULT_COLOR,
                    x: point.x,
                    y: point.y,
                    seatingTypeId: seatingTypes[0].id,
                    pricingCategoryId: placeCategories[0].id,
                    blockId: blocks[0].id
                }
                newRow.push(seat);
            }
            seatRows.push(newRow);
        }
        setChangedSeats(seatRows);
    }, [open, pointGrid, seatingTypes, placeCategories, blocks]);


    useEffect(() => {
        if (!open) return;
        if (isNaN(rowStartAt) || isNaN(seatStartAt)) return;
        setChangedSeats(prevChangedSeats => {
            const newChSeats = prevChangedSeats.map((row, rowIndex) => row.map((seat, seatIndex) => {
                return {
                    ...seat,
                    row: (rowStartAt + rowIndex).toString(),
                    label: (seatStartAt + seatIndex).toString()
                }
            }));
            if (prevChangedSeats.length === 0) return newChSeats;
            return newChSeats;
        });
    }, [open, rowStartAt, seatStartAt])

    useEffect(() => {
        if (newAddedSeats.length) setIsLoaderShowing(false);
    }, [newAddedSeats])


    const changeSeats = (cb: (seat) => Seat) => {
        const newChangedSeats = changedSeats.map(row => row.map(seat => cb(seat)));
        setChangedSeats(newChangedSeats);
    }


    const handlePlaceCategoryChange = (value: string) => {
        setPlaceCategoryId(value);
        changeSeats((seat) => {return {...seat, pricingCategoryId: value}});
    }

    const handleSeatingTypeChange = (value: string) => {
        setSeatingTypeId(value);
        changeSeats((seat) => {return {...seat, seatingTypeId: value}});
    }

    const handleBlockLabelChange = (value: string) => {
        setBlockId(value);
        changeSeats((seat) => {return {...seat, blockId: value}});
    }

    const handleRowStartAtChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const numValue = parseInt(e.target.value);
        setRowStartAt(numValue);
    }

    const handleSeatStartAtChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const numValue = parseInt(e.target.value);
        setSeatStartAt(numValue);
    }


    const handleOKClick = () => {
        const seatsFlattened = changedSeats.flat();
        const newSeats: SeatRecord = seatsFlattened.reduce((newSeats, seat) => {
            newSeats[seat.id] = seat;
            return newSeats;
        }, {});
        setIsLoaderShowing(true);
        onConfirm(newSeats);
    }

    //check for row/label value collisions and errors
    let errorHint = "";
    for (const row of changedSeats) {
        for (const currChSeat of row) {
            const seatsByBlockAndRow = seatsbyBlockAndRow;
            if (!(seatsByBlockAndRow[currChSeat.blockId] && seatsByBlockAndRow[currChSeat.blockId][currChSeat.row])) continue;
            for (const seat of seatsByBlockAndRow[currChSeat.blockId][currChSeat.row]) {    // check if combination already exists
                if (seat.label === currChSeat.label) {
                    errorHint = "Kombi aus Sitz-/Reihenbezeichnung existiert bereits.";
                    break;
                }
            }
            if (errorHint) break;
        }
    }


    return (
        <>
            <Dialog open={open} fullWidth={true} maxWidth={"md"}>
                <DialogTitle>Sitze hinzufügen</DialogTitle>
                <DialogContent dividers={true}>
                    <Grid container alignItems="center" columns={3} spacing={2}>
                        <Grid item md={1}>
                            <MaterialDropdown
                                label="Platzkategorie"
                                startAdornment={false}
                                value={placeCategoryId}
                                onChange={handlePlaceCategoryChange}
                                options={placeCategories.map(placeCategory => {return {label: placeCategory.name, value: placeCategory.id}})}
                            />
                        </Grid>
                        <Grid item md={1}>
                            <MaterialDropdown
                                label="Bestuhlungstyp"
                                value={seatingTypeId}
                                startAdornment={false}
                                onChange={handleSeatingTypeChange}
                                options={seatingTypes.map(seatingType => {return {label: seatingType.name, value: seatingType.id}})}
                            />
                        </Grid>
                        <Grid item md={1}>
                            <MaterialDropdown
                                label="Blockbezeichnung"
                                startAdornment={false}
                                value={blockId}
                                onChange={handleBlockLabelChange}
                                options={[{label: 'Bitte wählen', value: 'idPleaseChoose'}].concat(blocks.map(block => {return {label: block.backendNameShort, value: block.id}}))}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" columns={2} spacing={2}>
                        <Grid item md={1}>
                            <FormControl
                                fullWidth
                                variant="outlined"
                            >
                                <InputLabel>Reihen beginnen bei:</InputLabel>
                                <OutlinedInput
                                    value={isNaN(rowStartAt) ? '' : rowStartAt}
                                    type="number"
                                    placeholder="z.B. 10"
                                    onChange={handleRowStartAtChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={1}>
                            <FormControl
                                fullWidth
                                variant="outlined"
                            >
                                <InputLabel>Sitze beginnen bei:</InputLabel>
                                <OutlinedInput
                                    value={isNaN(seatStartAt) ? '' : seatStartAt}
                                    type="number"
                                    placeholder="z.B. 10"
                                    onChange={handleSeatStartAtChange}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
    {/*                 <Grid container alignItems="center" columns={2} spacing={2}>
                        <Grid item md={1}>
                            <MaterialDropdown
                                label="Reihen-Sortierung"
                                startAdornment={false}
                                value={SortDirection.ASCENDING}
                                onChange={handlePlaceCategoryChange}
                                options={[{label: 'Aufsteigend', value: SortDirection.ASCENDING}, {label: 'Absteigend', value: SortDirection.DESCENDING}]}
                            />
                        </Grid>
                        <Grid item md={1}>
                            <MaterialDropdown
                                label="Sitze-Sortierung"
                                value={SortDirection.ASCENDING}
                                startAdornment={false}
                                onChange={handleSeatingTypeChange}
                                options={[{label: 'Aufsteigend', value: SortDirection.ASCENDING}, {label: 'Absteigend', value: SortDirection.DESCENDING}]}
                            />
                        </Grid>
                    </Grid>
                    */}
                    <Typography variant="subtitle2" color="error" align="center" gutterBottom>
                        {errorHint}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <FeedbackButton onClick={onCancel} variant={'outlined'}>Abbrechen</FeedbackButton>
                    <FeedbackButton
                        onClick={handleOKClick}
                        disabled={errorHint !== ''}
                    >Hinzufügen</FeedbackButton>
                </DialogActions>
            </Dialog>
            <LoadingDialog isLoading={isLoaderShowing && open} />
        </>
    );
};



export default AddSeatsDialog;
