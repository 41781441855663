import { Point } from "pixi.js"
import { VisualItemData } from "../editableVisuals/visualItemData"


export enum StandingBlockType {
    STANDING_BLOCK = 'standingBlock',
    FREE_SEAT_SELECTION = 'freeSeatSelection'
};


export interface AreaFormData extends VisualItemData {
    id: string,
    title: string,
    ticketHint: string,
    shortTitle: string,
    blockType: StandingBlockType,
    priceCategoryId: string,
    x: number,
    y: number,
    points: Array<Point>
}
