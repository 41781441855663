import { history } from '../../configureStore';
import {all, takeEvery} from 'redux-saga/effects';

import entitySlice from '../entity/slice';
import Api from '../../../api';
import {block as schema, blockList as schemaList} from '../../schema';
import Block from "./resource";
import {byVenuePlan} from './slice';
import {byVenuePlanSaga} from './saga';
import loadingSlice from "../entity/slice/load";
import creatingSlice from "../entity/slice/create";
import savingSlice from "../entity/slice/save";
import deletingSlice from "../entity/slice/delete";
import {combineReducers} from "redux";
import byId from "../entity/slice/byId";
import createSaga from "../entity/saga/create";
import loadSaga from "../entity/saga/load";
import saveSaga from "../entity/saga/save";
import deleteSaga from "../entity/saga/delete";

const onCreateSuccess = entity => {
    history.push(`/base/venue-plan/${entity.venuePlan.id}/block/${entity.blockType}/${entity.id}`);
};
const entityName = 'block';

const slice = entitySlice(entityName);


const api = new Api();
const resource = new Block(api.axios);

const loadSlice = loadingSlice(entityName);
const createSlice = creatingSlice(entityName);
const saveSlice = savingSlice(entityName);
const deleteSlice = deletingSlice(entityName);

export const actions = {
    ...createSlice.actions,
    ...loadSlice.actions,
    ...saveSlice.actions,
    ...deleteSlice.actions,
    ...byVenuePlan.actions
};

export const reducer = combineReducers({
    creating: slice.reducer,
    loading: loadSlice.reducer,
    saving: saveSlice.reducer,
    deleting: deleteSlice.reducer,
    byId: byId(entityName),
    byVenuePlan: byVenuePlan.reducer
});

export const saga = function* saga() {
    yield all([
        takeEvery(actions.load, loadSaga({actions, resource, schema})),
        takeEvery(actions.save, saveSaga({actions, resource, schema})),
        takeEvery(actions.delete, deleteSaga({actions, resource, schema})),
        takeEvery(actions.create, createSaga({actions, resource, schema, onSuccess: onCreateSuccess})),
        takeEvery(actions.byVenuePlan, byVenuePlanSaga({actions, resource, schemaList})),
    ])
};










