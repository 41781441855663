import axios from 'axios';
import {get, isUndefined} from 'lodash';
// import store from './state/configureStore';
import authService from "./components/auth/authService";
import {PAGINATION_WINDOW_SIZE_DEFAULT} from './components/common/listView/ListViewPaginationControl';
import {ConcurrencyManager} from 'axios-concurrency';
import {getApiBaseUrl} from "./utils/utils";

const config = {
    authHeader: 'Authorization',
    authPrefix: 'Bearer '
};

/**
 * Client für eine RESTful Resource.
 */
export class Resource {

    constructor(entityName, axios) {
        this.entityName = entityName;
        this.axios = axios;
    }

    /**
     * Ruft alle Instanzen der Entität ab
     */
    getAll = () => {
        return this.axios.get(`/${this.entityName}`)
            .then((res) => res.data);
    };

    /**
     * Ruft die Instanz mit der gegebenen ID ab.
     */
    fetch = (id) => {
        return this.axios.get(`/${this.entityName}/${id}`)
            .then((res) => res.data);
    };

    /**
     * Legt eine Kopie der Instanz mit der gegebenen ID an.
     */
    copy = (id) => {
        return this.axios.post(`/${this.entityName}/${id}/copy`)
            .then((res) => res.data);
    };

    /**
     * Löscht die Instanz mit der gegebenen ID.
     */
    delete = (id) => {
        return this.axios.delete(`/${this.entityName}/${id}`)
            .then((res) => res.data);
    };

    /**
     * Legt eine neue Instanz der Resource mit den gegebenen Daten an.
     */
    create = (data) => {
        return this.axios.post(`/${this.entityName}`, data)
            .then((res) => res.data);
    };

    /**
     * Aktualisiert die Instanz mit der in den Daten angegebenen ID und den gegebenen Daten.
     */
    save = (data) => {
        return this.axios.post(`/${this.entityName}/${data.id}`, data)
            .then((res) => res.data);
    };
}

export default class Api {

    static instance;

    constructor() {
        // Singleton pattern
        if (Api.instance) {
            return Api.instance;
        }

        const headers = {};
        this.axios = axios.create({
            baseURL: getApiBaseUrl()+'/api/v1/backend',
            headers
        });

        this.axios.interceptors.request.use((requestConfig) => {
                const jwt = authService.getAccessToken();
                if (jwt) {
                    requestConfig.headers.common[config.authHeader] =  config.authPrefix + jwt;
                }
                const getCurrentTenant = JSON.parse(localStorage.getItem('state'));
                requestConfig.headers.common['Current-Tenant'] = getCurrentTenant && getCurrentTenant.currentTenant ? getCurrentTenant.currentTenant.id : null;

                return requestConfig;
        }, (error) => {
            return Promise.reject(error);
        });

        let isAlreadyFetchingAccessToken = false;

        this.axios.interceptors.response.use(res => res, error => {
            // Bei Fehlern mit Status 4XX interessiert uns die Reponse, da üblicherweise eine sinnvolle Fehlermeldung
            // enthalten ist, die wir auch anzeigen können.
            if (error?.response?.status < 500) {

                const data = error.response.data;

                if (data.code === 401 && data.errorType === 'tenant.notFound'
                    && localStorage.getItem('state')) {
                    localStorage.setItem('clearState', 'true');
                    // noinspection SillyAssignmentJS
                    window.location.href = window.location.href;
                } else if (data.code === 401 && data.message === 'Invalid JWT Token') {
                    // If the public key was changed try to get a new pair of tokens
                    if (!isAlreadyFetchingAccessToken) {
                        authService.signInSilent();
                        isAlreadyFetchingAccessToken = true

                        // Reset isAlreadyFetchingAccessToken after 1m
                        setTimeout(() => {
                            isAlreadyFetchingAccessToken = false
                        },  60000);
                    }
                }

                return Promise.reject(error.response.data);
            }

            return Promise.reject(error);
        });

        this.pricingList = new Resource('pricingList', this.axios);
        this.pricingMatrix = new Resource('pricingMatrix', this.axios);
        this.pricingCategory = new Resource('pricingCategory', this.axios);
        this.pricingClass = new Resource('pricingClass', this.axios);
        this.purchasableItemTemplate = new Resource('purchasableItemTemplate', this.axios);
        this.backendUser = new Resource('backendUser', this.axios);
        this.seatingType = new Resource('seatingType', this.axios);
        this.tenant = new Resource('tenant', this.axios);
        this.eventCategory = new Resource('eventCategory', this.axios);
        this.media = new Resource('media', this.axios);
        this.venue = new Resource('venue', this.axios);
        this.venuePlan = new Resource('venuePlan', this.axios);
        this.venueEditor = new Resource('venueEditor', this.axios);
        this.zukoZone = new Resource('zukoZone', this.axios);
        this.blockGroup = new Resource('blockGroup', this.axios);
        this.block = new Resource('block', this.axios);
        this.seat = new Resource('seat', this.axios);
        this.placePool = new Resource('placePool', this.axios);
        this.salesRule = new Resource('salesRule', this.axios);
        this.targetGroup = new Resource('targetGroup', this.axios);

        Api.instance = this;
    }

    createResource(entityName) {
        return new Resource(entityName, this.axios);
    }

    switchToSerialClient() {
        ConcurrencyManager(this.axios, 1);
    }

    getVenuePlan(venuePlanId) {
        return this.axios.get(`/venuePlan/${venuePlanId}`)
            .then((res) => res.data);
    }


    getVenuePlanSettings(venuePlanId) {
        return this.axios.get(`/venuePlan/${venuePlanId}/settings`)
        .then((res) => res.data);
    }

    /**
     * Data structure:
     * {
     *   "frontendSettings": {
     *     "showRowNumberAtBeginningOfRow": false,
     *     "showRowNumberAtEndOfRow": true,
     *     "showSeatLabels": false
     *   },
     *   "backendSettings": {
     *     "showRowNumberAtBeginningOfRow": true,
     *     "showRowNumberAtEndOfRow": false,
     *     "showSeatLabels": true
     *   }
     * }
     * 
     * @param {*} venuePlanId 
     * @param {*} settingsData 
     * @returns 
     */
    updateVenuePlanSettings(venuePlanId, settingsData) {
        return this.axios.post(`/venuePlan/${venuePlanId}/settings`, settingsData)
        .then((res) => res.data);
    }

    getVenueEventByIdent(ident) {
        return this.axios.get(`/venueEvent/byIdent`, {params: {ident}})
            .then((res) => res.data);
    }

    getMetaEventByIdent(ident) {
        return this.axios.get(`/metaEvent/byIdent`, {params: {ident}})
            .then((res) => res.data);
    }

    getTenantsSelect() {
        return this.axios.get('/tenant/select')
            .then((res) => res.data);
    }

    getPricingListsByQuery(query) {
        return this.axios.get('/pricingList/byQuery', {params: {query}})
            .then((res) => res.data);
    }

    savePricingListWithMatrix(pricingId, matrixId, data) {
        return this.axios.post(`/pricingList/${pricingId}/withMatrix/${matrixId}`, data)
            .then((res) => res.data);
    }

    getTicketLayoutsByQuery(query) {
        return this.axios.get('/ticketLayout/byQuery', {params: {query}})
            .then((res) => res.data);
    }

    getPricingCategoriesByQuery(query) {
        return this.axios.get('/pricingCategory/byQuery', {params: {query}})
            .then((res) => res.data);
    }

    getBlockByBackendNameShort(backendNameShort, venuePlanId) {
        return this.axios.get(`/block/byBackendNameShort`, {params: {venuePlanId, backendNameShort}})
            .then((res) => res.data);
    }


    getPricingClassByIdent(ident) {
        return this.axios.post(`/pricingClass/byIdent`, null, {params: {ident}})
            .then((res) => res.data);
    }

    getVenuePlanByBackendNameAndVenueId(backendName, venueId) {
        return this.axios.get('/venuePlan/byBackendNameAndVenueId', {params: {backendName, venueId}})
            .then((res) => res.data);
    }

    getPricingCategoryByIdent(ident) {
        return this.axios.post(`/pricingCategory/byIdent`, null, {params: {ident}})
            .then((res) => res.data);
    }

    byVenuePlan(id) {
        return this.axios.get(`/block/byVenuePlan/${id}`)
            .then((res) => res.data);
    };

    getSeatsByVenuePlan(venuePlanId) {
        return this.axios.get(`/seat/byVenuePlan/${venuePlanId}`)
            .then((res) => res.data);
    }

    addSeatsByVenuePlan(data) {
        return this.axios.post(`/seat/batch-create`, data)
            .then((res) => res.data);
    }

    updateSeatsByVenuePlan(data) {
        return this.axios.post(`/seat/batch-update`, data)
            .then((res) => res.data);
    }

    deleteSeats(data) {
        return this.axios.post(`/seat/batch-delete`, data)
            .then((res) => res.data);
    }

    getImagesByVenuePlan(venuePlanId) {
        return this.axios.get(`/venuePlanImage/byVenuePlan/${venuePlanId}`)
            .then((res) => res.data);
    }

    addImageToVenuePlan(data) {
        return this.axios.post(`/venuePlanImage`, data)
            .then((res) => res.data);
    }

    updateImagesOfVenuePlan(data) {
        return this.axios.post(`/venuePlanImage/batch-update`, data)
            .then((res) => res.data);
    }

    deleteImageOfVenuePlan(imageId) {
        return this.axios.delete(`/venuePlanImage/${imageId}`)
            .then((res) => res.data);
    }

    removePlacePoolFromAllSeatsOfBlock(id, placePoolId) {
        return this.axios.post(`/block/${id}/placePool/${placePoolId}/clear`).then((res) => res.data);
    }

    getPlacePoolsByQuery(query) {
        return this.axios.get('/placePool/byQuery', {params: {query}})
            .then((res) => res.data);
    }

    getPlacePoolsBySeatsIds(ids, query) {
        return this.axios.post('/placePool/query/bySeatsIds', {ids, query})
            .then((res) => res.data);
    }

    getSeatingTypes() {
        return this.axios.get('/seatingType')
            .then((res) => res.data);
    }

    seatsBatchAction(action, seatsIds, itemsIds) {
        return this.axios.post(`/seat/batch-action/${action}`, {seatsIds, itemsIds})
            .then((res) => res.data);
    }

    batchActionChangeArchivityStatus(action, itemsIds, itemsType) {
        return this.axios.post(`/listingView/batch-action/${action}`, {itemsIds, itemsType})
            .then((res) => res.data);
    }
    batchActionCancelPayment(id, data) {
        return this.axios.post(`/order/${id}/cancel`, data)
            .then((res) => res.data);
    }
    resolveServiceCase(data) {
        return this.axios.post(`/serviceCase/batchResolve`, data)
            .then((res) => res.data);
    }

    getSalesRulesByQuery(query) {
        return this.axios.get('/salesRule/byQuery', {params: {query}})
            .then((res) => res.data);
    }

    getTagsForSalesRulesByQuery(query) {
        return this.axios.get('/salesRule/tags/byQuery', {params: {query}})
            .then((res) => res.data);
    }

    getTagsForMetaEventsByLang(lang) {
        return this.axios.get('/metaEvent/tags/byLang', {params: {lang}})
            .then((res) => res.data);
    }

    getTagsForVenueEventsByLang(lang) {
        return this.axios.get('/venueEvent/tags/byLang', {params: {lang}})
            .then((res) => res.data);
    }

    getTagsForArtistByLang(lang) {
        return this.axios.get('/artist/tags/byLang', {params: {lang}})
            .then((res) => res.data);
    }

    getTagsForEventSeriesByLang(lang) {
        return this.axios.get('/eventSeries/tags/byLang', {params: {lang}})
            .then((res) => res.data);
    }

    getTagsForVenueByLang(lang) {
        return this.axios.get('/venue/tags/byLang', {params: {lang}})
            .then((res) => res.data);
    }

    getTagsForMediaByLang(lang) {
        return this.axios.get('/media/tags/byLang', {params: {lang}})
            .then((res) => res.data);
    }

    getEventCategoriesByQuery(query) {
        return this.axios.get('/eventCategory/byQuery', {params: {query}})
            .then((res) => res.data);
    }

    getArtistsByQuery(query, eventCategoryId = null) {
        return this.axios.get('/artist/byQuery',
                            {params: {
                                        query,
                                    eventCategoryId: eventCategoryId
                            }}).then((res) => res.data);
    }

    getTenantGoogleTagManagerId(tenantId) {
        return this.axios.get(`/tenant/${tenantId}`)
            .then((res) => res.data);
    }

    getArtistEvents(artistId) {
        return this.axios.get(`/artist/${artistId}/events`)
            .then((res) => res.data);
    }

    getMediaEvents(mediaId) {
        return this.axios.get(`/media/${mediaId}/events`)
            .then((res) => res.data);
    }

    getMediaArtists(mediaId) {
        return this.axios.get(`/media/${mediaId}/artists`)
            .then((res) => res.data);
    }

    getMediaEventSeries(mediaId) {
        return this.axios.get(`/media/${mediaId}/eventSeries`)
            .then((res) => res.data);
    }

    getMediaTicketLayouts(mediaId) {
        return this.axios.get(`/media/${mediaId}/ticketLayouts`)
            .then((res) => res.data);
    }

    getMediaVenues(mediaId) {
        return this.axios.get(`/media/${mediaId}/venues`)
            .then((res) => res.data);
    }

    uploadMediaFile(formData) {
        return this.axios.post(`/mediaFile/upload`, formData)
            .then((res) => res.data);
    }

    removeMediaFile(mediaFileId) {
        return this.axios.delete(`/mediaFile/${mediaFileId}`)
            .then((res) => res.data);
    }

    getTargetGroups() {
        return this.axios.get('/targetGroup')
            .then((res) => res.data);
    }

    getTargetGroupsByQuery(query) {
        return this.axios.get('/targetGroup/byQuery', {params: {query}})
            .then((res) => res.data);
    }

    getSalesChannels() {
        return this.axios.get('/salesChannel')
            .then((res) => res.data);
    }

    queryListView(endpoint, query) {

        const filter = get(query, 'filter', {});
        const sortBy = get(query, 'sort.field');

        const params = {
            maxPerPage: get(query, 'pagination.maxPerPage', PAGINATION_WINDOW_SIZE_DEFAULT),
            currentPage: get(query, 'pagination.currentPage', 1),
            sortBy,
            order: isUndefined(sortBy) ? undefined :
                (get(query, 'sort.ascending') ? 'asc' : 'desc')
        };

        return this.axios.post(endpoint, filter, {params})
            .then(res => {
                
                return {
                    fields: res.data.fields,
                    items: res.data.results,
                    pagination: {
                        currentPage: res.data.currentPage,
                        totalPages: res.data.nbPages,
                        maxPerPage: res.data.maxPerPage,
                        totalCount: res.data.nbResults,
                    }
                };
            });
    }

    queryBreadcrumbs(endpoint, query) {
        const filter = query ? query : '';

        return this.axios.get(endpoint+filter)
            .then(res => {
                return {
                    items: res.data
                };
            });
    }

    validateCSVImport(file, venueId) {
        let formData = new FormData();
        formData.append('importFile', file);
        formData.append('venueId', venueId);

        return this.axios.post('/validateCSVImport', formData)
            .then((res) => res.data)
    }

    createVenuePlanViaCSVImport(file, venuePlan) {
        let formData = new FormData();
        formData.append('importFile', file);
        formData.append('venuePlan', venuePlan);

        return this.axios.post('/importCSV', formData)
            .then((res) => res.data)
            .catch((err) => Promise.reject(err.response));
    }

    regenerateZukoEntries(venueEventId) {
        return this.axios.post(`/venueEvent/${venueEventId}/regenerateZukoEntries`)
            .then((res) => res.data);
    }

    getDeliveryGroupsForVenueEvent(startDate) {
        return this.axios.get('/deliveryGroup/venueEvent', {params: {eventStartDate: startDate}})
            .then((res) => res.data);
    }

    getDeliveryGroupsForMetaEvent(startDate) {
        return this.axios.get('/deliveryGroup/metaEvent', {params: {eventStartDate: startDate}})
            .then((res) => res.data);
    }

    getStandingBlocksData(venueEventId, venuePlanId) {
        return this.axios.get('/venueEvent/standingBlocks', {params: {venueEventId, venuePlanId}})
            .then((res) => res.data);
    }

    getQuotasData(eventId) {
        return this.axios.get('/salesRule/quotas-usage', {params: {eventId}})
            .then((res) => res.data);
    }

    getMetaEventStatus(metaEventId, venuePlanId) {
        return this.axios.get(`/metaEvent/status`, {params: {metaEventId, venuePlanId}})
            .then((res) => res.data);
    }

    getEventSummary(venueEventId) {
        return this.axios.get(`/venueEvent/${venueEventId}/summary`)
            .then((res) => res.data);
    }

    getEventSummaryPlacesByBlockGroup(venueEventId) {
        return this.axios.get(`/venueEvent/${venueEventId}/summary/placesByBlockGroup`)
            .then((res) => res.data);
    }

    getEventSummaryPlacesByBlock(venueEventId) {
        return this.axios.get(`/venueEvent/${venueEventId}/summary/placesByBlock`)
            .then((res) => res.data);
    }

    getEventTicketsSummaryBySalesChannel(venueEventId) {
        return this.axios.get(`/venueEvent/${venueEventId}/summary/ticketsBySalesChannel`)
            .then((res) => res.data);
    }

    getEventTicketsSummaryBySalesRule(venueEventId) {
        return this.axios.get(`/venueEvent/${venueEventId}/summary/ticketsBySalesRule`)
            .then((res) => res.data);
    }

    getEventTicketsSummaryPlacesByPlacePool(venueEventId) {
        return this.axios.get(`/venueEvent/${venueEventId}/summary/placesByPlacePool`)
            .then((res) => res.data);
    }

    getEventTicketsSummaryPlacesByPricingCategory(venueEventId) {
        return this.axios.get(`/venueEvent/${venueEventId}/summary/placesByPricingCategory`)
            .then((res) => res.data);
    }

    getEventTicketsByMarketPlaceSummary(venueEventId) {
        return this.axios.get(`/venueEvent/${venueEventId}/summary/ticketsByMarketPlace`)
            .then((res) => res.data);
    }

    stopJob(id) {
        return this.axios.post(`/job/${id}/stop`);
    }

    getUsedBundlesForEvent(eventId) {
        return this.axios.get(`/venueEvent/${eventId}/used-bundles`)
            .then((res) => res.data);
    }

    getEventsByQuery(query) {
        return this.axios.get('/event/byQuery', {params: {query}})
            .then((res) => res.data);
    }

    getMetaEventsByQuery(query) {
        return this.axios.get('/metaEvent/byQuery', {params: {query}})
            .then((res) => res.data);
    }

    getUsedEventsBySalesRule(salesRuleId) {
        return this.axios.get(`/salesRule/${salesRuleId}/events`)
            .then((res) => res.data);
    }

    ticketLayoutConfig() {
        return this.axios.get(`/ticketLayout/config`)
            .then((res) => res.data);
    }

    getPayonePaymentProducts(paymentSettingsData) {
        return this.axios.post(`/tenant/payment/payone/paymentProducts`, paymentSettingsData)
            .then((res) => res.data);
    }

    getMolliePaymentMethods(paymentSettingsData) {
        return this.axios.post(`/tenant/payment/mollie/paymentMethods`, paymentSettingsData)
            .then((res) => res.data);
    }

    previewTicketLayout(data) {
        return this.axios.request({
            responseType: 'arraybuffer',
            url: `/ticketLayout/preview`,
            method: 'post',
            data: data,
        })
    }

    checkTenantSmtpSettings(settings) {
        return this.axios.post(`/tenant/mailSettings/check`, settings)
            .then((res) => res.data);
    }

    getExternalEvents() {
        return this.axios.get(`/event/voucherExternalEvents`)
            .then((res) => res.data);
    }

    checkTenantVoucherSettings(settings) {
        return this.axios.post(`tenant/voucherSettings/check`, settings)
            .then((res) => res.data);
    }

    validateTenantSlug(slug) {
        return this.axios.post(`/tenant/validateSlug`, null, {params: {slug}})
            .then((res) => res.data);
    }

    getBackendUserFormOptions() {
        return this.axios.get(`/backendUser/form-options`)
            .then((res) => res.data);
    }

    getBIData(orderId) {
        return this.axios.get(`/order/${orderId}/bi`)
            .then((res) => res.data);
    }

}

