import React from "react";
import styles from "./baseLayout.module.scss";
import FeedbackButton from "../../components/common/FeedbackButton";
import { useSelector } from "react-redux";
import { IState, selectAllSeats, selectPlacepoolDefinitions, selectSelectedSeats } from "../../state/entities/venueEditor/selectors";
import { Seat, SeatInArrangement } from "../types";
import { countBy, get } from "lodash";
import classnames from "classnames";

enum PPSelectionState {
    ALL = "allSelected",
    PARTIALLY = "partiallySelected",
    NONE = "noneSelected"
}

export type TabPlacepoolChangeFunc = (seats:Seat[], tagsChanged: boolean) => void;

interface ITabPlacePoolsProps {
    onSelectedSeatsChange?: TabPlacepoolChangeFunc;
}



// Tip: Tag ist dasselbe wie Placepool (muss mal glattgezogen werden)
const TabPlacePools: React.FC<ITabPlacePoolsProps> = ({onSelectedSeatsChange}) => {
    const selectedSeats = useSelector((state: IState) => selectSelectedSeats(state));
    const allSeats = useSelector((state: IState) => selectAllSeats(state));
    const placepoolDefinitions = useSelector((state: IState) => selectPlacepoolDefinitions(state));

    // 2 sperate Funktionen, damit nicht für jedes Tag das komplette Counting gemacht wird (Performance)
    const getFlatSelectedTagCounts = (selectedSeats) => {
        const seatsToCount = anySeatsSelected ? selectedSeats : Object.values(allSeats);
        // Tags aus allen ausgewählten Plätzen flatten
        const appliedTags: string[] = seatsToCount.map((selectedSeat: Readonly<SeatInArrangement>) => selectedSeat?.tags)
            .reduce((a, b) => a?.concat(b), []);
        return countBy(appliedTags);
    }
    const anySeatsSelected = selectedSeats && selectedSeats.length > 0;
    const flatTagCounts = getFlatSelectedTagCounts(selectedSeats);

    const getTagSeletionState = (tagId: string): PPSelectionState => {
        if (!flatTagCounts[tagId]) return PPSelectionState.NONE;
        const tagCount: number = get(flatTagCounts, tagId);
        return tagCount === selectedSeats.length ? PPSelectionState.ALL : PPSelectionState.PARTIALLY;
    };
    
    const handlePlacepoolSelect = (placepoolId: string) => {
        let changedSeats = selectedSeats.map((seat) => {
            let newSeat: Seat =  {...seat};
            if (newSeat.tags === undefined) newSeat.tags = [];
            if (getTagSeletionState(placepoolId) === PPSelectionState.ALL) { //bei allen deselektieren
                newSeat.tags = newSeat.tags.filter((pp) => pp !== placepoolId);
            } else {
                if (!newSeat.tags.includes(placepoolId)) newSeat.tags = [...newSeat.tags, placepoolId]; // bei allen selektieren
            }
            return newSeat;
        });
        onSelectedSeatsChange(changedSeats, true);
    }

    const handleDeselectAllClick = () => {
        let changedSeats = selectedSeats.map((seat) => {
            return {...seat, tags: []};
        });
        onSelectedSeatsChange(changedSeats, true);
    }

    return (
        <div className={styles.tabsContent}>
            <h2 className={styles.tabsContent__title}>Platzpools</h2>
            <h3>Platzpools werden genutzt, um den Verkauf und die Preise mittels Verkaufsregeln steuern zu können. Plätze können zu mehreren Platzpools gehören.</h3>
            <div className={styles.tabsContent__colorsContainer}>
                <div className={classnames(styles.tabsContent__colorBox, styles.tabsContent__colorBoxHead)}>
                    <p className={styles.tabsContent__colorBoxTitle} style={{ fontWeight: 'bold'}}>Platzpools</p>
                    <p className={styles.tabsContent__colorBoxNumber} style={{ fontWeight: 'bold'}}>Anzahl</p>
                </div>
                {placepoolDefinitions.map((pp, index) => (
                    <PlacePoolButton
                        key={pp.id}
                        backgroundColor={pp.color}
                        title={pp.name}
                        tagId={pp.id}
                        count={flatTagCounts[pp.id] || 0}
                        selectionState={getTagSeletionState(pp.id)}
                        onSelect={handlePlacepoolSelect}
                    />
                ))}
            </div>
            <FeedbackButton className="mt-2" variant="outlined" disabled={selectedSeats.length === 0} onClick={handleDeselectAllClick}>Alle abwählen</FeedbackButton>
        </div>
    );
};



interface IPlacePoolButtonProps {
    backgroundColor: string,
    title: string,
    tagId: string,
    selectionState: PPSelectionState,
    count: number,
    onSelect: (placepoolId: string) => void;
}


const PlacePoolButton: React.FC<IPlacePoolButtonProps> = ({backgroundColor, title, tagId, selectionState, count, onSelect}) => {

    return (
        <div 
            className={styles.tabsContent__colorBox} 
            style={selectionState === PPSelectionState.NONE
                ? {}
                : (selectionState === PPSelectionState.ALL 
                    ? {backgroundColor: "#" + backgroundColor}
                    : {backgroundColor: "#DDD"})
            }
            onClick={() => onSelect(tagId)}
        >
            <div style={{ backgroundColor: "#" + backgroundColor }} className={styles.tabsContent__colorBoxColor}>{/*<ColorizeOutlinedIcon />*/}</div>
            <p className={styles.tabsContent__colorBoxTitle}>{title}</p>
            <p className={styles.tabsContent__colorBoxNumber}>{count}</p>
            {/*<IconButton onClick={toggleVisibilityBoxColor} className={styles.tabsContent__colorBoxEyeIcon}>
                {visibilityColor ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
            </IconButton>
            <IconButton onClick={handleEditColor} className={styles.tabsContent__colorBoxEditIcon}>
                <EditOutlinedIcon />
            </IconButton>
            <IconButton onClick={handleDeleteColor} className={styles.tabsContent__colorBoxDeleteIcon}>
                <DeleteOutlineOutlinedIcon />
            </IconButton> */}
        </div>
    );
}



export default TabPlacePools;
