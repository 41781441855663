import {Container, Polygon} from 'pixi.js';
import { AreaForm } from './areaFormItem';
import { AreaFormData } from './AreaFormData';
import { IVisualsManager, VisualsManager } from '../editableVisuals/visualsManager';


export const AREAFORMS_ENABLED = false;  //for intermediate releases


export interface IAreaFormsManager extends IVisualsManager<AreaFormData> {
    onRequestAddAreaForm: (rect: Polygon) => void;
}



export class AreaFormsManager extends VisualsManager<AreaForm, AreaFormData> implements IAreaFormsManager {

    constructor(
        itemsContainer: Container,
        overlay: Container
    ) {
        super(itemsContainer, overlay);        
        this.itemsContainer.visible = this.sensorsContainer.visible
            = this.handlesContainer.visible = AREAFORMS_ENABLED;
        this.setEditMode(false);
    }


    public requestAddAreaForm(rect: Polygon) {
        if (this.onRequestAddAreaForm) this.onRequestAddAreaForm(rect);
    }

    public onRequestAddAreaForm: (rect: Polygon) => void = undefined;


    protected visualItemFactory(
        dataItem: AreaFormData,
        index: number,
        itemsContainer: Container,
        sensorsContainer: Container,
        handlesContainer: Container,
        onSelectItem: (item: AreaForm) => void,
        onItemChanged: (itemData: AreaFormData) => void
    ): AreaForm {
        const newAreaFormDO = new AreaForm(
            dataItem,
            itemsContainer,
            sensorsContainer,
            handlesContainer,
            onSelectItem,
            onItemChanged
        );
        return newAreaFormDO;
    }
}
