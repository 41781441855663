import { createSelector } from "@reduxjs/toolkit";
import { InteractionMode } from "../../../seating/editor/display/interaction";
import { Seat, SeatRecord } from "../../../seating/types";
import { IPlaceCategory } from "../../../seating/types/PlaceCategory";
import { IPlacepool } from "../../../seating/types/Placepool";
import { ISeatingType } from "../../../seating/types/SeatingType";
import { IBlock } from "../../../seating/types/Block";
import { ImageData } from "../../../seating/editor/display/images/ImageData";
import { IAreaFormsSliceData, IVenuePlanSettingsSliceData, IVenuePlanSliceData, areaForms,
    isApiRequestPending,
    venuePlan, venuePlanSettings } from "./slice";


export interface IState {
    entities: IEntitiesRoot;
}

interface IEntitiesRoot {
    venueEditor: IVenueEditorRoot;
}

interface IVenueEditorRoot {
    venuePlan: IVenuePlanSliceData,
    venuePlanSettings: IVenuePlanSettingsSliceData,
    isApiRequestPending: boolean,
    allSeats: SeatRecord,
    newAddedSeats: Array<Seat>,
    isSeatsLoaded: boolean,
    placepoolDefinitions: Array<IPlacepool>,
    selectedSeatIds: Array<string>,
    interactionMode: InteractionMode,
    moveOnGrid: boolean,
    placeCategories: Array<IPlaceCategory>,
    seatingTypes: Array<ISeatingType>,
    blocks: Array<IBlock>,
    images: Array<ImageData>,
    areaForms: IAreaFormsSliceData,
    fatalAPIError: string
}



export const selectVenuePlan = (state: IState) => state.entities.venueEditor[venuePlan.name].venuePlan;
export const selectVenuePlanIsLoaded = (state: IState) => state.entities.venueEditor[venuePlan.name].isLoaded;

export const selectVenuePlanSettings = (state: IState) => state.entities.venueEditor[venuePlanSettings.name].venuePlanSettings;
export const selectVenuePlanSettingsIsLoaded = (state: IState) => state.entities.venueEditor[venuePlanSettings.name].isLoaded;

export const selectIsApiRequestPending = (state: IState) => state.entities.venueEditor[isApiRequestPending.name];

export const selectAllSeats = (state: IState) => state.entities.venueEditor.allSeats;
export const selectNewAddedSeats = (state: IState) => state.entities.venueEditor.newAddedSeats;
export const selectIsSeatsLoaded = (state: IState) => state.entities.venueEditor.isSeatsLoaded;
export const selectPlacepoolDefinitions = (state: IState) => state.entities.venueEditor.placepoolDefinitions;
export const selectPlaceCategories = (state: IState) => state.entities.venueEditor.placeCategories;
export const selectSeatingTypes = (state: IState) => state.entities.venueEditor.seatingTypes;
export const selectBlocks = (state: IState): IBlock[] => state.entities.venueEditor.blocks;
export const selectSelectedSeatIds = (state: IState) => state.entities.venueEditor.selectedSeatIds;
export const selectInteractionMode = (state: IState) => state.entities.venueEditor.interactionMode;
export const selectMoveOnGrid = (state: IState) => state.entities.venueEditor.moveOnGrid;
export const selectImages = (state: IState) => state.entities.venueEditor.images;

export const selectAreaForms = (state: IState) => state.entities.venueEditor[areaForms.name].areaForms;
export const selectNewAddedAreaForms = (state: IState) => state.entities.venueEditor[areaForms.name].newAddedAreaForms;
export const selectAreaFormsIsLoaded = (state: IState) => state.entities.venueEditor[areaForms.name].isLoaded;

export const selectFatalAPIError = (state: IState) => state.entities.venueEditor.fatalAPIError;


export const selectSelectedSeats = createSelector(  //memoized selector
    [selectAllSeats, selectSelectedSeatIds],
    (allSeats, selectedSeatIds) => {
        const selectedSeats = selectedSeatIds.map(seatId => allSeats[seatId]);
        return selectedSeats;
    }
);

export const selectSeatsWithEmptyLabelOrRow = createSelector(
    [selectAllSeats],
    seats => {
        return Object.values(seats).filter(seat => seat.row === '' || seat.label === '');
    }
);

export const selectSeatsByBlockAndRow = createSelector(
    [selectAllSeats],
    (seats: SeatRecord) => {
        const blocks = {};
        for (const seat of Object.values(seats)) {
            const blockId = seat.blockId;
            const row = seat.row;
            if (!blocks[blockId]) {  //block not existing yet
                const newBlock = {};
                newBlock[row] = [seat];
                blocks[blockId] = newBlock;
            } else if (!blocks[blockId][row]) { //row in block not existing yet
                blocks[blockId][row] = [seat];
            } else {
                blocks[blockId][row].push(seat);
            }
        }
        return blocks;
    }
)

export const selectSeatsCountWithoutPlacepools = createSelector(
    [selectAllSeats],
    (seats: SeatRecord) => {
        return Object.values(seats).reduce((count, seat) => count + (!seat.tags?.length ? 1 : 0), 0);
    }
)
