import {
    AbstractIdleState,
    AbstractPointerDownState,
    DisplayContext,
    DRAGGING_START_THRESHOLD,
    InteractionState,
    DraggingState
} from './common';
import {distance, Point} from '../../geometry';
import { FederatedEvent } from 'pixi.js';

export class AddSeatsIdleState extends AbstractIdleState {

    onPointerDown(context: DisplayContext, origin: Point, event: FederatedEvent): InteractionState {
        return new AddSeatsPointerDownState(origin);
    }

}

class AddSeatsPointerDownState extends AbstractPointerDownState {

    onPointerUp(context: DisplayContext): InteractionState {
        return new AddSeatsIdleState();
    }

    onPointerMove(context: DisplayContext, current: Point): InteractionState {
        // To avoid inadvertently moving a seat, the dragging interaction should only
        // start after the user moves the pointer a certain (minuscule) distance.
        if (distance(this.origin, current) > DRAGGING_START_THRESHOLD) {
            return new AddSeatsMarqueeState(this.origin);
        }

        return this;
    }
}

export class AddSeatsMarqueeState extends DraggingState {

    constructor(origin: Point) {
        super(origin);
    }

    protected update(context: DisplayContext): InteractionState {
        context.dataManager.addPreliminarySeats(this.origin, this.current);
        return this;
    }

    protected finish(context: DisplayContext): InteractionState {
        context.dataManager.requestAddSeats(this.origin, this.current);
        return new AddSeatsIdleState();
    }

    protected abort(context: DisplayContext): void {
        // TODO: Zustand wieder aufräumen.
    }
}
