import React from 'react';
import styles from "../layoutEditor.module.scss";
// import qr from './img/qr-lorem.svg'; // TODO:  correct import
import PropTypes from "prop-types";

const TicketQRCode = ({element, editModeEnabled}) => {
    return <div className={`${styles.LayoutElementInner} ${!editModeEnabled ? "interactive" : ""}`}>
        <img src={''} alt={"QR"} style={{
            maxWidth: element.width + "px",
            maxHeight: element.height + "px",
        }} />
    </div>;
};

TicketQRCode.propTypes = {
    element: PropTypes.object.isRequired,
    editModeEnabled: PropTypes.bool,
};

export default TicketQRCode;
