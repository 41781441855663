import Api from "../../../api";
import FormModal from "../modal/FormModal";
import FormikAsyncTypeaheadInput from "../formik/FormikAsyncTypeaheadInput";
import React, {useState} from "react";
import {Col, Row} from "react-bootstrap";
import {Select, MenuItem, FormControl} from '@mui/material';
import {Checkbox} from '@mui/material';
import {FormikTextInputGroup} from '../formik/FormikTextInputGroup';
import FormikCheckbox from '../../common/formik/FormikCheckbox';
import * as Yup from 'yup';

const api = new Api();

//types of batch action
export const BATCH_ACTION_MOVE_TO_ARCHIVE = "move_to_archive";
export const BATCH_ACTION_MOVE_TO_ACTIVE = "move_to_active";

export const BATCH_ACTION_CHANGE_PRICING_CATEGORY = "change_pricing_category";
export const BATCH_ACTION_ADD_PLACE_POOLS = "add_place_pools";
export const BATCH_ACTION_DELETE_PLACE_POOLS = "delete_place_pools";

export const BATCH_ACTION_CANCEL_PAYMENT = "cancel_payment";
export const BATCH_ACTION_RESOLVE_SERVICE_CASE_MANUAL = "resolve_service_case_manual";
export const BATCH_ACTION_RESOLVE_SERVICE_CASE_CANCELLATION_MANUAL = "resolve_service_case_cancellation_manual";

export const BATCH_ACTIONS_FOR_SEATS = [
    BATCH_ACTION_CHANGE_PRICING_CATEGORY,
    BATCH_ACTION_ADD_PLACE_POOLS,
    BATCH_ACTION_DELETE_PLACE_POOLS
]
const BATCH_ACTIONS_TRANSLATE = {
    [BATCH_ACTION_MOVE_TO_ARCHIVE]: 'Ins Archiv verschieben',
    [BATCH_ACTION_MOVE_TO_ACTIVE]: 'Reaktivieren',
    [BATCH_ACTION_CHANGE_PRICING_CATEGORY]: 'Kategorie wechseln',
    [BATCH_ACTION_ADD_PLACE_POOLS]: 'Platzpools hinzufügen',
    [BATCH_ACTION_DELETE_PLACE_POOLS]: 'Platzpools entfernen',
    [BATCH_ACTION_CANCEL_PAYMENT]: 'Stornieren',
    [BATCH_ACTION_RESOLVE_SERVICE_CASE_MANUAL]: 'Storno ohne Geldfluss',
    [BATCH_ACTION_RESOLVE_SERVICE_CASE_CANCELLATION_MANUAL]: 'Storno ohne Geldfluss',
}

const ListViewBatchActions = ({
    selectedElements,
    reloadView,
    resetSelectedElements,
    allowedActions,
    entity
}) => {
    const [isShowBatchModal, setIsShowBatchModal] = useState(false);
    const [isSubmitFormBusy, setIsSubmitFormBusy] = useState(false);
    const [modalErrorMessage, setModalErrorMessage] = useState('');

    const [selectedBatchAction, setSelectedBatchAction] = useState("");

    const [selectedElementsNames, setSelectedElementsNames] = useState("");

    // used for api query
    const [selectedElementsIds, setSelectedElementsIds] = useState([]);

    const toggleBatchHandler = (selectedAction) => {
        if (selectedAction === '' || selectedElements.length === 0) {
            setSelectedBatchAction("")
            return false;
        }

        let selectedElementsIds = [];
        let selectedElementsNames = [];

        selectedElements.map((el) => {
            selectedElementsIds.push(el.id);

            switch (selectedAction) {
                case BATCH_ACTION_MOVE_TO_ARCHIVE:
                case BATCH_ACTION_MOVE_TO_ACTIVE:
                    if (!selectedElementsNames.includes(el.name) && el.name !== "") {
                        selectedElementsNames.push(el.name);
                    }
                    break;
                case BATCH_ACTION_CHANGE_PRICING_CATEGORY:
                    if (!selectedElementsNames.includes(el.pricingCategory) && el.pricingCategory !== "") {
                        selectedElementsNames.push(el.pricingCategory);
                    }
                    break;
                case BATCH_ACTION_ADD_PLACE_POOLS:
                case BATCH_ACTION_DELETE_PLACE_POOLS:
                    if (!selectedElementsNames.includes(el.placePools) && el.placePools !== "") {
                        selectedElementsNames.push(el.placePools);
                    }
                break;
            }
        });

        if (selectedAction === BATCH_ACTION_ADD_PLACE_POOLS || selectedAction === BATCH_ACTION_DELETE_PLACE_POOLS) {
            //because usedPlacePoolsNames[0] is string like "place_pool_1,place_pool_2"
            selectedElementsNames = selectedElementsNames.join(',').split(',');

            const uniqueUsedPlacePools = [];
            selectedElementsNames.map(el => {
                if (uniqueUsedPlacePools.indexOf(el) === -1) {
                    uniqueUsedPlacePools.push(el)
                }
            });
            selectedElementsNames = uniqueUsedPlacePools;
        }

        setSelectedElementsIds(selectedElementsIds);
        setSelectedElementsNames(selectedElementsNames.join(', '));

        setSelectedBatchAction(selectedAction);
        setIsShowBatchModal(true);
    }

    const applyBatchAction = (association) => {
        setIsSubmitFormBusy(true);
        if (BATCH_ACTIONS_FOR_SEATS.includes(selectedBatchAction)) {
            let selectedPlacePoolsOrCategories = [];

            switch (selectedBatchAction) {
                case BATCH_ACTION_DELETE_PLACE_POOLS:
                    association.placePoolsForDelete.forEach((el) => {
                        selectedPlacePoolsOrCategories.push(el.id);
                    });
                    break;
                case BATCH_ACTION_ADD_PLACE_POOLS:
                    association.placePoolsForAdd.forEach((el) => {
                        selectedPlacePoolsOrCategories.push(el.id);
                    });
                    break;
                case BATCH_ACTION_CHANGE_PRICING_CATEGORY:
                    selectedPlacePoolsOrCategories.push(association.pricingCategory.id);
                    break;
                default:
                    setIsSubmitFormBusy(false);
                    return;
            }

            api.seatsBatchAction(selectedBatchAction, selectedElementsIds, selectedPlacePoolsOrCategories)
                .then(() => {
                    finishAction();
                });
        } else if ([BATCH_ACTION_MOVE_TO_ACTIVE, BATCH_ACTION_MOVE_TO_ARCHIVE].includes(selectedBatchAction)) {
            if (entity !== '') {
                api.batchActionChangeArchivityStatus(selectedBatchAction, selectedElementsIds, entity)
                    .then(() => {
                        finishAction();
                    });
            }
        } else if (selectedBatchAction === BATCH_ACTION_CANCEL_PAYMENT) {
            api.batchActionCancelPayment(selectedElements[0].orderId, {
                cartItemsIds: selectedElementsIds,
                cancellationReason: association.cancellationReason,
                freeUpPlaces: association.freeUpPlaces
            })
            .then(() => {
                finishAction();
                window.location.reload();
            })
            .catch((error) => {
                setModalErrorMessage('Die Stornierung der Zahlung ist fehlgeschlagen');
                setIsSubmitFormBusy(false);
            });
        } else if (selectedBatchAction === BATCH_ACTION_RESOLVE_SERVICE_CASE_MANUAL) {
            api.resolveServiceCase({
                serviceCaseIds: selectedElementsIds,
                resolveComment: association.resolveComment
            })
                .then(() => {
                    finishAction();
                })
                .catch((error) => {
                    setModalErrorMessage('Servicefälle konnten nicht gelöst werden');
                    setIsSubmitFormBusy(false);
                });
        } else if (selectedBatchAction === BATCH_ACTION_RESOLVE_SERVICE_CASE_CANCELLATION_MANUAL) {
            api.resolveServiceCase({
                orderCancellationCartItemIds: selectedElementsIds,
                resolveComment: association.resolveComment
            })
                .then(() => {
                    finishAction();
                })
                .catch((error) => {
                    setModalErrorMessage('Servicefälle konnten nicht gelöst werden');
                    setIsSubmitFormBusy(false);
                });
        }
    }

    const finishAction = () => {
        reloadView();

        setIsSubmitFormBusy(false);
        closeModal();
        resetSelectedElements();
    }
    const closeModal = () => {
        setIsShowBatchModal(false);
        setSelectedBatchAction('');
        setModalErrorMessage('');
    }

    const INITIAL_VALUES = {
        placePoolsForAdd: [],
        placePoolsForDelete: [],
        pricingCategory: '',
        cancellationReason: '',
        freeUpPlaces: true
    }

    const getConfirmLabel = () => {
        if (selectedBatchAction === BATCH_ACTION_RESOLVE_SERVICE_CASE_MANUAL
            || selectedBatchAction === BATCH_ACTION_RESOLVE_SERVICE_CASE_CANCELLATION_MANUAL) {
            return 'Bearbeitet';
        }
        return 'Speichern';
    }

    const getValidationSchema = () => {
        if (selectedBatchAction === BATCH_ACTION_CANCEL_PAYMENT) {
            return Yup.object().shape({
                cancellationReason: Yup.string().required(),
            });
        }
        return Yup.object().shape({});
    }

    return (
        <>
            <FormControl fullWidth sx={{
                maxWidth: 162
            }}>
                <Select
                    labelId="select-batch-actions-label"
                    id="select-batch-actions"
                    value={selectedBatchAction}
                    displayEmpty={true}
                    onChange={(e) => toggleBatchHandler(e.target.value)}
                >
                    <MenuItem value="" sx={{
                        paddingLeft: '16px',
                        height: '32px'
                    }}>
                        <em>Bitte wählen...</em>
                    </MenuItem>
                    {allowedActions.map((el, index) => (
                        <MenuItem key={index} value={el} sx={{
                            paddingLeft: '16px',
                            height: '32px'
                        }}>
                            {BATCH_ACTIONS_TRANSLATE[el]}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {/* modal form for batch action */}
            <FormModal show={isShowBatchModal}
                       title="Batch-Aktion"
                       initialValues={INITIAL_VALUES}
                       validationSchema={getValidationSchema()}
                       confirmLabel={getConfirmLabel()}
                       onConfirm={applyBatchAction}
                       onCancel={() => {
                           closeModal()
                       }}
                       submitTestId="submit_listing_view_batch_action"
                       submitBusy={isSubmitFormBusy}
            >
                {({associationFormik}) => {
                    return (
                        <>
                            {selectedBatchAction === BATCH_ACTION_CHANGE_PRICING_CATEGORY && (
                                <>
                                    <h2 className="font-weight-bold pb-3 mb-3">Kategorie wechseln</h2>
                                    <h3>Verwendete Kategorien:</h3>
                                    <p>
                                        {selectedElementsNames}
                                    </p>
                                    <Row className="mt-3">
                                        <Col className="col-md-12">
                                            <FormikAsyncTypeaheadInput
                                                id="pricingCategory"
                                                label="Platzkategorie"
                                                onSearch={query => api.getPricingCategoriesByQuery(query)}
                                                labelKey={o => o.name}
                                                minLength={0}
                                                testid="pricingCategory"
                                                formik={associationFormik}
                                            />
                                        </Col>
                                    </Row>
                                </>
                            )}

                            {selectedBatchAction === BATCH_ACTION_ADD_PLACE_POOLS && (
                                <>
                                    <h2 className="font-weight-bold pb-3 mb-3">Platzpools hinzufügen</h2>
                                    <h3>Gebrauchte Platzpools:</h3>
                                    <p>
                                        {selectedElementsNames}
                                    </p>
                                    <Row className="mt-3">
                                        <Col className="col-md-12">
                                            <FormikAsyncTypeaheadInput
                                                id="placePoolsForAdd"
                                                label="Platzpools:"
                                                onSearch={query => api.getPlacePoolsByQuery(query)}
                                                labelKey={o => o.name}
                                                minLength={0}
                                                multiple={true}
                                                formik={associationFormik}
                                            />
                                        </Col>
                                    </Row>
                                </>
                            )}

                            {selectedBatchAction === BATCH_ACTION_DELETE_PLACE_POOLS && (
                                <>
                                    <h2 className="font-weight-bold pb-3 mb-3">Platzpools entfernen</h2>
                                    <h3>Gebrauchte Platzpools:</h3>
                                    <p>
                                        {selectedElementsNames}
                                    </p>
                                    <Row className="mt-3">
                                        <Col className="col-md-12">
                                            <FormikAsyncTypeaheadInput
                                                id="placePoolsForDelete"
                                                label="Wählen Sie die zu entfernenden Platzpools aus"
                                                onSearch={query => api.getPlacePoolsBySeatsIds(selectedElementsIds, query)}
                                                labelKey={o => o.name}
                                                minLength={0}
                                                multiple={true}
                                                formik={associationFormik}
                                            />
                                        </Col>
                                    </Row>
                                </>
                            )}

                            {selectedBatchAction === BATCH_ACTION_MOVE_TO_ARCHIVE && (
                                <>
                                    <h2 className="font-weight-bold pb-3 mb-3">Ins Archiv verschieben</h2>
                                    <h3>Sind sie sich sicher, dass sie die Einträge ins Archiv verschieben möchten?</h3>
                                    <p>
                                        {selectedElementsNames}
                                    </p>
                                </>
                            )}

                            {selectedBatchAction === BATCH_ACTION_MOVE_TO_ACTIVE && (
                                <>
                                    <h2 className="font-weight-bold pb-3 mb-3">Reaktivieren</h2>
                                    <h3>Sind sie sich sicher, dass sie diese Einträge wieder reaktivieren möchten?</h3>
                                    <p>
                                        {selectedElementsNames}
                                    </p>
                                </>
                            )}
                            {selectedBatchAction === BATCH_ACTION_CANCEL_PAYMENT && (
                                <>
                                    <h2 className="font-weight-bold pb-3 mb-3">
                                        {selectedElementsIds.length > 1
                                            ? 'Ausgewählte ' + selectedElementsIds.length + ' Leistungen wirklich wirklich stornieren und rückerstatten?'
                                            : 'Ausgewählte Leistung wirklich stornieren & rückerstatten?'
                                        }
                                    </h2>
                                    <FormikTextInputGroup
                                        label="Grund / Kommentar für das Storno*"
                                        name="cancellationReason"
                                        type='textarea'
                                        minRows={3}
                                    />
                                    <FormikCheckbox
                                        label="Verkaufte Plätze freigeben?"
                                        name="freeUpPlaces"
                                    />
                                </>
                            )}
                            {(selectedBatchAction === BATCH_ACTION_RESOLVE_SERVICE_CASE_MANUAL
                                || selectedBatchAction === BATCH_ACTION_RESOLVE_SERVICE_CASE_CANCELLATION_MANUAL) && (
                                <>
                                    <h2 className="font-weight-bold pb-3 mb-3">Begründung</h2>
                                    <FormikTextInputGroup
                                        label="Bearbeitungskommentar für die Lösung des Klärfalls"
                                        name="resolveComment"
                                        type='textarea'
                                        minRows={3}
                                    />
                                </>
                            )}

                            {modalErrorMessage && <p className="error-text">{modalErrorMessage}</p>}
                        </>
                    )
                }}
            </FormModal>
        </>
    );
}

export default ListViewBatchActions;


