import {createSlice} from 'redux-starter-kit';

const currentTenant = createSlice({
    slice: 'currentTenant',
    initialState: '',
    reducers: {
        saveTenant: (state, data) => ({ ...data.payload })
    }
});

export const actions = {
    ...currentTenant.actions
};

export const reducer = currentTenant.reducer;