import React from 'react';
import FormPayonePaymentSettings from "./FormPayonePaymentSettings";
import { FormikProps } from 'formik';
import FormikRadio, { IFormValues,  ItemsProps } from '../common/formik/FormikRadio';
import { ITenant } from './TenantTypes';
import styles from "../form.module.scss";
import FormMolliePaymentSettings from "./FormMolliePaymentSettings";

const FormPaymentSettings = ({
    formik,
    tenant
} : {
    formik: FormikProps<IFormValues>,
    tenant: ITenant
}) => {
    const items: ItemsProps[] = [
        {
            value: 'mollie',
            name: 'Mollie',
            component: <FormMolliePaymentSettings formik={formik} tenant={tenant}/>
        },
        {
            value: 'payone',
            name: 'Payone',
            component: <FormPayonePaymentSettings formik={formik} tenant={tenant}/>
        },
        {
            value: 'fake',
            name: 'Fakepayment',
            component: ''
        },
        {
            value: '',
            name: 'Keine Bezahlung (kein Kaufabschluss möglich)',
            component: ''
        },
    ];

    return (
        <div className={styles.formBox}>
            <h2 className={styles.formTitle}>Payment Konfiguration</h2>
            <h3 className="font-weight-bold pb-3 mb-3">Wählen Sie einen Paymentserviceprovider aus und geben Sie
                Ihre Zugangsdaten ein:</h3>
            <FormikRadio
                name="paymentSettings.provider"
                items={items}
            />
        </div>
    )
};

export default FormPaymentSettings;
