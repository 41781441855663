import React, { ReactNode } from "react";

interface ITabPanelProps {
    children?: ReactNode;
    index: string;
    value: string;
    isHovered?: boolean;
    tabPanelRef?: React.Ref<HTMLDivElement>;
}

const TabsPanel: React.FC<ITabPanelProps> = ({ children, tabPanelRef, value, isHovered, index, ...other }) => {
    return (
        <div
            role="tabpanel"
            ref={tabPanelRef}
            hidden={value !== index && !isHovered}
            id={index}
            aria-labelledby={index}
            {...other}
        >
            {children}
      </div>
    );
};

export default TabsPanel;