import React, { useState } from 'react';
import { Field, getIn, FormikProps, FieldProps } from 'formik';
import { defaultTo } from 'lodash';
import InputTextField from '../InputTextField/InputTextField';

interface FormikTextInputGroupProps {
    name: string;
    label: string;
    testid?: string;
    className?: string;
    placeholder?: string;
    min?: number;
    type?: string;
    validate?: (value: string) => string | undefined;
    inputRef?: React.Ref<HTMLTextAreaElement | HTMLInputElement>;
    onKeyDown?: (event: React.KeyboardEvent) => void;
    helpText?: string;
    minRows?: number;
    disabled?: boolean;
    readOnly?: boolean;
}

interface FormValues {
    [field: string]: string;
}

export const FormikTextInputGroup: React.FC<FormikTextInputGroupProps> = ({
    name,
    label,
    testid,
    placeholder,
    type,
    validate,
    inputRef,
    onKeyDown,
    helpText,
    minRows,
    className,
    disabled,
    readOnly
}) => {
    return (
        <Field name={name} validate={validate}>
            {({ field, form }: FieldProps<string, FormikProps<FormValues>>) => {
                // if there is no value we have to assume an empty string as value,
                // otherwise React will assume the input to be uncontrolled
                const value = defaultTo(field.value, '');
                const error = getIn(form.errors, field.name);
                const touched = getIn(form.touched, field.name);
                const isInvalid = touched && !!error;
                return (
                    <InputTextField
                        className={className}
                        inputRef={inputRef}
                        label={label}
                        value={value}
                        helpText={helpText}
                        type={type}
                        minRows={minRows}
                        placeholder={placeholder}
                        testid={testid}
                        isInvalid={isInvalid}
                        disabled={disabled}
                        readOnly={readOnly}
                        error={error}
                        onKeyDown={onKeyDown}
                        onHandleIconClick={() => form.setFieldValue(name, '')}
                        onChange={(e) => form.setFieldValue(name, e.target.value)}
                        onBlur={() => form.setFieldTouched(name, true)}
                    />
                );
            }}
        </Field>
    )
};
