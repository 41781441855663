import React, {useState} from 'react';
import * as PropTypes from 'prop-types';
import {Formik} from "formik";
import * as yup from "yup";
import FormPlacePoolsAdd from "./FormPlacePoolsAdd";
import FeedbackButton from '../common/FeedbackButton';
import Modal from "react-bootstrap/Modal";
import {FormikTextInputGroup} from "../common/formik/FormikTextInputGroup";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import styles from '../common/listView/listView.module.scss';
import classnames from 'classnames';

const FormPlacePools = ({formik, blockIndex, standingBlocks}) => {
    const onSubmit = (values, {resetForm}) => {
        values['placePool' + blockIndex] && formik.setFieldValue('standingBlocks[' + blockIndex + '].placePools', [...formik.values.standingBlocks[blockIndex].placePools, {
            placePool: {
                id: values['placePool' + blockIndex].id,
                name: values['placePool' + blockIndex].name
            },
            capacity: 1,
            availableCapacity: null,
        }]);
        // Resetten der form, damit Werte auf Initial Values gesetzt werden
        resetForm();
    };

    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
    const [placePoolToDelete, setPlacePoolToDelete] = useState(undefined);

    const getSubformInitialValues = () => ({
        ['placePool' + blockIndex]: {id: "", name: ''}
    });

    const SUBFORM_SCHEMA = yup.object().shape({
        ['placePool' + blockIndex]: yup.object().required()
            .test('unique', 'Der Platzpool ist bereits zugeordnet', pool => !formik.values.standingBlocks[blockIndex].placePools.find(p => p.placePool.name === pool.name))
            .test('empty', 'Der Platzpool wurde nicht ausgewählt', pool => !!pool.name)
    });

    const canDeletePlacePool = (blockId, placePoolId) => {
        if(!standingBlocks || !Array.isArray(standingBlocks.venueEventStandingBlocks)) {
            return false;
        }

        const block = standingBlocks.venueEventStandingBlocks.find(item => {
            return item.id === blockId;
        });

        if (block && block.placePools) {
            const placePool = block.placePools.find(item => {
                return item.id === placePoolId;
            });

            return !placePool || placePool.bookedPlaces === 0;
        }

        return true;
    };

    const validatePoolCapacity = (formBlock, placePoolId, capacity) => {
        if(!standingBlocks || !Array.isArray(standingBlocks.venueEventStandingBlocks)) {
            return;
        }

        const block = standingBlocks.venueEventStandingBlocks.find(item => {
            return item.id === formBlock.id;
        });

        if (block && block.placePools) {
            const placePool = block.placePools.find(item => {
                return item.id === placePoolId;
            });

            let minCapacity = 0;

            if (placePool && placePool.bookedPlaces) {
                minCapacity = placePool.bookedPlaces
            }

            if (minCapacity > capacity) {
                return `Die Kapazität sollte größer oder gleich ${minCapacity} sein`
            }
        }
    };

    const deletePlacePool = (placePoolIndex) => {
        let pools = formik.values.standingBlocks[blockIndex].placePools.filter((placePool, index) => {
            return (index !== placePoolIndex)
        });
        formik.setFieldValue('standingBlocks[' + blockIndex + '].placePools', pools);
    };

    const showDeleteConfirmingDialog = (index) => {
        setShowDeleteConfirmDialog(true);
        setPlacePoolToDelete(index);
    };

    const handleCancelDelete = () => {
        setShowDeleteConfirmDialog(false);
    };

    const handleConfirmDelete = () => {
        setShowDeleteConfirmDialog(false);
        deletePlacePool(placePoolToDelete);
    };

    return (
        <>
            <table className={classnames('table table-striped', styles.TableWrap)}>
                <thead className={styles.TableHead}>
                <tr>
                    <th>Platzpool</th>
                    <th>Anzahl Plätze dieses Platzpools in diesem Block</th>
                    <th>Zuordnung entfernen</th>
                </tr>
                </thead>
                <tbody className={styles.TableBody}>
                {
                    formik.values.standingBlocks[blockIndex].placePools.map((pool, index) =>
                        <tr key={index}>
                            <td>{pool.placePool.name}</td>
                            <td>
                                <FormikTextInputGroup
                                    type="number"
                                    min="0"
                                    name={'standingBlocks[' + blockIndex + '].placePools[' + index + '].capacity'}
                                    validate={(capacity) => validatePoolCapacity(formik.values.standingBlocks[blockIndex], pool.placePool.id, capacity)}
                                />
                            </td>
                            <td>
                                {(canDeletePlacePool(formik.values.standingBlocks[blockIndex].id, pool.placePool.id)) && (
                                    <FeedbackButton
                                        title="Zuordnung entfernen"
                                        className="list-link link-btn"
                                        onClick={() => showDeleteConfirmingDialog(index)}
                                    >
                                        <DeleteOutlineOutlinedIcon className='table-icon' />
                                    </FeedbackButton>
                                )}
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </table>
            <Formik initialValues={{...getSubformInitialValues()}}
                    validationSchema={SUBFORM_SCHEMA}
                    onSubmit={onSubmit}
            >
                {(subformik) => {
                    return (
                        <FormPlacePoolsAdd
                            subformik={subformik}
                            blockIndex={blockIndex}
                        />
                    )
                }}
            </Formik>
            <Modal show={showDeleteConfirmDialog} onHide={handleCancelDelete}>
                <Modal.Header closeButton>
                    <Modal.Title>Platzpool löschen</Modal.Title>
                </Modal.Header>
                <Modal.Body>Möchten Sie die Zuordnung wirklich entfernen?</Modal.Body>
                <Modal.Footer>
                    <FeedbackButton variant="outlined" onClick={handleCancelDelete}>
                        Abbrechen
                    </FeedbackButton>
                    <FeedbackButton onClick={handleConfirmDelete}>
                        Entfernen
                    </FeedbackButton>
                </Modal.Footer>
            </Modal>
        </>
    )

}

FormPlacePools.propTypes = {
    formik: PropTypes.any,
    blockIndex: PropTypes.any,
    standingBlocks: PropTypes.object,
};

export default FormPlacePools;