import {Viewport} from 'pixi-viewport';
import {BitmapText, Container, Graphics, Point as PixiPoint, Rectangle, Sprite} from 'pixi.js';
import {Point, Vector} from '../geometry';

import {remove} from 'lodash';
import {Seat, SeatColor, SeatStyle} from '../../types';
import {getSeatTexture, sanitizeStringForLabelUse} from './resource';
import {calculateRowLablePositions, identifySeatRows, RowLabelPositions, RowLabelVisibilityMode} from './labels/rows';
import {LABEL_BITMAP_FONT} from './fonts';

export const SEAT_SIZE = 1;   //don't change except you know about consequences,
                                //already created plans will look wrong,
                                //changing this will affect customer frontend and CSV import,
                                //they must also  be adjusted
export const SEAT_RADIUS = SEAT_SIZE * 0.5;
export const SEAT_DEFAULT_COLOR = 0x000000;
export const LABEL_DEFAULT_COLOR = 0x4d4d4d;

/**
 * Der Standardwert für den Abstand der Reihenlabels zum Referenzplatz (erster/letzer Platz in der Reihe).
 */
export const ROW_LABEL_DEFAULT_DISTANCE = SEAT_SIZE * 1.15;

export interface BackdropConfig {
    scale: number;
    offset: Vector;
}

export interface Marquee {

    readonly id: string;

    //
    // color: number;
    //
    // visible: boolean;

    // remove(): void;
    box: Rectangle;

}

export interface SceneContainers {
    root: Container,
    overlay: {
        root: Container
    }
    viewport: {
        root: Viewport,
        images: Container,
        areaForms: Container,
        seats: Container,
        rowLabels: Container,
        seatLabels: Container,
        overlay: Container,
        selectionMarkers: Container,
    }
}

class ScreenSpaceMarquee implements Marquee {

    private readonly marquee = new Graphics();
    private readonly handles = new Graphics();

    constructor(
        public readonly id: string,
        private readonly scene: Scene,
        private rect: Rectangle,
        private color: number,
        private showHandles: boolean
    ) {
        this.handles.name = this.id;
        this.draw();
    }

    get box(): Rectangle {
        return this.rect.clone();
    }

    set box(box) {
        this.rect = box.clone();
        this.draw();
    }

    draw(): void {
        if (!this.marquee.parent) {
            this.scene.overlay.root.addChild(this.marquee);
            this.scene.overlay.root.addChild(this.handles);
        }

        const min = this.scene.convertFromViewportCoords({x: this.rect.left, y: this.rect.top});
        const max = this.scene.convertFromViewportCoords({x: this.rect.right, y: this.rect.bottom});

        this.marquee.clear();
        this.marquee.lineStyle(1, this.color, 1);
        this.marquee.beginFill(this.color, 1 / 25);
        this.marquee.drawRect(min.x, min.y, max.x - min.x, max.y - min.y);

        if (this.showHandles) {
            this.handles.clear()
            this.handles.cursor = 'pointer';
            this.handles.eventMode = 'static';
            this.handles.lineStyle(1, this.color, 1);
            this.handles.beginFill(this.color, 1);
            this.handles.drawCircle(min.x, min.y, 5);
            this.handles.drawCircle(min.x, max.y, 5);
            this.handles.drawCircle(max.x, min.y, 5);
            this.handles.drawCircle(max.x, max.y, 5);
        }
    }

    remove(): void {
        this.scene.overlay.root.removeChild(this.marquee);
        this.scene.overlay.root.removeChild(this.handles);
    }

}

export class Scene implements Readonly<SceneContainers> {

    readonly root: Container;
    readonly overlay: {
        readonly root: Container
    };
    readonly underlay: {
        readonly root: Container
    };
    readonly viewport: {
        readonly root: Viewport,
        readonly images: Container,
        readonly areaForms: Container,
        readonly seats: Container,
        readonly rowLabels: Container,
        readonly seatLabels: Container,
        readonly overlay: Container,
        readonly selectionMarkers: Container
    };

    private readonly marquees: ScreenSpaceMarquee[] = [];

    constructor(viewport: Viewport) {
        this.root = new Container();
        // FIXME: evtl. overlay anstatt root interactive machen
        this.overlay = {
            root: new Container()
        };
        this.underlay = {
            root: new Container()
        };
        this.viewport = {
            root: viewport,
            images: new Container(),
            areaForms: new Container(),
            seats: new Container(),
            rowLabels: new Container(),
            seatLabels: new Container(),
            overlay: new Container(),
            selectionMarkers: new Container()
        };

        // The drawing order of objects is determined by their order in the children array of a container;
        // thus the insertion order here is important as objects added later to a container are drawn
        // "on-top" of objects that were added earlier.

        this.root.addChild(this.underlay.root);
        this.root.addChild(this.viewport.root);
        this.root.addChild(this.overlay.root);

        this.viewport.root.addChild(this.viewport.images);
        this.viewport.root.addChild(this.viewport.areaForms);
        this.viewport.root.addChild(this.viewport.rowLabels);
        this.viewport.root.addChild(this.viewport.seats);
        this.viewport.root.addChild(this.viewport.seatLabels);

        this.viewport.root.addChild(this.viewport.overlay);
        this.viewport.root.addChild(this.viewport.selectionMarkers);

        // Wenn die Ansicht gezoomt order verschoben wird, müssen die Marquees neu gezeichnet werden,
        // da diese zwar im Koordinaten-System des Viewport definiert sind, aber im screen-space
        // gezeichnet werden.
        this.viewport.root.on('moved', () => {
            this.redrawMarquees();
        })
    }

    /**
     * Konvertiert globale Koordinaten zu Viewport lokalen Koordinaten.
     *
     * @param point Die zu konvertierende Koordinate.
     *
     * @return Die konvertierte Koordinate.
     */
    convertToViewportCoords(point: Point): Point {
        return this.viewport.root.toLocal(new PixiPoint(point.x, point.y));
    }

    /**
     * Konvertiert Viewport lokale Koordinaten zu globale Koordinaten.
     *
     * @param point Die zu konvertierende Koordinate.
     *
     * @return Die konvertierte Koordinate.
     */
    convertFromViewportCoords(point: Point): Point {
        return this.viewport.root.toGlobal(new PixiPoint(point.x, point.y));
    }

    addMarquee(id: string, rect: Rectangle, color: number, showHandles: boolean): Marquee {
        const marquee = new ScreenSpaceMarquee(id, this, rect, color, showHandles);

        this.marquees.push(marquee)

        return marquee;
    }

    
    removeMarquee(marquee: Marquee) {
        remove(this.marquees, marquee).forEach(m => m.remove());
    }


    private redrawMarquees(): void {
        this.marquees.forEach(m => m.draw());
    }


    /**
     * Die Reihen-Labels aktualisieren.
     */
    updateRowLabels(seats: Iterable<Seat>, mode: RowLabelVisibilityMode): void {
        // Die simpelste Methode um Veränderungen abzubilden ist alles wegschmeißen und neu erzeugen.
        // Wenn die bisherigen Labels nicht entfernt würden, dann würden die alten und neuen Labels
        // gleichzeitig angezeigt. Bei einer Verschiebung bspw. am alten und am neuen Ort.
        // Eine effizientere aber auch komplexere Lösung wäre bspw. etwas wie object-pooling zu
        // betreiben und bestehende labels wiederzuverwenden.
        this.viewport.rowLabels.removeChildren();

        if (mode === 'NONE') {
            return;
        }

        const labelPositions = identifySeatRows(seats).mapValues((seats) => {
            return calculateRowLablePositions(seats, ROW_LABEL_DEFAULT_DISTANCE);
        })

        for (const [[, row], positions] of labelPositions) {
            // Für Reihen ohne Bezeichnung brauchen wir keine Labels zu erzeugen
            if (row) {
                this.viewport.rowLabels.addChild(...createRowLabels(row, positions, mode));
            }
        }
    }

    /**
     * Die Seat-Labels aktualisieren.
     */
    updateSeatLabels(seats: Iterable<Seat>, showSeatLabels: boolean): void {
        // Die simpelste Methode um Veränderungen abzubilden ist alles wegschmeißen und neu erzeugen.
        // Wenn die bisherigen Labels nicht entfernt würden, dann würden die alten und neuen Labels
        // gleichzeitig angezeigt. Bei einer Verschiebung bspw. am alten und am neuen Ort.
        // Eine effizientere aber auch komplexere Lösung wäre bspw. etwas wie object-pooling zu
        // betreiben und bestehende labels wiederzuverwenden.
        this.viewport.seatLabels.removeChildren();

        if (showSeatLabels) {
            for (const seat of seats) {
                if (seat.label) {
                    const label = createSeatLabel(seat.label, seat);
                    this.viewport.seatLabels.addChild(label);
                }
            }
        }
    }

    /**
     * Die Anzeige der Reihen- und Seat-Labels (de-)aktivieren.
     */
    toggleSeatAndRowLabelDisplay(visible: boolean) {
        this.viewport.seatLabels.visible = visible;
        this.viewport.rowLabels.visible = visible;
    }
}

function createRowLabels(row: string, positions: RowLabelPositions, mode: RowLabelVisibilityMode): BitmapText[] {
    const result = [];

    if (mode === 'ALL' || mode === 'START_ONLY') {
        const startLabel = createRowLabel(row, positions.start);
        // Start-Label "rechtsbündig" ausrichten
        startLabel.anchor.set(0.5, 0.5);
        result.push(startLabel);
    }

    if (mode === 'ALL' || mode === 'END_ONLY') {
        const endLabel = createRowLabel(row, positions.end);
        // End-Label "linksbündig" ausrichten
        endLabel.anchor.set(0.5, 0.5);
        result.push(endLabel);
    }

    return result;
}

function createRowLabel(text: string, position: Point): BitmapText {
    const label = new BitmapText(sanitizeStringForLabelUse(text), {
        fontSize: SEAT_SIZE,
        fontName: LABEL_BITMAP_FONT,
        tint: LABEL_DEFAULT_COLOR,
        align: 'center'
    });

    label.position.copyFrom(position);

    return label;
}

function createSeatLabel(text: string, position: Point): BitmapText {
    const label = new BitmapText(sanitizeStringForLabelUse(text), {
        fontSize: SEAT_SIZE * 0.5,
        fontName: LABEL_BITMAP_FONT,
        tint: LABEL_DEFAULT_COLOR
    })

    label.anchor.set(0.5, 0.50);
    label.position.copyFrom(position);

    return label;
}

export function createSeatSprite(style: SeatStyle, color: SeatColor): Sprite {
    const sprite = new Sprite(getSeatTexture(style));

    sprite.anchor.set(0.5);
    sprite.width = SEAT_SIZE;
    sprite.height = SEAT_SIZE;

    applySeatStyle(sprite, style, color);

    return sprite;
}

export function applySeatStyle(sprite: Sprite, style: SeatStyle, color: SeatColor) {
    sprite.texture = getSeatTexture(style);
    sprite.tint = color;
}
