import { createSlice } from "redux-starter-kit";

const entityName = 'venuePlan';

export const byVenue = createSlice({
    slice: entityName,
    initialState: {},
    reducers: {
        byVenue: (state, { payload }) => {
            state[payload.id] = true;
        },
        byVenueError: (state, { payload }) => {
            state[payload.id] = false;
        },
        byVenueSuccess: (state, { payload }) => {
            state[payload.response.result] = false;
        }
    }
});