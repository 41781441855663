import React, {Component} from 'react';
import {Container, Row} from 'react-bootstrap';
import ConnectedListView from '../common/listView/ConnectedListView';
import {connect} from 'react-redux';
import * as order from "../../state/entities/order";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";
import FeedbackButton from "../common/FeedbackButton";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";

class List extends Component {
    render() {
        return (
            <Container>
                <Row className="my-4">
                    <Breadcrumbs entity={["Order"]} endpoints={['order/breadcrumb']} title="Orders"
                                 links={['/sales/order/']} active={[0]}/>
                </Row>
                <ConnectedListView
                    heading="Orders"
                    endpoint="listingView/order"
                >
                    {({fields, item}) => (
                        <>
                            <FeedbackButton
                                className="list-link"
                                title="Order ansehen"
                                to={`/sales/order/${item.id}`}
                                icon={<EditNoteOutlinedIcon className='table-icon'/>}
                            />
                        </>
                    )}
                </ConnectedListView>
            </Container>
        );
    }
}

export default connect(null, {
    deleteOrder: entity => order.actions.delete({entity})
})(List);
