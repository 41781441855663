import React from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import * as PropTypes from 'prop-types';
import Table from "react-bootstrap/Table";
import {FormattedDate} from "react-intl";
import {Link} from "react-router-dom";
import styles from "../../form.module.scss";
import stylesTable from "../../common/listView/listView.module.scss";

function VenueEvents({venuePlan}) {
    return (
        <div className='mt-4'>
            <h2 className={styles.formTitle}>Events</h2>
            <Row className="mt-4">
                <Col className="col-md-12">
                    <Table striped bordered hover className={stylesTable.TableWrap}>
                        <thead className={stylesTable.TableHead}>
                        <tr>
                            <th width="65%">Event Titel</th>
                            <th width="15%">Startzeitpunkt</th>
                            <th width="15%">&nbsp;</th>
                        </tr>
                        </thead>
                        <tbody className={stylesTable.TableBody}>
                        {venuePlan.events && venuePlan.events.map((element, eventIndex) => (
                            <tr>
                                <td>{element.title.de}</td>
                                <td><FormattedDate value={element.startDate} day="2-digit" month="2-digit"
                                                    year="numeric"/></td>
                                <td>
                                    <Link target="_blank" to={`/event-management/event/${element.id}`}>
                                        zu diesem Event springen
                                    </Link>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </div>
    )
}

VenueEvents.propTypes = {
    venuePlan: PropTypes.object,
};

export default VenueEvents;
