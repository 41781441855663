import React from 'react';
import PropTypes from 'prop-types';
import {FormattedDate, FormattedNumber, FormattedTime} from 'react-intl';
import {isEmpty} from 'lodash';
import Euro from "../numberFormat/Euro";
import ImagePreview from '../../common/ImagePreview/ImagePreview';
import Zoom from 'react-medium-image-zoom'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import * as MUIcon from '@mui/icons-material';
import Tooltip from "@mui/material/Tooltip";

function IconComponent({iconName, tooltipText}) {
    const Icon = MUIcon[iconName];

    if (!Icon) {
        return <p>Icon {iconName} not found</p>;
    }

    return <Tooltip title={tooltipText} placement="top">
        <Icon/>
    </Tooltip>;
}

function ListViewItemCell({field, value, className}) {
    function RenderValue({value}) {
        switch (field.type) {
            case 'currency':
                return <Euro centAmount={value}/>;
            case 'datetime':
                return value && (
                    <>
                        <FormattedDate value={value} day="2-digit" month="2-digit" year="numeric"/>
                        <br/>
                        <FormattedTime value={value} hour="2-digit" minute="2-digit"/>
                    </>
                );
            case 'number':
                return <FormattedNumber value={value}/>;
            case 'boolean':
                return value ? 'ja' : "nein";
            case 'salesChannelIcons':
                return value.map(v => {
                    return (
                        <span key={v.id} title={v.name}>{v.icon}</span>
                    )
                })
            case 'svgIcons':
                return value.map((v, index) => {
                    return (
                        <IconComponent key={index} iconName={v.icon} tooltipText={v.tooltip}/>
                    )
                })
            case 'ticketPdf':
                return <a target={'_blank'} href={value} title={value}><PictureAsPdfIcon/></a>
            case 'image':
                return (
                    <Zoom>
                        <ImagePreview src={value} width='40px' height='40px' fullSize/>
                    </Zoom>
                )
            default:
                return isEmpty(value) ? <></> : <>{value}</>;
        }
    }

    return (
        <td className={className}><RenderValue value={value}/></td>
    );
}

ListViewItemCell.propTypes = {
    type: PropTypes.string,
    className: PropTypes.string
};

export default ListViewItemCell;
