import React, {Component} from 'react';
import Form from "./FormEdit";
import {connect} from "react-redux";
import {LoadingIndicator} from "../../../../common/LoadingIndicator";
import {Col, Row} from "react-bootstrap";
import * as blockState from "../../../../../state/entities/block";
import * as seatState from "../../../../../state/entities/seat";
import * as placePoolState from "../../../../../state/entities/placePool";
import Breadcrumbs from "../../../../common/breadcrumbs/Breadcrumbs";

class Edit extends Component {

    componentDidMount() {
        this.props.loadBlock(this.props.blockId);
        this.props.loadSeat(this.props.seatId);
        this.props.loadPlacePools();

    }

    onSubmit = (seat, {setErrors}) => {
        this.props.saveSeat(seat, setErrors);
    };

    render() {
        const {seat, loading, saving, blockLoading, blockId, placePools, block} = this.props;
        if (!seat || loading || blockLoading || !placePools) {
            return <LoadingIndicator/>;
        }
        return <>
            <Row className="my-4">
                <Breadcrumbs entity={["Spielstätte", "Saalplan", "Blockgruppe", "Block", "Sitzplätze"]} endpoints = {['venue/breadcrumb', `venue/${block.venuePlan.venue.id}/venue_plan/breadcrumb`, `venue_plan/${block.venuePlan.id}/block_group/breadcrumb`, `venue_plan/${block.venuePlan.id}/block/breadcrumb`, `block/${block.id}/seat/breadcrumb`]} title = {["Blockgruppen", "Blöcke", "Sitzplätze"]} links={['/base/venue/', `/base/venue/${block.venuePlan.venue.id}/venue-plan/`, `/base/venue-plan/${block.venuePlan.id}/block-group/`, `/base/venue-plan/${block.venuePlan.id}/block/`, `/base/block/${block.id}/seat/`]} active={[block.venuePlan.venue.id, block.venuePlan.id, 0, block.id, seat.id]} />
            </Row>
            <Row className="mb-4 mt-4">
                <Col className="pt-3 col-sm-8">
                    <h1>Bearbeiten des Sitzplatzes <em>Reihe {seat.rowLabel}, Sitzplatz {seat.seatLabel}</em></h1>
                </Col>
            </Row>
            <Form seat={seat} onSubmit={this.onSubmit} submitPending={saving} blockId={blockId} placePools={placePools} />
        </>;
    }
}

const mapStateToProps = (state, props) => {
    const blockId = props.match.params.bid;
    const seatId = props.match.params.sid;
    const placePools =Object.values(state.entities.placePool.byId);
    return {
        seatId,
        blockId,
        placePools,
        loading: state.entities.seat.loading[seatId],
        saving: state.entities.seat.saving[seatId],
        seat: state.entities.seat.byId[seatId],
        block: state.entities.block.byId[blockId],
        blockLoading: state.entities.block.loading[blockId],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadSeat: id => dispatch(seatState.actions.load({id})),
        saveSeat: entity => dispatch(seatState.actions.save({entity})),
        loadBlock: id => dispatch(blockState.actions.load({id})),
        loadPlacePools: () => dispatch(placePoolState.actions.loadAll()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
