import {FederatedPointerEvent, Graphics} from 'pixi.js';
import {Point, calculateBoundingBox} from '../../geometry';
import {Scene} from '../scene';
import {DataManager} from '../data';
import {VenuePlanInteractionEvent} from './event';
import {debounce} from 'lodash';
import { ImagesManager } from '../images/imagesManager';
import { AreaFormsManager } from '../areaForms/areaFormsManager';
import Flatten from '@flatten-js/core';

export const DRAGGING_START_THRESHOLD = 2;
const SEAT_MOUSE_OVER_DEBOUNCE_DELAY = 200;


export enum InteractionMode {
    ADD = 'ADD',
    SELECT = 'SELECT',
    IMAGES = 'IMAGES',
    AREAFORMS = 'AREAFORMS',
    ADD_AREAFORM = 'ADD_AREAFORM'
}



export interface MouseWheelZoomEvent {
    direction: 'IN' | 'OUT'
}

export enum EventType {
    MOUSE_WHEEL_ZOOM = 'MOUSE_WHEEL_ZOOM',
    PIXI_INTERACTION = 'PIXI_INTERACTION',
    KEYBOARD = 'KEYBOARD'
}

export interface ZoomEvent {
    type: EventType.MOUSE_WHEEL_ZOOM
    event: MouseWheelZoomEvent
}

export interface PixiInteractionEvent {
    type: EventType.PIXI_INTERACTION
    event: FederatedPointerEvent
}

export interface KeyEvent {
    type: EventType.KEYBOARD
    event: KeyboardEvent
}

export type DisplayEvent =
    | ZoomEvent
    | PixiInteractionEvent
    | KeyEvent


export interface DisplayContext {

    readonly interactionMode: InteractionMode;

    readonly scene: Scene;

    readonly dataManager: DataManager;

    readonly imagesManager: ImagesManager;

    readonly areaFormsManager: AreaFormsManager;

    dispatch(event: VenuePlanInteractionEvent): void;

    /**
     * Die übergebene Position auf einen valide Bereiche einschränken.
     *
     * @return Die eingeschränkte Position.
     */
    constrainPosition(position: Point): Point;
}

export interface InteractionState {

    /**
     * Callback der aufgerufen wird, wenn ein Ereignis in der Anzeige stattgefunden hat.
     */
    onEvent(context: DisplayContext, event: DisplayEvent): InteractionState;

    /**
     * Callback der aufgerufen wird, wenn der state installiert wurde.
     *
     * Konkrete Implementierungen sollte hier evtl. nötige Setup-Arbeiten durchführen.
     */
    onInstallation(context: DisplayContext): void;

    /**
     * Callback der aufgerufen wird, wenn der state ausgetauscht werden soll.
     *
     * Konkrete Implementierungen sollte hier evtl. nötige Aufräumarbeiten durchführen,
     * bspw. entfernen von Elementen aus der Anzeigen.
     */
    onEviction(context: DisplayContext): void;

}

abstract class AbstractInteractionState implements InteractionState {

    onEvent(context: DisplayContext, event: DisplayEvent): InteractionState {
        switch (event.type) {
            case EventType.PIXI_INTERACTION:
                return this.onInteraction(context, event.event);
            case EventType.MOUSE_WHEEL_ZOOM:
                return this.onZoom(context, event.event);
            case EventType.KEYBOARD:
                return this.onKeyboard(context, event.event);
            default:
                return this;
        }
    }

    onInstallation(context: DisplayContext) {
        // leer
    }

    onEviction(context: DisplayContext): void {
        // leer
    };

    protected onInteraction(context: DisplayContext, event: FederatedPointerEvent): InteractionState {
        return this;
    };

    protected onZoom(context: DisplayContext, event: MouseWheelZoomEvent): InteractionState {
        return this;
    }

    protected onKeyboard(context: DisplayContext, event: KeyboardEvent): InteractionState {
        return this;
    }
}

export abstract class DraggingState extends AbstractInteractionState {

    protected current: Point;

    protected constructor(protected readonly origin: Point) {
        super();
        this.current = origin;
    }

    onInteraction(context: DisplayContext, event: FederatedPointerEvent): InteractionState {
        switch (event.type) {
            case 'pointerup':
                return this.finish(context);
            case 'pointermove':
                this.current = event.data.global.clone();
                return this.update(context);
        }

        return this;
    }

    onEviction(context: DisplayContext): void {
        this.abort(context);
    }

    protected abstract update(context: DisplayContext): InteractionState;

    protected abstract finish(context: DisplayContext): InteractionState;

    protected abstract abort(context: DisplayContext): void;

}


export abstract class DrawRectState extends DraggingState {

    private readonly marquee = new Graphics();

    constructor(
        origin: Point,
        protected readonly rectColor: number)
    {
        super(origin);
    }

    protected update(context: DisplayContext): InteractionState {
        if (!this.marquee.parent) {
            context.scene.overlay.root.addChild(this.marquee);
        }

        const bb = calculateBoundingBox([this.origin, this.current]);

        this.marquee.clear();
        this.marquee.lineStyle(1, this.rectColor, 1);
        this.marquee.beginFill(this.rectColor, 1 / 25);
        this.marquee.drawRect(bb.xmin, bb.ymin, bb.xmax - bb.xmin, bb.ymax - bb.ymin);

        return this;
    }

    protected finish(context: DisplayContext): InteractionState {
        context.scene.overlay.root.removeChild(this.marquee);

        const localOrigin = context.scene.convertToViewportCoords(this.origin);
        const localControl = context.scene.convertToViewportCoords(this.current);
        const bb = calculateBoundingBox([localOrigin, localControl]);

        return this.processRect(bb, context);
    }

    protected abstract processRect(rect: Flatten.Box, context: DisplayContext): InteractionState;

    protected abort(context: DisplayContext): void {
        context.scene.overlay.root.removeChild(this.marquee);
    }

}


export enum MouseButtonIndex {
    LEFT,
    MIDDLE,
    RIGHT
}

export enum PointerType {
    MOUSE = 'mouse',
    PEN = 'pen',
    TOUCH = 'touch'
}

/**
 * debounced das Move-Over-Event für Seat, als workaround für Performanz-Probleme,
 * da sonst bei jeder Mouse-Bewegung zig solcher Events gefeuert werden
 */
const debouncedSeatMouseOverDispatch = debounce((context: DisplayContext, point: Point) => {
    context.dispatch({
        type: 'SEAT_MOUSEOVER',
        seat: context.dataManager.pickSeat(context.scene.convertToViewportCoords(point))
    });
}, SEAT_MOUSE_OVER_DEBOUNCE_DELAY);

export abstract class AbstractIdleState extends AbstractInteractionState {

    onInteraction(context: DisplayContext, event: FederatedPointerEvent): InteractionState {
        const isMouse = event.pointerType === PointerType.MOUSE;
        const isLeftButton = event.data.button === MouseButtonIndex.LEFT;

        if (isMouse && isLeftButton) {
            switch (event.type) {
                case 'pointerdown':
                    return this.onPointerDown(context, event.global.clone(), event);
                case 'pointerup':
                    return this;
            }
        }

        // FIXME:
        //  Hier wird nach dem Seat unter dem pointer gepickt, vornehmlich, um dessen Informationen
        //  on-hover darzustellen. Aktuell funktioniert das hier, weil alle relevanten InteractionStates
        //  von dieser Klasse erben, es gibt aber keine Garantie dafür, dass das in Zukunft auch so bleibt.
        //  Idealerweise würde man diese Logik an eine zentrale(re) Stelle verschieben bspw. über einen
        //  InteractionStateDecorator, der immer vor den aktuellen state gehängt wird.
        if (event.type === 'pointermove') {
            debouncedSeatMouseOverDispatch(context, event.global.clone());
        }
        return this;
    }

    onEviction(context: DisplayContext): void {
        // noop
    }

    abstract onPointerDown(context: DisplayContext, origin: Point, event: FederatedPointerEvent): InteractionState;

}

export abstract class AbstractPointerDownState extends AbstractInteractionState {

    constructor(protected origin: Point) {
        super();
    }

    onInteraction(context: DisplayContext, event: FederatedPointerEvent): InteractionState {
        switch (event.type) {
            case 'pointerup':
                return this.onPointerUp(context);
            case 'pointermove':
                return this.onPointerMove(context, event.global.clone());
        }

        return this;
    }

    onEviction(context: DisplayContext): void {
        // noop
    }

    abstract onPointerUp(context: DisplayContext): InteractionState;

    abstract onPointerMove(context: DisplayContext, current: Point): InteractionState;

}


export interface InteractionStateDecoratorOptions {
    onEvent?(context: DisplayContext, event: DisplayEvent): void

    onInstallation?(context: DisplayContext): void

    onEviction?(context: DisplayContext): void
}

/**
 * Decorator der genutzt werden kann, um zusätzliche Logik um die Callbacks eines InteractionState herum auszuführen.
 */
export class InteractionStateDecorator implements InteractionState {

    constructor(private decoratedState: InteractionState, private options: InteractionStateDecoratorOptions = {}) {
        // parameter properties only
    }

    onEvent(context: DisplayContext, event: DisplayEvent): InteractionState {
        this.options.onEvent?.(context, event);
        // Der decorator tritt anstelle des dekorierten state auf, daher evtl. Wechsel des state intern abbilden.
        this.decoratedState = this.decoratedState.onEvent(context, event);
        return this;
    }

    onInstallation(context: DisplayContext) {
        this.options.onInstallation?.(context);
        this.decoratedState.onInstallation(context);
    }

    onEviction(context: DisplayContext): void {
        this.decoratedState.onEviction(context);
        this.options.onEviction?.(context);
    }

}
