import React, {useEffect} from 'react';
import {Col, Row} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {FormikTextInputGroup} from '../common/formik/FormikTextInputGroup';
import FormMolliePaymentMethods from "./FormMolliePaymentMethods";

function FormMolliePaymentSettings({formik, tenant}) {
    const visible = formik.values.paymentSettings.provider === 'mollie';

    useEffect(() => {
        if (!visible) {
            return;
        }

        if (formik.values.paymentSettings?.mollie === null) {
            formik.setFieldValue('paymentSettings.mollie', {
                    partnerId: '',
                    apiKey: '',
                    profileId: '',
                    paymentMethods: [],
                }
            );
        }
    }, [formik, visible]);

    return (
        <>
            <Row className={`${!visible ? 'hidden' : ''}`}>
                <Col className="col-md-4">
                    <Row className="mt-3">
                        <Col className="col-md-12">
                            <FormikTextInputGroup
                                label={`API key (https://www.mollie.com/dashboard/developers/api-keys)`}
                                name="paymentSettings.mollie.apiKey"
                                type='password'
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="col-md-12">
                            <FormikTextInputGroup label="Partner ID (https://my.mollie.com/dashboard/developers/api-keys)"
                                                  name="paymentSettings.mollie.partnerId"
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="col-md-12">
                            <FormikTextInputGroup label="Profile ID  (https://my.mollie.com/dashboard/developers/api-keys)"
                                                  name="paymentSettings.mollie.profileId"
                            />
                        </Col>
                    </Row>
                </Col>
                <Col className="col-md-6">
                    <Row className="mt-3">
                        <Col className="col-md-12">
                            <h3>Wählen Sie hier ihre grundsätzlich möglichen Zahlungsarten aus:</h3>
                            <FormMolliePaymentMethods formik={formik}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

FormMolliePaymentSettings.propTypes = {
    formik: PropTypes.object,
    tenant: PropTypes.object,
};

export default FormMolliePaymentSettings;
