import React, {Component} from 'react';
import {Container, Row} from 'react-bootstrap';
import ConnectedListView from '../common/listView/ConnectedListView';
import {connect} from 'react-redux';
import * as order from "../../state/entities/order";
import FeedbackButton from "../common/FeedbackButton";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
 import {BATCH_ACTION_RESOLVE_SERVICE_CASE_MANUAL} from "../common/listView/ListViewBatchActions";

class List extends Component {
    render() {
        return (
            <Container>
                <Row className="my-4"></Row>
                <ConnectedListView
                    heading="Servicefälle"
                    endpoint="listingView/serviceCase?status=open"
                    isShowSelectionWithBatchActions={true}
                    allowedActions={[BATCH_ACTION_RESOLVE_SERVICE_CASE_MANUAL]}
                >
                    {({fields, item}) => (
                        <>
                            <FeedbackButton
                                className="list-link"
                                title="Order ansehen"
                                to={`/sales/order/${item.orderId}`}
                                icon={<EditNoteOutlinedIcon className='table-icon'/>}
                            />
                        </>
                    )}
                </ConnectedListView>

            </Container>
        );
    }
}

export default connect(null, {
    deleteOrder: entity => order.actions.delete({entity})
})(List);
