import React from 'react';
import Avatar from './Avatar';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import styles from './navbar.module.scss';

import {AuthConsumer} from "../auth/AuthProvider";
import ToggleHelptexts from "../common/ToggleHelptexts";

/**
 * Create a new navbar instance.
 *
 * @param     {Object}      props
 * @property  {String|null} className CSS Selector classes.
 * @property  {String}      home      The home route.
 * @property  {String}      profile   The profile route.
 * @property  {Object}      user      The authenticated user.
 * @return    {JSX}
 */
export default ({
  className
}) => <Navbar className={[styles.Navbar, className].join(' ')} fixed="top">
  <Nav className="ml-auto align-items-center">
      <ToggleHelptexts/>
    {/* <Nav.Link href="#">
      <i className="border-left pl-4 pr-2 icon-medium fa fa-bell-o"></i>
    </Nav.Link> */}
    <AuthConsumer>
      {({ logout }) => {
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
          return <a href="#" onClick={logout}>
            <i className="border-left pl-4 pr-2 icon-medium fa fa-sign-out" />
          </a>
      }}
    </AuthConsumer>
  </Nav>
</Navbar>;
