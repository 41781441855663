import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';

import Api from '../../api';
import FeedbackButton from '../common/FeedbackButton';
import FormikAsyncTypeaheadInput from '../common/formik/FormikAsyncTypeaheadInput';
import FilteringMultiSelectList from '../common/form/FilteringMultiSelectList/FilteringMultiSelectList';
import {ErrorMessage, Field, Form, Formik, getIn} from 'formik';
import * as yup from 'yup';
import ObjectId from 'bson-objectid';
import ConfirmModal from '../common/modal/ConfirmModal';
import FormModal from '../common/modal/FormModal';
import { FormikTextInputGroup } from '../common/formik/FormikTextInputGroup';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import {getSalesChannelsWithIcon} from '../salesRule/Form';
import {findIndex, get} from 'lodash';
import {SalesRuleAssociation} from './schema';
import HelpContent from '../common/help/HelpContent';
import SalesRuleAssociationForm from "./SalesRuleAssociationForm";
import {MetaEvent} from "../metaEvent/schema";
import {Link} from "react-router-dom";
import SalesRuleBatchEdit from "./SalesRuleBatchEdit";
import Modal from "react-bootstrap/Modal";
import FormMaxTicketsSalesRulesGroups from "./FormMaxTicketsSalesRulesGroups";
import classNames from "classnames";
import AddIcon from '@mui/icons-material/Add';
import styles from "../form.module.scss";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';

const api = new Api();

/**
 * Determines if a sales rule is active, depending on the current date and state.
 *
 * @param salesRule Sales rule to check
 * @param currentTimestampInSeconds Current date a timestamp in seconds.
 *
 * @returns {boolean} true if sales rule is active.
 */
export function determineIfSalesRuleActive(salesRule, currentTimestampInSeconds) {
    if (!salesRule.enabled) {
        return false;
    }

    const now = moment(currentTimestampInSeconds * 1000);

    if (salesRule.validFrom) {
        if (salesRule.validTo) {
            return now.isBetween(salesRule.validFrom, salesRule.validTo, null, '[]');
        } else {
            return now.isSameOrAfter(salesRule.validFrom);
        }
    } else {
        if (salesRule.validTo) {
            return now.isSameOrBefore(salesRule.validTo);
        } else {
            // rule is always active if there are no limits
            return true;
        }
    }
}

export const SHIPPING_CODES = [
    {id: 'DPD', name: 'Versand mit DPD'},
    {id: 'DHL', name: 'Versand Auswärtstickets mit DHL'},
    {id: 'CLT', name: 'Abholung / Hinterlegung'},
    {id: 'DGT', name: 'Digital'},
    {id: 'VIP', name: 'VIP-Tickets'},
    {id: 'POST', name: 'Post'},
    {id: 'FREE', name: 'Versandkostenfrei'},
    {id: 'PAH', name: 'Print at Home Ticket'},
    {id: 'PAHWOI', name: 'Print at Home ohne Mailrechung'},
    {id: 'PLASTIK', name: 'Plastikkarte'},
    {id: 'PLASTIKNO', name: 'Plastikkarte kostenfrei'},
    {id: 'BLOCKBOCA', name: 'Blockdauerkarte Thermo'},
    {id: 'BLOCKFREE', name: 'Blockdauerkarte kostenfrei'},
    {id: 'BLOCKPAH', name: 'Blockdauerkarte Print@Home'},
];

export const getNameByShippingCode = (deliveryGroups) => {
    for (const code of SHIPPING_CODES) {
        if (deliveryGroups === code.id) {
            return code.name;
        }
    }

    return '';
}

export const getDeliveryGroupsWithDescriptions = (deliveryGroups) => {
        if (!deliveryGroups) {
            return [];
        }

        let position = 0;
        return deliveryGroups.map(g => {
            let description = "";

            for (const code of SHIPPING_CODES) {
                if (g.shippingCode === code.id) {
                    description = code.name;
                }
            }

            return {
                ...g,
                description: description,
                position: ++position
            }
        })
    };

/**
 * Creates a list of elements suitable for display in the <FilteringMultiSelectList> included in this modules component.
 *
 * The sales rules are displayed as active or inactive depending on whether the current date is within its
 * validFrom/validTo range.
 *
 * @param salesRules A list of sales rules to display.
 * @param quotas
 * @param maxTicketsSalesRulesGroups
 * @param currentTimestampInSeconds Current date a timestamp in seconds.
 * @param bundles
 */
export function createListElements(salesRules, quotas, maxTicketsSalesRulesGroups,  currentTimestampInSeconds, bundles = []) {

    return salesRules.map((rule, index) => {
        const salesChannelsWithIcons = getSalesChannelsWithIcon(rule.salesRule.salesChannels);
        const deliveryGroupsWithDescriptions = getDeliveryGroupsWithDescriptions(rule.deliveryGroups);
        const ruleWithExtraData = {
            ...rule,
            salesRule: {
                ...rule.salesRule,
                salesChannels: salesChannelsWithIcons
            },
            deliveryGroups: deliveryGroupsWithDescriptions,
        }

        return ({
            ...ruleWithExtraData,
            index: index,
            status: determineIfSalesRuleActive(ruleWithExtraData, currentTimestampInSeconds) ? 'aktiv' : 'inaktiv',
            quota: quotas ? quotas.find(item => {
                return item.eventSalesRuleIds.includes(rule.id);
            }) : null,
            maxTicketsSalesRulesGroup: maxTicketsSalesRulesGroups ? maxTicketsSalesRulesGroups.find(item => {
                return item.eventSalesRuleIds.includes(rule.id);
            }) : null,
            bundle: bundles ? bundles.find(bundle => {
                return bundle.salesRules.find(bundleSalesRule => {
                    return bundleSalesRule.eventSalesRuleId === rule.id;
                });
            })  || null : null,
        });
    });
}


function FormSalesRules({submitPending, event, formik, ticketLayouts, refs, formSchema}) {
    const [salesRuleToAdd, setSalesRuleToAdd] = useState(null);
    const [salesRuleAssociationToEdit, setSalesRuleAssociationToEdit] = useState(null);
    const [allDeliveryGroups, setAllDeliveryGroups] = useState(null);
    const [showBatchEdit, setShowBatchEdit] = useState(false);
    const [batchEditSubmitButtonName, setBatchEditSubmitButtonName] = useState('');

    useEffect(() => {
        if (formSchema === MetaEvent) {
            api.getDeliveryGroupsForMetaEvent(formik.values.startDate).then(result => {
                const deliveryGroupsWithDescription = getDeliveryGroupsWithDescriptions(result)
                setAllDeliveryGroups(deliveryGroupsWithDescription);
            });
        }else {
            api.getDeliveryGroupsForVenueEvent(formik.values.startDate).then(result => {
                const deliveryGroupsWithDescription = getDeliveryGroupsWithDescriptions(result)
                setAllDeliveryGroups(deliveryGroupsWithDescription);
            });
        }
    }, [formik.values.startDate]);

    const batchActions = <>
        <FeedbackButton variant="outlined" onClick={() => setShowBatchEdit(true)} className='mt-2'>
            Auswahl bearbeiten
        </FeedbackButton>
    </>;

    const fieldCheckbox = {name: 'enabled', label: 'Regel freigeben', showStatusIcon: true, type: 'formSalesRules'};
    const fieldBatchAction = {name: 'selectedForBatchAction', label: 'Batch action', actions: batchActions};

    const fields = [
        {
            name: 'salesRule.name',
            label: 'Regel',
            transformValue: (name, element) => {
                return <>
                    <Link target="_blank" variant="link" to={{pathname: `/event-management/sales-rule/${element.salesRule.id}`}}>
                        {name}
                    </Link>
                </>;
            }
        },
        {
            name: 'salesRule.targetGroups',
            label: 'Personenpools',
            transformValue: (groups, element) => {
                const operator = get(element, 'salesRule.linkTargetGroups')
                const targetGroups = (groups || []).map(g => g.name).join(', ');

                const isActiveMembersOnly = get(element, 'activeMembersOnly');
                return <>
                    {operator}: {targetGroups} {!!isActiveMembersOnly && <i className="fa fa-calendar-plus-o"></i>}
                </>;
            }
        },
        {
            name: 'salesRule.placePools',
            label: 'Platzpools',
            transformValue: pools => (pools || []).map(p => p.name).join(', ')
        },
        {
            name: 'salesRule.pricingList.name',
            label: 'Preisliste',
            transformValue: (name, element) => {
                return <>
                    <Link target="_blank" variant="link" to={{pathname: `/event-management/pricing-list/${element.salesRule.pricingList.id}`}}>
                        {name}
                    </Link>
                </>;
            }
        },
        {
            name: 'ticketLayout.name',
            label: 'Ticketlayout',
            testid: 'ticketlayout',
            transformValue: (name, element) => {
                return <>
                    <Link target="_blank" variant="link" to={{pathname: `/base/ticket-layout/${element.ticketLayout.id}`}}>
                        {name}
                    </Link>
                    <div className={styles.PrintAtHomeLabel}>P@H Layout:</div>
                    {element.printAtHomeTicketLayout &&
                        <Link target="_blank" variant="link"
                              to={{pathname: `/base/ticket-layout/${element.printAtHomeTicketLayout.id}`}}>
                            {element.printAtHomeTicketLayout.name}
                        </Link>
                    }
                </>;
            }
        },
        {name: 'validFrom', label: 'Gültig von', type: 'datetime'},
        {name: 'validTo', label: 'Gültig bis', type: 'datetime'},
    ];

    fields.push(
        {
            name: 'maxTicketsPerUser',
            label: 'Max. Tickets',
            testid: 'maxTicketsPerUser',
            transformValue: (name, element) => {
                const limits = [];

                if (element.maxTicketsPerUser) {
                    limits.push(element.maxTicketsPerUser);
                }
                if (element.maxTicketsSalesRulesGroup) {
                    if (limits.length === 0) {
                        limits.push('∞');
                    }
                    limits.push(element.maxTicketsSalesRulesGroup.maxTicketsPerUser);
                }

                if (limits.length === 1) {
                    return <>{limits[0]}</>;
                } else if (limits.length === 2) {
                    return <>{limits[0]} / <span
                        className={classNames('max-tickets-limit', (element.maxTicketsSalesRulesGroup.enabled > 0 ? 'enabled' : 'disabled'))}
                        title={element.maxTicketsSalesRulesGroup.name}>{limits[1]}</span></>;
                }
            }
        },
        {
            name: 'salesRule.salesChannels',
            label: 'Kanal',
            keyFunc: e => e.salesRule.salesChannels[0].name,
            transformValue: channels => {
                // instead of a textual representation render list of icons
                return <>{(channels || []).map(c => <span  key={c.id} title={c.name}>{c.icon}</span>)}</>;
            }
        },
        {
            name: 'deliveryGroups',
            label: 'Versandarten',
            keyFunc: e => e.deliveryGroups[0].shippingCode,
            transformValue: groups => (groups || []).map(g => <span className={g.shippingCode} key={g.shippingCode}
                                                     title={g.description}>{g.shippingCode} </span>),
            testid: 'delivery_groups'
        }
    );

    const INITIAL_SUBVALUES = {
        salesRule: '',
    };


    const SUBFORM_SCHEMA = yup.object().shape({
        salesRule: yup
            .mixed()
            .transform((originalValue, originalObject) => {
                if (originalObject.salesRule && typeof originalObject.salesRule === 'object') {
                    return originalObject.salesRule.name || '';
                }
                return originalValue;
            })
            .nullable()
            .required('Sales Rule is required'),
    });

    // current timestamp
    let now = Date.now() / 1000;

    const invokeAddSalesRuleAssociationModal = ({salesRule}, {resetForm}) => {
        setSalesRuleToAdd(salesRule);
        resetForm();
    }

    const addSalesRuleAssociation = (association) => {
        formik.setFieldValue('salesRules', [...formik.values.salesRules, {
            ...association,
            id: ObjectId.generate()
        }]);

        setSalesRuleToAdd(null);
    }

    const updateSalesRuleAssociation = (updatedAssociation) => {
        let salesRules = getIn(formik.values, 'salesRules', []);
        const index = findIndex(salesRules, s => s.id === updatedAssociation.id);
        if (index !== -1) {
            salesRules = [...salesRules];
            salesRules.splice(index, 1, updatedAssociation);
            formik.setFieldValue('salesRules', salesRules);
        }

        setSalesRuleAssociationToEdit(null);
    };

    const batchUpdateSalesRuleAssociations = (updatedAssociation) => {
        let salesRules = getIn(formik.values, 'salesRules', []);
        const shippingCodeMatches = batchEditSubmitButtonName.match(/^shippingCode_(.+)/);

        if (shippingCodeMatches !== null && shippingCodeMatches.length === 2) {
            const deliveryGroupShippingCode = shippingCodeMatches[1];
            const deliveryGroup = updatedAssociation.deliveryGroups.find(
                deliveryGroup => deliveryGroup.shippingCode === deliveryGroupShippingCode
            )

            salesRules = (salesRules.map(salesRule => {
                if (!salesRule.selectedForBatchAction) {
                    return salesRule;
                }

                const deliveryGroups = salesRule.deliveryGroups.filter(
                    deliveryGroup => deliveryGroup.shippingCode !== deliveryGroupShippingCode
                );

                if (deliveryGroup) {
                    deliveryGroups.push(deliveryGroup);
                }

                deliveryGroups.sort((a, b) => {
                    a = allDeliveryGroups.find(dg => dg.shippingCode === a.shippingCode).position;
                    b = allDeliveryGroups.find(dg => dg.shippingCode === b.shippingCode).position;

                    return Math.sign(a - b)
                });

                return {
                    ...salesRule,
                    deliveryGroups: deliveryGroups
                }
            }));
        } else {
            salesRules = (salesRules.map(salesRule => {
                if (!salesRule.selectedForBatchAction) {
                    return salesRule;
                }
                if ([
                    'maxTicketsPerUser',
                    'maxTicketsPerUser',
                    'validFrom',
                    'validTo',
                    'secondaryMarketFeeFactorCode',
                    'useSecondaryMarket',
                    'provideDownloadUrl',
                    'activeMembersOnly',
                    'activeMemberSinceAt',
                    'useFor',
                ].includes(batchEditSubmitButtonName)) {
                    return {
                        ...salesRule,
                        [batchEditSubmitButtonName]: updatedAssociation[batchEditSubmitButtonName]
                    };
                } else if ([
                    'ticketLayout',
                    'printAtHomeTicketLayout',
                ].includes(batchEditSubmitButtonName) && updatedAssociation[batchEditSubmitButtonName]) {
                    return {
                        ...salesRule,
                        [batchEditSubmitButtonName]: updatedAssociation[batchEditSubmitButtonName]
                    };
                } else if (batchEditSubmitButtonName === 'tags') {
                    return {
                        ...salesRule,
                        tags: [...updatedAssociation[batchEditSubmitButtonName]]
                    };
                }
                return salesRule;
            }));
        }

        formik.setFieldValue('salesRules', salesRules);
        setShowBatchEdit(false);
    };

    const onChange = (selection) => {
        formik.setFieldValue('salesRules', selection)
    };


    /**
     * Renders the action button to remove an assigned sales rule from the event.
     *
     * This implicitly setups up a modal confirm dialog for the action as well.
     *
     * @param id The ID of the sales rule to be removed
     * @param salesRule The sales rule to be removed
     */
    const RemoveSalesRuleAction = ({id, salesRule}) => {
        const removeSalesRule = (salesRuleId) => () => {
            const values = formik.values.salesRules.filter(rule => rule.id !== salesRuleId);
            formik.setFieldValue('salesRules', values)
        };

        return (
            <ConfirmModal title="Verkaufsregel löschen"
                          body={`Das Entfernen einer Regel aus dieser Liste führt dazu, dass das Wissen über die Anzahl der darüber gekauften Tickets verloren geht. 
                                 Dies kann nicht rückgängig gemacht werden.
                                 Möchten Sie die Verkaufsregel "${salesRule.name}" trotzdem löschen?`}
                          cancelLabel="Abbrechen"
                          confirmLabel="Löschen"
            >
                {confirm => (
                    <FeedbackButton title="Verkaufsregel löschen" className="list-link" onClick={() => confirm(removeSalesRule(id))}>
                        <DeleteOutlineOutlinedIcon className='table-icon' />
                    </FeedbackButton>
                )}
            </ConfirmModal>
        )
    }

    const EditSalesRuleAssociationButton = ({association}) => (
        <FeedbackButton title="Verkaufsregel editieren" className="list-link" onClick={() => setSalesRuleAssociationToEdit(association)}>
            <EditNoteOutlinedIcon className='table-icon' />
        </FeedbackButton>
    )

    return (
        <>
            {/* modal form for adding a sales association */}
            <FormModal show={!!salesRuleToAdd}
                       title="Verkaufsregel hinzufügen"
                       initialValues={{...SalesRuleAssociation.default(), salesRule: salesRuleToAdd}}
                       validationSchema={SalesRuleAssociation}
                       onConfirm={addSalesRuleAssociation}
                       onCancel={() => setSalesRuleToAdd(null)}
                       submitTestId="submit_salesRule"
            >
                {({associationFormik}) => {
                    return (
                        <SalesRuleAssociationForm associationFormik={associationFormik} allDeliveryGroups={allDeliveryGroups} ticketLayouts={ticketLayouts}/>
                    )
                }}
            </FormModal>
            {/* modal form for editing a sales association */}
            <FormModal show={!!salesRuleAssociationToEdit}
                       title="Verkaufsregel editieren"
                       initialValues={{...SalesRuleAssociation.default(), ...salesRuleAssociationToEdit}}
                       validationSchema={SalesRuleAssociation}
                       onConfirm={updateSalesRuleAssociation}
                       onCancel={() => setSalesRuleAssociationToEdit(null)}
                       submitTestId="submit_salesRule"
            >
                {({associationFormik}) => {
                    return (
                        <SalesRuleAssociationForm associationFormik={associationFormik} allDeliveryGroups={allDeliveryGroups} ticketLayouts={ticketLayouts}/>
                    )
                }}
            </FormModal>
            {/* modal form for batch edit a sales rules */}
            <Modal size="lg" show={showBatchEdit} onHide={() => setShowBatchEdit(false)} enforceFocus={false} centered>
                <Formik onSubmit={batchUpdateSalesRuleAssociations}
                        initialValues={{...SalesRuleAssociation.default(), ...salesRuleAssociationToEdit}}>
                    {(associationFormik) => (
                        <Form>
                            <Modal.Header closeButton>
                                <Modal.Title>Batchbearbeitung</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <SalesRuleBatchEdit associationFormik={associationFormik}
                                                    allDeliveryGroups={allDeliveryGroups}
                                                    setBatchEditSubmitButtonName={setBatchEditSubmitButtonName}
                                                    ticketLayouts={ticketLayouts}/>
                            </Modal.Body>
                            <Modal.Footer>
                                <FeedbackButton
                                    type="button"
                                    variant="outlined"
                                    onClick={() => setShowBatchEdit(false)}
                                >
                                    Abbrechen
                                </FeedbackButton>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
            <div className={styles.formBox}>
                <h2 className={styles.formTitle}>Maximale Tickets je Käufer für dieses Event</h2>
                <Row className="mt-3">
                    <Col className="col-auto align-self-center">
                        <p className="d-inline">In Summe dürfen von jedem Käufer für dieses Event absolut maximal nur</p>
                    </Col>
                    <Col className="col-md-3">
                        <FormikTextInputGroup
                            name="maxTicketsPerUser"
                            type="number"
                            min="1"
                            max={'999'}
                            className="counterMui"
                        />
                    </Col>
                    <Col className="col-auto align-self-center">
                        <p className="d-inline">Karten gekauft/verkauft werden.</p>
                    </Col>
                </Row>
            </div>
            <FormMaxTicketsSalesRulesGroups
                event={event}
                formik={formik}
            />
            <div className={styles.formBox}>
                <h2 className={styles.formTitle}>Liste der Verkaufsregeln</h2>
                <FilteringMultiSelectList
                    elements={createListElements(formik.values.salesRules, formik.values.quotas, formik.values.maxTicketsSalesRulesGroups, now, formik.values.bundles)}
                    refs={refs}
                    fields={fields}
                    fieldCheckbox={fieldCheckbox}
                    formik={formik}
                    fieldBatchAction={fieldBatchAction}
                    onChange={onChange}
                >
                    {({element}) => {
                        return (
                            <div key={`div${element.id}`}>
                                <EditSalesRuleAssociationButton association={element}/>
                                <RemoveSalesRuleAction id={element.id} salesRule={element.salesRule}/>
                            </div>
                        )
                    }}
                </FilteringMultiSelectList>
            </div>
            <div className={styles.formBox}>
                <Formik initialValues={{...INITIAL_SUBVALUES}}
                        validationSchema={SUBFORM_SCHEMA}
                        onSubmit={invokeAddSalesRuleAssociationModal}
                >
                    {subformik => {
                        return (
                            <Row>
                                <Col className='col-md-4'>          
                                    <h2 className={styles.formTitle}>Hinzufügen von Verkaufsregeln</h2>
                                    <div className='titleWrapWithBtn'>
                                        <FeedbackButton
                                            className="mb-3"
                                            icon={<AddIcon />}
                                            onClick={() => subformik.submitForm()}
                                            style={{ flexShrink: 0 }}
                                        >
                                            Verkaufsregel hinzufügen
                                        </FeedbackButton>
                                        <HelpContent as="p" className='longTitle'>Hier können weitere Verkaufsregeln hinzugefügt werden. Sie können in diesem Feld auch nach Verkaufsregeln suchen, indem Sie nur einen Teil des Namens eingeben.</HelpContent>
                                    </div>
                                    <div>
                                        <FormikAsyncTypeaheadInput
                                            id="salesRule"
                                            label="Verkaufsregel*"
                                            onSearch={query => api.getSalesRulesByQuery(query)}
                                            labelKey={o => `${o.name}`}
                                            minLength={0}
                                            testid="add_salesRule"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        )
                    }}
                </Formik>
            </div>
        </>
    )
}

FormSalesRules.propTypes = {
    submitPending: PropTypes.bool,
    formik: PropTypes.object,
    ticketLayouts: PropTypes.any,
    refs: PropTypes.object,
    metaEventStatus: PropTypes.object,
    event: PropTypes.object,
    createCart: PropTypes.func,
    createTickets: PropTypes.func,
};

export default FormSalesRules;
