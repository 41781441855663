import React from 'react';
import Form from 'react-bootstrap/Form';
import {get, isArray} from 'lodash';
import {connect, getIn} from 'formik';
import {arrayOf, shape, string} from 'prop-types';

/**
 * Stellt eine Auswahl über Objekten dar.
 *
 * @param name Name des Datenfeldes im Modell, kann ein Pfad sein bspw. "foo.bar.baz".
 * @param label Label das an dem Eingabefeld angezeigt werden soll.
 * @param emptyValueLabel Label das in der Auswahl angezeigt werden soll, wenn keine der angegebenen Optionen ausgewählt ist.
 * @param options Die zur Auswahl stehenden Objekte.
 * @param keyProperty Property der Objekte welche als "key" prop verwendet werden kann, default ist "id".
 * @param labelProperty Property der Objekte welche als Label in der Auswahl angezeigt werden soll, default ist "name"
 * @param secondLabelProperty Property der Objekte welche als zweites Label hinter dem ersten in einer Klammer in der Auswahl angezeigt werden soll, default ist ""
 * @param formik
 * @param onChange
 */
function FormikObjectSelect({
                                name, label, emptyValueLabel, options, keyProperty = 'id', labelProperty = 'name', testid,
                                secondLabelProperty = '', formik, onChange = () => {}, inputRef, disabled
                            }) {

    const getValue = () => {
        if (isArray(getIn(formik.values, name))) {
            return (getIn(formik.values, name)[0] || {})[keyProperty];
        } else {
            return (getIn(formik.values, name) || {})[keyProperty];
        }
    }

    const getError = () =>
        getIn(formik.errors, name);

    const isInvalid = () =>
        getError() && getIn(formik.touched, name);

    const handleChange = (event) => {
        formik.setFieldValue(name, options.find(o => o[keyProperty] === event.target.value) || null);
        onChange(event.target.value)
    };
    return (
        <Form.Group className="mb-0">
            {label && <Form.Label>{label}</Form.Label>}
            <Form.Control as="select"
                          id={name}
                          value={getValue()}
                          isInvalid={isInvalid()}
                          onChange={handleChange}
                          onBlur={formik.handleBlur}
                          disabled={disabled}
                          data-testid={testid}
                          ref={inputRef}
            >
                <option value="">{emptyValueLabel && emptyValueLabel}</option>
                {options.map(option => (
                    <option key={option[keyProperty]} value={option[keyProperty]}>
                        {secondLabelProperty
                            ? (`${get(option, labelProperty)} (${get(option, secondLabelProperty)})`)
                            : (`${get(option, labelProperty)}`)
                        }

                    </option>
                ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">{getError()}</Form.Control.Feedback>
        </Form.Group>
    );
}

FormikObjectSelect.propTypes = {
    name: string.isRequired,
    label: string,
    emptyValueLabel: string.isRequired,
    options: arrayOf(shape({id: string.isRequired})).isRequired,
    keyProperty: string,
    labelProperty: string,
    secondLabelProperty: string,
};

export default connect(FormikObjectSelect);
