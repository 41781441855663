import React, {Component} from 'react';
import {connect} from "react-redux";
import {LoadingIndicator} from "../common/LoadingIndicator";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";
import {Col, Row} from "react-bootstrap";
import VerticalSpacer from "../layout/VerticalSpacer";
import * as order from "../../state/entities/order";
import OrderDetails from "./OrderDetails";

class Details extends Component {
    componentDidMount() {
        this.props.loadOrder(this.props.orderId);
    }

    render() {
        const {order, loading} = this.props;
        if (!order || loading) {
            return <LoadingIndicator/>;
        }
        return <>
            <Row className="my-4">
                <Breadcrumbs entity={['Spielstätte', 'Saalplan']} endpoints={['order/breadcrumb']} title = "Orders" links={['/sales/order/']} active={[order.id]} />
            </Row>
            <Row className="mb-4 mt-4">
                <Col className="pt-3 col-sm-8">
                    <h1>Bestellung: <em>{order.orderId}</em></h1>
                </Col>
            </Row>
            <OrderDetails order={order} />
            <VerticalSpacer size={4}/>
        </>;
    }
}

const mapStateToProps = (state, props) => {
    const orderId = props.match.params.id;
    return {
        orderId,
        loading: state.entities.order.loading[orderId],
        saving: state.entities.order.saving[orderId],
        order: state.entities.order.byId[orderId]
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadOrder: id => dispatch(order.actions.load({id}))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);
