import React from "react";
import {ButtonToolbar, Col, Row} from 'react-bootstrap';

/**
 * Create a Footer that can contain several Buttons in it
 *
 * @param children - Buttons to be rendered inside the footer
 * @return  {JSX}
 */
export default function Footer({children}) {
    return (
        <footer className="pricing-matrix-footer">
            {children}
        </footer>
    );
}
