import React, {useEffect, useState} from 'react';
import * as yup from 'yup';
import {ErrorMessage, Field, Formik, getIn} from 'formik';
import {Col, Form as BootstrapForm, Form, Row} from 'react-bootstrap';
import {FormikTextInputGroup} from '../common/formik/FormikTextInputGroup';
import * as PropTypes from 'prop-types';
import FeedbackButton from '../common/FeedbackButton';
import {connect} from 'react-redux';
import MultiSelectList from "../common/form/MultiSelectList";
import * as placePool from "../../state/entities/placePool";
import {LoadingIndicator} from "../common/LoadingIndicator";
import FormikAsyncTypeaheadInput from "../common/formik/FormikAsyncTypeaheadInput";
import Api from "../../api";
import Footer from "../layout/Footer";
import {Link} from "react-router-dom";
import MaterialInput from "../layout/MaterialInput";
import Table from "react-bootstrap/Table";
import {filter, includes, some, upperCase, words} from "lodash";
import ConfirmModal from "../common/modal/ConfirmModal";
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import FormikCheckbox from "../common/formik/FormikCheckbox";
import FormikRadio from '../common/formik/FormikRadio';
import styles from "../form.module.scss";
import AppsIcon from '@mui/icons-material/Apps';
import WebIcon from '@mui/icons-material/Web';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import LockIcon from '@mui/icons-material/Lock';
import InboxIcon from '@mui/icons-material/Inbox';

export const getSalesChannelsWithIcon = (salesChannels) => {
    const channels = salesChannels;
    return channels.map(ch => {
        let icon = <BookOnlineIcon fontSize='small' />;
        switch (ch.id) {
            case "app":
                icon = <AppsIcon fontSize='small'/> 
                break;
            case "web":
                icon = <WebIcon fontSize='small' />
                break;
            case "ticket_agency":
                icon = <BookOnlineIcon fontSize='small' />
                break;
            case "pos":
                icon = <FormatBoldIcon fontSize='small' />
                break;
            case "stadium_box_office":
                icon = <SportsSoccerIcon fontSize='small' />
                break;
            case "backend":
                icon = <LockIcon fontSize='small' />
                break;
            default:
                icon = <InboxIcon fontSize='small' />
        }
        return {
            ...ch,
            icon: icon
        }
    })
};

const api = new Api();

const FORM_SCHEMA = yup.object().shape({
    name: yup.string().required().default(''),
    targetGroups: yup.array().min(1).required().default([]),
    linkTargetGroups: yup.mixed().oneOf([
        "OR",
        "AND"
    ]),
    placePools: yup.array().min(1).required().default([]),
    salesChannels: yup.array().required().default([]),
    pricingList: yup.mixed().required(),
    archived: yup.bool()
});

const FormRadioItems = [
    {
        id: 'linkTargetGroupsOR',
        value: 'OR',
        name: 'OR (Gehört zu mindestens einem Personenpool)',
        component: ''
    },
    {
        id: 'linkTargetGroupsAND',
        value: 'AND',
        name: 'AND (Gehört zu allen Personenpools)',
        component: ''
    },
];

const SalesRuleForm = ({salesRule, onSubmit, submitPending, loadPlacePools, allPlacePools, helpTextsVisible}) => {

    let [salesChannels, setSalesChannels] = useState([]);
    let [usedInEvents, setUsedInEvents] = useState([]);
    let [filterEventText, setFilterEventText] = useState('');

    const [isArchived, setIsArchived] = useState(salesRule.archived);
    const ArchivedStatus = ({setFieldValue}) => {
        const confirmChangeStatus = () => () => {
            let newStatus = !isArchived
            setIsArchived(newStatus);
            setFieldValue('archived', newStatus);
        };
        return (
            <ConfirmModal title="Status ändern"
                          body={(isArchived) ? `Verkaufsregel wieder reaktivieren?`: `Verkaufsregel ins Archiv verschieben?`}
                          cancelLabel="Abbrechen"
                          confirmLabel="Bestätigen"
            >
                {confirm => (
                    <FormikCheckbox
                        type="checkbox"
                        id="archived"
                        label="Verkaufsregel archivieren"
                        onChange={(e) => confirm(confirmChangeStatus(e))}
                        name="archived"
                        checked={isArchived}
                    />
                )}
            </ConfirmModal>
        )
    }

    useEffect(() => {
        api.getSalesChannels().then(result => {
            setSalesChannels(result);
        });

        if (salesRule.id !== undefined) {
            api.getUsedEventsBySalesRule(salesRule.id).then(result => {
                setUsedInEvents(result);
            });
        }

        loadPlacePools();
        // We want this process to happen only once at "on-mount", therefore we use [] as dependency.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const INITIAL_VALUES = {
        linkTargetGroups: "OR",
        archived: false
    };

    if (Object.values(allPlacePools).length === 0) {
        return <LoadingIndicator/>;
    }

    const getFilteredEvents = () => {
        const filterWords = words(filterEventText);
        if (filterWords.length === 0) return usedInEvents ? usedInEvents : [];
        return filter(usedInEvents, element => {
            return some(filterWords, filterWord =>
                    includes(upperCase(element.title), upperCase(filterWord))
                );
        });
    };

    return <Formik
        initialValues={{...FORM_SCHEMA.default(), ...INITIAL_VALUES, ...salesRule}}
        validationSchema={FORM_SCHEMA}
        onSubmit={onSubmit}
    >
        {formik => {
            const handleSalesChannelSelectionChange = (salesChannelSelection) => {
                let channels = salesChannelSelection.filter(selection => typeof (selection) === 'object');
                formik.setFieldValue("salesChannels", channels);
            };
            const selectedPricingList = getIn(formik.values, 'pricingList', {});

            return (
                <form onSubmit={formik.handleSubmit} className={styles.formCotainer}>
                    <div className={styles.formBox}>
                        <h2 className={styles.formTitle}>Allgemein</h2>
                        <Row className="mt-3">
                            <Col className="col-md-4">
                                <FormikTextInputGroup label="Titel der Verkaufsregel*" name="name"
                                                        helpTextsVisible={helpTextsVisible}/>
                            </Col>
                            <Col className="col-md-4 align-self-center">
                                <ArchivedStatus setFieldValue={formik.setFieldValue}/>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col className="col-md-4 mb-3">
                                <FormikAsyncTypeaheadInput
                                    id="targetGroups"
                                    label="Personenpools*"
                                    onSearch={query => api.getTargetGroupsByQuery(query)}
                                    labelKey={o => o.name}
                                    minLength={0}
                                    multiple={true}
                                    helpText="Hier können mehrere Personenpools hinzugefügt werden, geben Sie für die Suche die Personenpools in das Feld ein."
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col className="col-md-4 mb-3">
                                <p>Verknüpfung für Personenpools</p>

                                <Form.Group>
                                    <FormikRadio
                                        name="linkTargetGroups"
                                        items={FormRadioItems}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col className="col-md-4 mb-3">
                                <FormikAsyncTypeaheadInput
                                    id="placePools"
                                    label="Platzpools*"
                                    onSearch={query => api.getPlacePoolsByQuery(query)}
                                    labelKey={o => o.name}
                                    minLength={0}
                                    multiple={true}
                                    helpText="Hier können mehrere Platzpools hinzugefügt werden, geben Sie für die Suche die Platzpools in das Feld ein."
                                />

                            </Col>

                        </Row>
                        <Row className="mt-3">
                            <Col className="col-md-4 mb-3">
                                <p>Verkaufskanäle*</p>
                                <MultiSelectList
                                    fieldMap={{"name": 'Titel'}}
                                    elements={getSalesChannelsWithIcon(salesChannels)}
                                    selected={salesRule.salesChannels}
                                    onChange={handleSalesChannelSelectionChange}
                                />
                                <ErrorMessage className="form-text text-danger" name="salesChannels">
                                    {msg => <small className="form-text text-danger">{msg}</small>}
                                </ErrorMessage>
                            </Col>

                        </Row>
                        <Row className="mt-3">
                            <Col className="col-md-4 mb-3">
                                <Form.Label htmlFor="pricingList" className="w-100">
                                    Preisliste* &nbsp;
                                    {selectedPricingList &&
                                        <Link target="_blank" to={`/event-management/pricing-list/${selectedPricingList.id}`}>
                                            {selectedPricingList.name}
                                        </Link>
                                    }

                                    <Link className="float-right" target="_blank" to={"/event-management/pricing-list/create"}>
                                        Neue Preisliste erstellen
                                    </Link>
                                </Form.Label>
                                <FormikAsyncTypeaheadInput
                                    id="pricingList"
                                    onSearch={query => api.getPricingListsByQuery(query)}
                                    labelKey={o => o?.name}
                                    minLength={0}
                                />
                            </Col>
                        </Row>
                    </div>
                    {salesRule.id !== undefined && <div className={styles.formBox}>
                        <Row>
                            <Col className="col-md-4">
                                <h2 className={styles.formTitle}>Regel wird in folgenden bevorstehenden Events genutzt</h2>
                                <div className={styles.searchBarWrap}>
                                    <MaterialInput
                                        label='Suche'
                                        className="MuiFormControl-searchBar"
                                        onChange={value => setFilterEventText(value)}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-md-4">
                                <Table striped bordered hover>
                                    <thead>
                                    <tr>
                                        <th key="Titel">Titel</th>
                                        <th key="Action">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {getFilteredEvents().map(element =>
                                        <tr key={element.id}>
                                            <td>{element.title}</td>
                                            <td>
                                                {element.eventType === 'venueEvent' &&
                                                    <FeedbackButton
                                                        to={`/event-management/event/${element.id}`}
                                                        className="list-link"
                                                        title="Event bearbeiten"
                                                        target="_blank"
                                                        icon={<EditNoteOutlinedIcon className='table-icon' />}
                                                    />
                                                }

                                                {element.eventType === 'metaEvent' &&
                                                    <FeedbackButton
                                                        to={`/event-management/meta-event/${element.id}`}
                                                        className="list-link"
                                                        title="Event bearbeiten"
                                                        target="_blank"
                                                        icon={<EditNoteOutlinedIcon className='table-icon' />}
                                                    />
                                                }
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </div>}

                    <Footer>
                        <FeedbackButton to={`/event-management/sales-rule`}>
                            Abbrechen
                        </FeedbackButton>
                        <FeedbackButton
                            type="submit"
                            busy={submitPending}
                        >
                            Speichern
                        </FeedbackButton>
                    </Footer>
                </form>
            )
        }
        }
    </Formik>
};


SalesRuleForm.propTypes = {
    onSubmit: PropTypes.func,
    submitPending: PropTypes.bool,
    blockGroup: PropTypes.any,
    requestErrors: PropTypes.any
};

const mapStateToProps = (state, props) => {
    const helpTextsVisible = state.helpTextsToggle;
    return {
        allPlacePools: state.entities.placePool.byId,
        helpTextsVisible
    }
};

const mapDispatchToProps = dispatch => ({
    loadPlacePools: () => dispatch(placePool.actions.loadAll()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesRuleForm);


