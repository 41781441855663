import {get} from 'lodash';

/**
 * Reducer für Entitäten, der auf "normalizr" responses reagiert und mit deren Inhalt den state aktualisiert.
 * @param entityName  Der Name der Entität auf die der Reducer reagiert.
 */
export default (entityName) => (state = {}, action) => {
    // Es wir auf alle actions reagiert, die eine "normalizr" reponse als payload haben mit den entsprechenden entities.
    const entities = get(action, ['payload', 'response', 'entities', entityName]);

    if (entities) {
        return {
            ...entities
        };
    }

    if (get(action, ['payload', 'response', 'result'])?.length === 0) {
        return {};
    }

    return state;
}

