import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import Table from "react-bootstrap/Table";
import {FormattedDate} from "react-intl";
import {Link} from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import styles from "../form.module.scss";
import { getIn } from 'formik';
import Api from "../../api";

const api = new Api();

const dataConfig = [
    {
        fetchFunction: 'getMediaEvents',
        type: 'event',
        title: 'Events',
        titleCategory: 'Event Titel',
        linkText: 'event',
        linkUrl: '/event-management/event/',
        titleField: 'title.de',
        idField: 'id',
        isDate: true
    },
    {
        fetchFunction: 'getMediaArtists',
        type: 'artist',
        title: 'Künstler',
        titleCategory: 'Künstler name',
        linkText: 'künstler',
        linkUrl: '/event-management/artist/',
        titleField: 'name',
        idField: 'id'
    },
    {
        fetchFunction: 'getMediaEventSeries',
        type: 'event-series',
        title: 'Eventserien',
        titleCategory: 'Eventserien name',
        linkText: 'eventserien',
        linkUrl: '/event-management/event-series/',
        titleField: 'name.de',
        idField: 'id'
    },
    {
        fetchFunction: 'getMediaVenues',
        type: 'venue',
        title: 'Spielstätten',
        titleCategory: 'Spielstätten name',
        linkText: 'spielstätten',
        linkUrl: '/base/venue/',
        titleField: 'name',
        idField: 'id'
    },
    {
        fetchFunction: 'getMediaTicketLayouts',
        type: 'ticket-layout',
        title: 'Ticketlayouts',
        titleCategory: 'Ticketlayouts name',
        linkText: 'ticketlayouts',
        linkUrl: '/base/ticket-layout/',
        titleField: 'name',
        idField: 'id'
    },
  ];

function UsedIn({ mediaId }) {
    const [isLoading, setIsLoading] = useState(false);
    const [stateData, setStateData] = useState({});

    const fetchData = async (fetchFunction, type) => {
        try {
            const result = await api[fetchFunction](mediaId);
            setStateData(prevState => ({
                ...prevState,
                [type]: result ?? [],
                [`${type}Fetching`]: false,
            }));
        } catch (error) {
            console.error(`Error fetching ${type} data:`, error);
            setStateData(prevState => ({
                ...prevState,
                [`${type}Fetching`]: false,
            }));
        }
    };

    useEffect(() => {
        if (!mediaId) {
            return;
        }

        setIsLoading(true);

        const fetchAllData = async () => {
            await Promise.all(
                dataConfig.map(async ({ fetchFunction, type }) => {
                    await fetchData(fetchFunction, type);
                })
            );
      
            setIsLoading(false);
          };

        fetchAllData();

    }, [mediaId]);

    return (
        <div className={styles.formBox}>
            <Row>
                {dataConfig.map(({
                    type,
                    titleField,
                    title,
                    titleCategory,
                    linkText,
                    linkUrl,
                    idField,
                    isDate
                }) => (
                    <Col key={type} className="col-md-4 mt-3">
                        <h2 className={styles.formTitle}>{title}</h2>
                        <Table striped bordered hover className="multiselectlist">
                            <thead>
                                <tr>
                                    <th>{titleCategory}</th>
                                    {isDate && <th>Startzeitpunkt</th>}
                                    <th width="30%">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!isLoading ? (
                                    stateData[`${type}Fetching`] ? (
                                        <Spinner animation="border" role="status" />
                                    ) : (
                                        (stateData[type] || []).map((element, key) => {
                                            const modifiedUrl = element.type && element.type === 'metaEvent' ?
                                                '/event-management/meta-event/' :
                                                linkUrl
                                            return (
                                                <tr key={key}>
                                                    <td>{getIn(element, titleField)}</td>
                                                    {isDate && (
                                                        <td>
                                                            <FormattedDate
                                                                value={element.startDate}
                                                                day="2-digit"
                                                                month="2-digit"
                                                                year="numeric"
                                                            />
                                                        </td>
                                                    )}
                                                    <td>
                                                        <Link target="_blank" to={`${modifiedUrl}${element[idField]}`}>
                                                            zu diesem {linkText} springen
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    )
                                ) : (
                                    <Spinner animation="border" role="status" />
                                )}
                            </tbody>
                        </Table>
                    </Col>
                ))}
            </Row>
        </div>
    )
}

export default UsedIn;
