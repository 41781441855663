import {
    AbstractIdleState,
    DisplayContext,
    DrawRectState,
    InteractionState,
} from './common';
import {Point} from '../../geometry';
import {FederatedPointerEvent, Polygon} from 'pixi.js';



/**
 * Most interaction is done via PIXI inside AreaForms classes.
 */
export class AreaFormsIdleState extends AbstractIdleState {

    onInstallation(context: DisplayContext) {
        context.areaFormsManager.setEditMode(true);
    }

    onEviction(context: DisplayContext): void {
        context.areaFormsManager.setEditMode(false);
    };

    onPointerDown(context: DisplayContext, origin: Point, event: FederatedPointerEvent): InteractionState {
        return this;
    }
}


export class AreaFormsCreateState extends AbstractIdleState {

    onPointerDown(context: DisplayContext, origin: Point, event: FederatedPointerEvent): InteractionState {
        return new AreaFormsCreateDrawState(origin);
    }
}


class AreaFormsCreateDrawState extends DrawRectState {

    constructor(origin: Point) {
        super(origin, 0x006600);
    }

    protected processRect(rect: Flatten.Box, context: DisplayContext): InteractionState {
        const aFRect = new Polygon(
            rect.xmin, rect.ymin,
            rect.xmax, rect.ymin,
            rect.xmax, rect.ymax,
            rect.xmin, rect.ymax);
        context.areaFormsManager.requestAddAreaForm(aFRect);
        return new AreaFormsIdleState();
    }
}
