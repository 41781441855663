import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import {Formik} from "formik";
import Table from "react-bootstrap/Table";
import {FormattedDate} from "react-intl";
import FormVenueEventsAdd from "./FormVenueEventsAdd";
import {Link} from "react-router-dom";
import FeedbackButton from '../common/FeedbackButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

function FormVenueEvents({formik, metaEventStatus, loadMetaEventStatus}) {
    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
    const [standingBlockToDelete, setStandingBlockToDelete] = useState(undefined);

    let firstVenueEvent = formik.values.venueEvents && formik.values.venueEvents.length ? formik.values.venueEvents[0] : null;

    useEffect(() => {
        loadMetaEventStatus(formik);
    }, [formik.values.venueEvents, firstVenueEvent]);

    const onSubmitVenueEventAdd = (values, {resetForm}) => {
        if (values.venueEvent) {
            const venueEvent = metaEventStatus.venueEvents.find(item => {
                return item.id === values.venueEvent;
            });
            venueEvent && formik.setFieldValue('venueEvents', [...formik.values.venueEvents, {
                ...venueEvent
            }]);
        }

        // Resetten der form, damit Werte auf Initial Values gesetzt werden
        resetForm();
    };

    const deleteStandingBlock = (standingBlockIndex) => {
        let venueEvents = formik.values.venueEvents.filter((standingBlock, index) => {
            return (index !== standingBlockIndex)
        });
        formik.setFieldValue('venueEvents', venueEvents);
    };

    const showDeleteConfirmingDialog = (index) => {
        setShowDeleteConfirmDialog(true);
        setStandingBlockToDelete(index);
    };

    const handleCancelDelete = () => {
        setShowDeleteConfirmDialog(false);
    };

    const handleConfirmDelete = () => {
        setShowDeleteConfirmDialog(false);
        deleteStandingBlock(standingBlockToDelete);
    };

    return (
        <>
            <Row className="mt-4">
                <Col className="col-md-4">
                    <h1>Meta-Event {formik.values.title.de ? '"' + formik.values.title.de + '" -' : ""} Enthaltene Events</h1>
                    <Table striped bordered hover className="multiselectlist">
                        <thead>
                        <tr>
                            <th width="50%">Event Titel</th>
                            <th width="15%">Startzeitpunkt</th>
                            <th width="15%">Saalplan</th>
                            <th width="30%">&nbsp;</th>
                            <th width="5%">&nbsp;</th>
                        </tr>
                        </thead>
                        <tbody>
                        {formik.values.venueEvents.map((element, eventIndex) => (
                            <tr>
                                <td>{element.title.de}</td>
                                <td><FormattedDate value={element.startDate} day="2-digit" month="2-digit"
                                                   year="numeric"/></td>
                                <td>
                                    <Link target="_blank" to={`/base/venue/${element.venue.id}/venue-plan/${element.venuePlan.id}`}>
                                    {element.venuePlan.name}
                                </Link>
                                </td>
                                <td>
                                    <Link target="_blank" to={`/event-management/event/${element.id}`}>
                                        zu diesem Event springen
                                    </Link>
                                </td>
                                <td>
                                    <FeedbackButton
                                        title="Zuordnung entfernen"
                                        className="list-link link-btn"
                                        onClick={() => showDeleteConfirmingDialog(eventIndex)}
                                    >
                                        <DeleteOutlineOutlinedIcon className='table-icon' />
                                    </FeedbackButton>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col className="pt-3">
                    <Formik
                        initialValues={{venueEvent: ""}}
                        onSubmit={onSubmitVenueEventAdd}
                    >
                        {(subformik) => {
                            return (
                                <FormVenueEventsAdd
                                    formik={formik}
                                    subformik={subformik}
                                    metaEventStatus={metaEventStatus}
                                />
                            )
                        }}
                    </Formik>
                </Col>
            </Row>

            <Modal show={showDeleteConfirmDialog} onHide={handleCancelDelete}>
                <Modal.Header closeButton>
                    <Modal.Title>Platzpool löschen</Modal.Title>
                </Modal.Header>
                <Modal.Body>Möchten Sie die Zuordnung wirklich entfernen?</Modal.Body>
                <Modal.Footer>
                    <FeedbackButton variant="outlined" onClick={handleCancelDelete}>
                        Abbrechen
                    </FeedbackButton>
                    <FeedbackButton onClick={handleConfirmDelete}>
                        Entfernen
                    </FeedbackButton>
                </Modal.Footer>
            </Modal>
        </>
    )
}

FormVenueEvents.propTypes = {
    formik: PropTypes.object,
    metaEventStatus: PropTypes.object,
    loadMetaEventStatus: PropTypes.func,
};

export default FormVenueEvents;
