import React, {useState} from 'react';
import * as PropTypes from 'prop-types';
import {Formik} from "formik";
import * as yup from "yup";
import FormPlacePoolsTypeAhead from "./FormPlacePoolsTypeAhead";
import FeedbackButton from '../../../common/FeedbackButton';
import Modal from "react-bootstrap/Modal";
import Api from "../../../../api";
import ConfirmModal from "../../../common/modal/ConfirmModal";
import HelpContent from "../../../common/help/HelpContent";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const api = new Api();

const FormPlacePools = ({onSubSubmit, formik}) => {

    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
    const [placePoolToDelete, setPlacePoolToDelete] = useState(undefined);

    const getSubformInitialValues = () => ({
        // if it's a seating block, init it with 1 so the validation doesn't break
        capacityPlacePool: formik.values.blockType === "standing" ? 0 : 1,
        placePool: {id: "", name: ''}
    });

    const SUBFORM_SCHEMA = yup.object().shape({
        capacityPlacePool: yup.number().min(1),
        placePool: yup.object().nullable().required()
            .test('unique', 'Der Platzpool ist bereits zugeordnet', pool => !formik.values.placePools.find(p => p.placePool.name === pool.name))
            .test('empty', 'Der Platzpool wurde nicht ausgewählt', pool => pool.name !== '')
    });

    const deletePlacePoolFromOld = (placePoolIndex) => {
        let pools = formik.values.usedPlacePools.filter((placePool, index) => {
            return (index !== placePoolIndex)
        });
        formik.setFieldValue("usedPlacePools", pools);

        if (formik.values.blockType === "seating") {
            api.removePlacePoolFromAllSeatsOfBlock(formik.values.id, formik.values.usedPlacePools[placePoolIndex].placePool.id);
        }
    };

    const deletePlacePoolFromAdd = (placePoolIndex) => {
        formik.setFieldValue("placePools", formik.values.placePools.filter((placePool, index) => {
            return (index !== placePoolIndex)
        }));
    };

    const showDeleteConfirmingDialog = (index) => {
        setShowDeleteConfirmDialog(true);
        setPlacePoolToDelete(index);
    };

    const handleCancelDelete = () => {
        setShowDeleteConfirmDialog(false);
    };

    const handleConfirmDelete = () => {
        setShowDeleteConfirmDialog(false);
        deletePlacePoolFromOld(placePoolToDelete);
    };

    return (
        <>
            <h3>Vorhandene Platzpools</h3>
            <HelpContent as="p" className="text-muted">Diese Platzpools sind bei mindestens einem Platz in diesem Block hinterlegt.</HelpContent>

            <table className="table table-striped">
                <thead>
                <tr>
                    <th>Platzpool</th>
                    <th className="text-center">Anzahl Plätze</th>
                    <th className="text-center">Zuordnung entfernen</th>
                </tr>
                </thead>
                <tbody>
                {
                    formik.values.usedPlacePools.map((pool, index) =>
                        <tr key={index}>
                            <td data-testid="name_placePool">{pool.placePool.name}</td>
                            <td className="text-center" data-testid="capacity_placePool">{pool.capacity}</td>
                            <td className="text-center">
                                <FeedbackButton
                                    title="Zuordnung entfernen"
                                    className="list-link link-btn"
                                    onClick={() => showDeleteConfirmingDialog(index)}
                                >
                                    <DeleteOutlineOutlinedIcon className='table-icon' />
                                </FeedbackButton>
                            </td>
                        </tr>
                    )

                }
                </tbody>
            </table>

            <h3>Neue Platzpools hinzufügen</h3>
            <HelpContent as="p" className="text-muted">Diese Platzpools werden beim Speichern allen Plätzen des Blocks hinzugefügt.</HelpContent>
            <table className="table table-striped">
                <thead>
                <tr>
                    <th>Platzpool</th>
                    <th className="text-center">Zuordnung entfernen</th>
                </tr>
                </thead>
                <tbody>
                {
                    formik.values.placePools.map((pool, index) =>
                        <tr key={index}>
                            <td data-testid="name_placePool">{pool.placePool.name}</td>
                            <td className="text-center">
                                <ConfirmModal title="Platzpool löschen"
                                              body={`Möchten Sie die Zuordnung wirklich entfernen?`}
                                              cancelLabel="Abbrechen"
                                              confirmLabel="Entfernen"
                                >
                                    {confirm =>
                                        <FeedbackButton
                                            title="Zuordnung entfernen"
                                            className="list-link link-btn"
                                            onClick={() => confirm(() => {
                                                deletePlacePoolFromAdd(index);
                                            })}
                                        >
                                            <DeleteOutlineOutlinedIcon className='table-icon' />
                                        </FeedbackButton>
                                    }
                                </ConfirmModal>
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </table>
            <Formik initialValues={{...getSubformInitialValues()}}
                    validationSchema={SUBFORM_SCHEMA}
                    onSubmit={onSubSubmit}
            >
                {(subformik) => {
                    const validateCapacityPlacePool = (capacityPlacePool) => {
                        if (formik.values.blockType === "standing") {
                            let previousCapacity = (formik.values.placePools.reduce((a, b) => a + (b["capacity"] || 0), 0));
                            if (previousCapacity + capacityPlacePool > formik.values.capacity &&
                                capacityPlacePool !== "" && capacityPlacePool !== 0) {
                                return 'Die Anzahl der Stehplätze darf nicht überschritten werden.'
                            }
                            if (capacityPlacePool === "" || capacityPlacePool === 0) {
                                return 'Bitte geben Sie eine Anzahl an.'
                            }
                        }
                    };

                    return (
                        <FormPlacePoolsTypeAhead
                            subformik={subformik}
                            validateCapacityPlacePool={validateCapacityPlacePool}
                            blockType={formik.values.blockType}
                        />
                    )
                }}
            </Formik>
            <Modal show={showDeleteConfirmDialog} onHide={handleCancelDelete}>
                <Modal.Header closeButton>
                    <Modal.Title>Platzpool löschen</Modal.Title>
                </Modal.Header>
                <Modal.Body>Möchten Sie die Zuordnung wirklich entfernen? Diese Aktion kann nicht widerrufen werden.</Modal.Body>
                <Modal.Footer>
                    <FeedbackButton variant="outlined" onClick={handleCancelDelete}>
                        Abbrechen
                    </FeedbackButton>
                    <FeedbackButton onClick={handleConfirmDelete}>
                        Entfernen
                    </FeedbackButton>
                </Modal.Footer>
            </Modal>
        </>
    )
}

FormPlacePools.propTypes = {
    onSubSubmit: PropTypes.any,
    formik: PropTypes.any
};

export default FormPlacePools;


