import React, { ReactNode, useState } from "react";
import classnames from "classnames";
import {
    Tab,
    Tabs,
    IconButton,
} from '@mui/material';
import TabsPanel from '../../components/common/TabsPanel/TabsPanel';
import TabPlacePools, { TabPlacepoolChangeFunc } from "./TabPlacePools";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import styles from "./baseLayout.module.scss";
import TabSeatDataForm from "./TabSeatDataForm";
import TabImages from "./TabImages";
import { IImagesManager } from "../editor/display/images/imagesManager";
import TabStatistics from "./TabStatistics";
import TabAreaForms from "./TabAreaForms";
import { AREAFORMS_ENABLED, IAreaFormsManager } from "../editor/display/areaForms/areaFormsManager";
import TabLabels from "./TabLabels";
import { InteractionMode } from "../editor/display/interaction";
import { useSelector } from "react-redux";
import { IState, selectInteractionMode } from "../../state/entities/venueEditor/selectors";


interface IBaseLayoutProps {
    onSelectedSeatsChanged?: TabPlacepoolChangeFunc;
    imagesManager: IImagesManager;
    areaFormsManager: IAreaFormsManager;
    venuePlanId: string;
    onNeededWidthChanged: (width: number) => void;
    children?: ReactNode;
}

const SelectOptions = [
    {id: 0, name: 'Bitte wählen...'},
    {id: 5, name: '5'},
    {id: 10, name: '10'},
]

const BaseLayout: React.FC<IBaseLayoutProps> = ({
    onSelectedSeatsChanged,
    imagesManager,
    areaFormsManager,
    venuePlanId,
    onNeededWidthChanged,
    children
}) => {
    const interactionMode = useSelector((state: IState) => selectInteractionMode(state));
    const [currentTabBlock1, setCurrentTabBlock1] = useState('placeData');
    const [currentTabBlock2, setCurrentTabBlock2] = useState('statistics');
    const [visibilityColor, setVisibilityColor] = useState(false);
    const [expandedLayout, setExpandedLayout] = useState(true);
    const handleToggleLayout = () => {
        setExpandedLayout(!expandedLayout);
    };
    const handleToggleGeometry = () => {
        console.log('handleToggleGeometry');
    };
    const handleChangeExpanse = ( value: string ) => {
        console.log('handleChangeExpanse', value);
    };
    const setGridRealignment = ( value: string ) => {
        console.log('setGridRealignment', value);
    };
    const toggleVisibilityBoxColor = () => {
        setVisibilityColor(!visibilityColor);
    };
    const handleEditColor = () => {
        console.log('handleEditColor');
    };
    const handleDeleteColor = () => {
        console.log('handleDeleteColor');
    };

    onNeededWidthChanged(expandedLayout ? 700 : 64);

    const modeName = 
            [InteractionMode.SELECT, InteractionMode.ADD].includes(interactionMode) ? 'Plätze' :
            [InteractionMode.AREAFORMS, InteractionMode.ADD_AREAFORM].includes(interactionMode) ? 'Stehplätze' :
            [InteractionMode.IMAGES].includes(interactionMode) ? 'Bilder' : '';

    return (
        <div className={classnames(styles.baseLayout, { [styles.baseLayoutExpanded]: expandedLayout })}>
            <IconButton onClick={handleToggleLayout} className={styles.arrowIcon}>
                {expandedLayout ? <ArrowForwardIcon /> : <ArrowBackIcon />}
            </IconButton>
            {expandedLayout && (
                <div className={styles.tabsWrapper}>
                    <div className={styles.tabsWrapper__overflow}>
                        <Tabs value={currentTabBlock1} onChange={(event, newTab) => setCurrentTabBlock1(newTab)}>
                            <Tab value="placeData" label={`Details: ${modeName}`} />
                            <Tab value="placePools" label="Platzpools" />
                            {/* <Tab value="geometry" label="Geometrie" /> */}
                        </Tabs>
                        <TabsPanel value={currentTabBlock1} index={'placePools'}>
                            <TabPlacePools onSelectedSeatsChange={onSelectedSeatsChanged} />
                       </TabsPanel>
                        <TabsPanel value={currentTabBlock1} index={'placeData'}>
                            {
                                modeName === 'Plätze' ? (
                                    <TabSeatDataForm
                                        onSelectedSeatsChange={onSelectedSeatsChanged}
                                        venuePlanId={venuePlanId}
                                    />
                                ) : modeName === 'Stehplätze' ? (
                                    null
                                ) : modeName === 'Bilder' ? (
                                    <TabImages
                                        imagesManager={imagesManager}
                                        venuePlanId={venuePlanId}
                                    />
                                ) : null
                            }
                        </TabsPanel>
{/*                         <TabsPanel value={currentTabBlock1} index={'geometry'}>
                            <div className={styles.tabsContent}>
                                <h2 className={styles.tabsContent__title}>Modus</h2>
                                <div className={styles.tabsContent__buttons}>
                                    <FeedbackButton variant="outlined" onClick={handleToggleGeometry}>
                                        Drehen
                                    </FeedbackButton>
                                    <FeedbackButton variant="outlined" onClick={handleToggleGeometry}>
                                        Scheren
                                    </FeedbackButton>
                                    <FeedbackButton variant="outlined" onClick={handleToggleGeometry}>
                                        Verschieben
                                    </FeedbackButton>
                                </div>
                            </div>
                        </TabsPanel>
 */}
                    </div>
                    <div className={styles.tabsWrapper__overflow}>
                        <Tabs value={currentTabBlock2} onChange={(event, newTab) => setCurrentTabBlock2(newTab)}>
                            <Tab value="statistics" label="Statistik" />
                            <Tab value="labels" label="Labels" />
                            
                            {/* <Tab value="grid" label="Grid" />
                            <Tab value="autoNumbering" label="Autonummerierung" />
                            <Tab value="csvUpload" label="CSV Upload" />
                            <Tab value="text" label="Text" /> */}
                        </Tabs>
                        <div className={styles.tabsContentWrapper}>
                            <TabsPanel value={currentTabBlock2} index='statistics'>
                                <TabStatistics />
                            </TabsPanel>
{/*                             <TabsPanel value={currentTabBlock2} index={'grid'}>
                                <div className={styles.tabsContent}>
                                    <h2 className={styles.tabsContent__title}>Grid</h2>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={<Switch defaultChecked />}
                                        label="Grid: an/aus"
                                    />
                                    <MaterialInput
                                        label='Grid: neu ausrichten'
                                        startAdornment={false}
                                        value={''}
                                        onChange={value => setGridRealignment(value)}
                                    />
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel>Grid: Weite</InputLabel>
                                        <Select
                                            displayEmpty={true}
                                            value={'0'}
                                            renderValue={(selected: string) => {
                                                if (!selected) {
                                                    return <em>Bitte wählen ...</em>;
                                                }
                                                const selectedOption = SelectOptions.find(option => String(option.id) === selected);
                                                return selectedOption ? selectedOption.name : selected;
                                            }}
                                            onChange={(event) => {
                                                handleChangeExpanse(event.target.value as string);
                                            }}
                                        >
                                            {SelectOptions.map(({ name, id }) => {
                                                return (
                                                    <MenuItem key={id} value={id}>
                                                        {name}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                            </TabsPanel>
 */}
                            <TabsPanel value={currentTabBlock2} index='labels'>
                                <TabLabels venuePlanId={venuePlanId} />
                            </TabsPanel>

{/*                             <TabsPanel value={currentTabBlock2} index={'autoNumbering'}>
                                <div className={styles.tabsContent}>
                                    <h2 className={styles.tabsContent__title}>Autonummerierung</h2>
                                </div>
                            </TabsPanel>
 */}
{/*                             <TabsPanel value={currentTabBlock2} index={'csvUpload'}>
                                <div className={styles.tabsContent}>
                                    <h2 className={styles.tabsContent__title}>CSV Upload</h2>
                                </div>
                            </TabsPanel>
                            <TabsPanel value={currentTabBlock2} index={'standingBlock'}>
                                <TabAreaForms areaFormsManager={areaFormsManager}/>
                            </TabsPanel>
 */}

{/*                             <TabsPanel value={currentTabBlock2} index={'text'}>
                                <div className={styles.tabsContent}>
                                    <h2 className={styles.tabsContent__title}>Text</h2>
                                </div>
                            </TabsPanel>
 */}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BaseLayout;