import React, { useState, useRef } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, ToggleButton, ToggleButtonGroup } from '@mui/material';
import NearMeIcon from '@mui/icons-material/NearMe';
import UndoIcon from '@mui/icons-material/Undo';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Grid4x4Icon from '@mui/icons-material/Grid4x4';
import { Tab, Tabs } from '@mui/material';
import TabsPanel from '../../components/common/TabsPanel/TabsPanel';
import styles from './toolBar.module.scss';
import { InteractionMode } from '../editor/display/interaction';
import { useDispatch, useSelector } from 'react-redux';
import {actions as venueEditorActions} from '../../state/entities/venueEditor';
import { IState, selectInteractionMode, selectMoveOnGrid, selectSelectedSeatIds } from '../../state/entities/venueEditor/selectors';
import { Button } from 'react-bootstrap';
import { AREAFORMS_ENABLED, IAreaFormsManager } from '../editor/display/areaForms/areaFormsManager';
import { IImagesManager } from '../editor/display/images/imagesManager';
import ListingModal from "../../components/common/modal/ListingModal";
import { ImageData } from "../editor/display/images/ImageData";
import FieldImage, { FieldValuesProps } from '../../components/common/FieldImage/FieldImage';
import { convertImage2ImageData, convertImageData2Image } from "../../state/entities/venueEditor/saga";
import { isEmpty } from 'lodash';
import Api from "../../api";


const api = new Api();

export const switchToSerialClient = () => {
    api.switchToSerialClient();
}


enum TabState {
    SEATS = 'seats',
    STANDINGBLOCKS = 'standingBlocks',
    IMAGES = 'images',
    NONE = ''
}


const subToolbarStyles = {
    display: 'flex',
    alignItems: 'flex-start',
    width: 'fit-content',
    height: 'auto',
    borderRadius: 1,
    boxShadow: 3,
    bgcolor: 'background.paper',
};

const iconButtonStyles = {
    width: 32,
    height: 32,
    '& svg': {fontSize: 18, color: '#1D1D1F'},
    '&.Mui-disabled': {
        '& svg': {color: 'action.disabled'}
    }
};


interface IToolbarUtilsProps {
    venuePlanId: string;
    areaFormsManager: IAreaFormsManager;
    imagesManager: IImagesManager;
    onSeatsDelete: () => void;
}


export const ToolbarUtils: React.FC<IToolbarUtilsProps> = ({venuePlanId, areaFormsManager, imagesManager, onSeatsDelete}) => {
    const dispatch = useDispatch();
    const interactionMode = useSelector((state: IState) => selectInteractionMode(state));
    const moveOnGrid = useSelector((state: IState) => selectMoveOnGrid(state));
    const [hoveredTab, setHoveredTab] = useState(TabState.NONE);
    const selectedSeatIds = useSelector((state: IState) => selectSelectedSeatIds(state));
    const tabPanelRef = useRef(null);
    const [deleteSeatsDialogShowing, setDeleteSeatsDialogShowing] = useState(false);
    const [isShowBatchModal, setIsShowBatchModal] = useState(false);
    const [activeFieldName, setActiveFieldName] = useState('');
    const [imageFields, setImageFields] = useState([]);

    const handleTabHover = (event: React.MouseEvent<HTMLButtonElement>, tabValue: TabState) => {
        const target = event.target as HTMLButtonElement;
        if (target.classList.contains('Mui-selected')) {
            handleTabLeave();  //for moving from hovered button to currently active button
            return;  
        }
        setHoveredTab(tabValue);
    };

    const handleTabLeave = () => {
        setHoveredTab(TabState.NONE);
    };

    const getActiveTab = (): TabState => {
        if ([InteractionMode.SELECT, InteractionMode.ADD].includes(interactionMode)) return TabState.SEATS;
        if ([InteractionMode.AREAFORMS, InteractionMode.ADD_AREAFORM].includes(interactionMode)) return TabState.STANDINGBLOCKS;
        if ([InteractionMode.IMAGES].includes(interactionMode)) return TabState.IMAGES;
    }


    const handleChange = (event: React.MouseEvent<HTMLElement>, newInteractionMode: InteractionMode) => {
        if (newInteractionMode === null) return;
        dispatch(venueEditorActions.setInteractionMode(newInteractionMode))
        return;
    }

    const handleMainTabClick = (event, value: string) => {
        const defaultTabModes = {
            [TabState.SEATS]: InteractionMode.SELECT,
            [TabState.STANDINGBLOCKS]: InteractionMode.AREAFORMS,
            [TabState.IMAGES]: InteractionMode.IMAGES
        };
        dispatch(venueEditorActions.setInteractionMode(defaultTabModes[value]));
    }

    const handleDeleteSeatsClick = () => {
        if (!Object.values(selectedSeatIds).length) return;
        setDeleteSeatsDialogShowing(true);
    }

    const handleDeletePlaces = () => {
        setDeleteSeatsDialogShowing(false);
        onSeatsDelete();
    }

    const handleMoveOnGrid = () => {
        dispatch(venueEditorActions.setMoveOnGrid(!moveOnGrid));
    }

    const onHandleChooseImage = (item: FieldValuesProps) => {
        setIsShowBatchModal(false);
        imagesManager.prebuildNewImage(item.id, item.title, item.url).then((image: ImageData) => {
            const imageRequestData = convertImage2ImageData(image);
            imageRequestData.venuePlanId = venuePlanId;
            api.addImageToVenuePlan(imageRequestData).then((newImageData) => {
                const newImage = convertImageData2Image(newImageData);
                imagesManager.addItem(newImage);
            });
        });

        setActiveFieldName(item.id)
    }

    const onHandleShowModal = (e: React.MouseEvent<HTMLButtonElement>, fieldName: string) => {
        e.stopPropagation();
        setIsShowBatchModal(true);
        setActiveFieldName(fieldName);
    }

    const onHandleRemove = (e: React.MouseEvent<HTMLButtonElement>, fieldName: string) => {
        e.stopPropagation();
        setImageFields(prevState => prevState.filter(item => item.id !== fieldName));
    }

    const handleAddImageClick = () => {
        dispatch(venueEditorActions.setInteractionMode(InteractionMode.IMAGES));
        setIsShowBatchModal(true);
    }
    

    const deleteSeatsDisabled = getActiveTab() !== TabState.SEATS || !Object.values(selectedSeatIds).length

    return (
        <>
            <div className={styles.toolBarUtilsContainer} onMouseLeave={handleTabLeave}>
                <Tabs
                    value={getActiveTab()}
                    onChange={handleMainTabClick}
                    className={styles.tabsToolbar}
                >
                    <Tab
                        component="button"
                        value="seats"
                        label="Sitzplätze"
                        onMouseEnter={(e) => handleTabHover(e, TabState.SEATS)} 
                        disableRipple
                    />
                    <Tab
                        component="button"
                        value="standingBlocks"
                        label="Stehplatzblöcke"
                        onMouseEnter={(e) => handleTabHover(e, TabState.STANDINGBLOCKS)}
                        disabled={!AREAFORMS_ENABLED}
                        disableRipple
                    />
                    <Tab
                        component="button"
                        value="images"
                        label="Grafiken"
                        onMouseEnter={(e) => handleTabHover(e, TabState.IMAGES)}
                        disableRipple
                    />
                </Tabs>
                <TabsPanel
                    value={getActiveTab()}
                    index={TabState.SEATS}
                    isHovered={hoveredTab === 'seats'}
                    tabPanelRef={tabPanelRef}
                >
                    <Box sx={subToolbarStyles}>
                        <ToggleButtonGroup
                            color="primary"
                            value={interactionMode}
                            onChange={(e, newIntercationMode) => handleChange(e, newIntercationMode)}
                            className={styles.toolBarUtilsBtnGroup}
                            exclusive
                            sx={{boxShadow: 0}}
                        >
                            <ToggleButton value={InteractionMode.SELECT}>
                                <NearMeIcon />
                            </ToggleButton>
                            <ToggleButton value={InteractionMode.ADD}>
                                <AddIcon />
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <IconButton value="delete" disabled={deleteSeatsDisabled} sx={iconButtonStyles}>
                            <DeleteOutlineOutlinedIcon onClick={handleDeleteSeatsClick} />
                        </IconButton>
                        <Divider orientation="vertical" flexItem />
                        <ToggleButton
                            value="MOVE_ON_GRID"
                            selected={moveOnGrid}
                            className={styles.toolBarUtilsBtnGroup__button}
                            onClick={handleMoveOnGrid}
                            disabled={getActiveTab() !== TabState.SEATS}
                            sx={{...iconButtonStyles, ...(moveOnGrid && {
                                bgcolor: '#9FD8F9'
                            })}}
                        >
                            <Grid4x4Icon/>
                        </ToggleButton>
                    </Box>
                </TabsPanel>
                <TabsPanel
                    value={getActiveTab()}
                    index={TabState.STANDINGBLOCKS}
                    isHovered={hoveredTab === 'standingBlocks'}
                    tabPanelRef={tabPanelRef}
                >
                    <Box sx={subToolbarStyles}>
                        <ToggleButtonGroup
                            color="primary"
                            value={interactionMode}
                            onChange={(e, newAlignment) => handleChange(e, newAlignment)}
                            className={styles.toolBarUtilsBtnGroup}
                            exclusive
                            sx={{boxShadow: 0}}
                        >
                            <ToggleButton value={InteractionMode.AREAFORMS}>
                                <NearMeIcon />
                            </ToggleButton>
                            <ToggleButton
                                value={InteractionMode.ADD_AREAFORM}
                            >
                                <AddIcon />
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <IconButton
                            value="delete"
                            disabled={getActiveTab() !== TabState.STANDINGBLOCKS}
                            onClick={() => areaFormsManager.deleteSelectedItem()}
                            sx={iconButtonStyles}
                        >
                            <DeleteOutlineOutlinedIcon/>
                        </IconButton>
                    </Box>
                </TabsPanel>
                <TabsPanel
                    value={getActiveTab()}
                    index={TabState.IMAGES}
                    isHovered={hoveredTab === 'images'}
                    tabPanelRef={tabPanelRef}
                >
                    <Box sx={subToolbarStyles}>
                        <ToggleButtonGroup
                            color="primary"
                            value={interactionMode}
                            onChange={(e, newAlignment) => handleChange(e, newAlignment)}
                            className={styles.toolBarUtilsBtnGroup}
                            exclusive
                            sx={{boxShadow: 0}}
                        >
                            <ToggleButton value={InteractionMode.IMAGES}>
                                <NearMeIcon />
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <IconButton
                            value="add"
                            sx={iconButtonStyles}
                            onClick={handleAddImageClick}
                        >
                            <AddIcon/>
                        </IconButton>
                        <IconButton
                            value="delete"
                            disabled={getActiveTab() !== TabState.IMAGES}
                            sx={iconButtonStyles}
                            onClick={() => imagesManager.deleteSelectedItem()}
                        >
                            <DeleteOutlineOutlinedIcon/>
                        </IconButton>
                    </Box>
                </TabsPanel>
                {!isEmpty(imageFields) && imageFields.map((item) => {
                    return (
                        <div key={item.id}>
                            <FieldImage
                                values={item}
                                fieldName={activeFieldName}
                                label={`Label Image ${item.title}`}
                                onHandleShowModal={(e) => onHandleShowModal(e, item.id)}
                                onHanldleRemove={(e) => onHandleRemove(e, item.id)}
                            />
                        </div>
                    );
                })}
            </div>
            <Dialog open={deleteSeatsDialogShowing} onClose={() => setDeleteSeatsDialogShowing(false)}>
                <DialogTitle>Bestätigung</DialogTitle>
                <DialogContent>
                    <DialogContentText>Die ausgewählten Sitze wirklich löschen?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeletePlaces} size="sm" autoFocus>OK</Button>
                    <Button onClick={() => setDeleteSeatsDialogShowing(false)} size="sm" variant="outlined">Abbrechen</Button>
                </DialogActions>
            </Dialog>
            <ListingModal
                show={isShowBatchModal}
                title="Bild wählen"
                onCancel={() => setIsShowBatchModal(false)}
                onHandleChooseImage={onHandleChooseImage}
            />
        </>
    )
}

export default ToolbarUtils;