import React, { useState } from 'react';
import * as yup from 'yup';
import { Formik, getIn } from 'formik';
import { Col, Row } from 'react-bootstrap';
import { FormikTextInputGroup } from '../common/formik/FormikTextInputGroup';
import * as PropTypes from 'prop-types';
import FeedbackButton from '../common/FeedbackButton';
import Footer from "../layout/Footer";
import { connect } from "react-redux";
import FormikAsyncTypeaheadInput from "../common/formik/FormikAsyncTypeaheadInput";
import Api from "../../api";
import FormikSelect from "../common/formik/FormikSelect";
import MuiAutocomplete from '../common/MuiAutocomplete/MuiAutocomplete';
import ListingModal from "../common/modal/ListingModal";
import FieldImage from '../common/FieldImage/FieldImage';
import Events from "./Events";
import styles from "../form.module.scss";
import { COUNTRIES } from "../../utils/countries";
import { COUNTRY_CODE } from "../../utils/countryCode";
import { get } from 'lodash';

const INITIAL_VALUES = {
    name: '',
    ident: '',
    eventCategories: [],
    description: {
        de: '',
        en: ''
    },
    tags: {
        de: [],
        en: []
    },
    logo: null,
    legalContact: {
        salutation: null,
        firstName: null,
        lastName: null,
        address1: null,
        address2: null,
        companyName: null,
        postalCode: null,
        city: null,
        phone: {
            countryCode: null,
            phoneNumber: null
        },
        mobile: {
            countryCode: null,
            phoneNumber: null
        },
        country: 'DE',
        email: null
    },
};

const SALUTATION_OPTIONS = [
    { id: 'm', name: 'Herr' },
    { id: 'f', name: 'Frau' },
    { id: 'd', name: 'Divers' },
]

const FORM_SCHEMA = yup.object().shape({
    name: yup.string().required(),
    ident: yup.string().required(),
    eventCategories: yup.array().default([]),
    description: yup.object().shape({
        de: yup.string(),
        en: yup.string()
    }).nullable(),
    tags: yup.object().shape({
        de: yup.array().of(yup.string()).nullable().default([]),
        en: yup.array().of(yup.string()).nullable().default([]),
    }).nullable(),
    logo: yup.object().shape({
        id: yup.string(),
        url: yup.string().url(),
        title: yup.string(),
    }).nullable(),
    legalContact: yup.object().shape({
        salutation: yup.string().required(),
        firstName: yup.string().required().max(255),
        lastName: yup.string().required().max(255),
        address1: yup.string().required().max(255),
        address2: yup.string().max(255),
        companyName: yup.string().max(255),
        postalCode: yup.string().required().max(6),
        city: yup.string().required().max(48),
        country: yup.string().required(),
        phone: yup.object().shape({
            countryCode: yup.string().required(),
            phoneNumber: yup.string().matches(/^\d{1,12}$/, 'Invalid phone number').required()
        }),
        mobile: yup.object().shape({
            countryCode: yup.string().nullable(),
            phoneNumber: yup.string().matches(/^\d{1,12}$/, 'Invalid phone number').nullable()
        }),
        email: yup.string().email('Invalid email').required('Email is required')
    }),
});

const api = new Api();

const Form = ({ onSubmit, submitPending, artist, helpTextsVisible }) => {
    const [activeFieldName, setActiveFieldName] = useState('');
    const [isShowBatchModal, setIsShowBatchModal] = useState(false);

    const closeModal = () => {
        setIsShowBatchModal(false);
    }

    const onHandleShowModal = (e, fieldName) => {
        e.stopPropagation();
        setIsShowBatchModal(true);
        setActiveFieldName(fieldName);
    }

    const handleSubmit = (values, actions) => {
        const getLegalContact = values.legalContact;
        onSubmit({ ...values, invoiceContact: getLegalContact }, actions);
    }

    return <Formik
        initialValues={{ ...INITIAL_VALUES, ...artist }}
        validationSchema={FORM_SCHEMA}
        onSubmit={handleSubmit}
    >
        {formik => {
            const onHanldleRemove = (e, fieldName) => {
                e.stopPropagation();
                formik.setFieldValue(fieldName, null);
            }
            const onHandleChooseImage = (item) => {
                const { id, title, url } = item;
                formik.setFieldValue(activeFieldName, {
                    title,
                    url,
                    id,
                });
                setIsShowBatchModal(false);
            }

            const getValueCountryCode = ( fieldname ) => ({
                name: get(formik.values, `legalContact.${fieldname}.countryCode`),
                id: get(formik.values, `legalContact.${fieldname}.countryCode`)
            });
            return (
                <>
                    <form onSubmit={formik.handleSubmit} className={styles.formCotainer}>
                        <div className={styles.formBox}>
                            <h2 className={styles.formTitle}>Allgemein</h2>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <FormikTextInputGroup label="Ident*" name="ident" testid="ident" />
                                </Col>
                                <Col className="col-md-4">
                                    <FormikTextInputGroup label="Künstlername*" name="name" testid="name" />
                                </Col>
                                <Col className="col-md-4">
                                    <FieldImage
                                        values={getIn(formik.values, 'logo')}
                                        label="Logo/ Bild"
                                        fieldName='logo'
                                        onHandleShowModal={onHandleShowModal}
                                        onHanldleRemove={onHanldleRemove}
                                        helpText="Wird im Eventlisting ausgeliefert"
                                    />
                                </Col>
                                <Col className="col-md-4">
                                    <FormikTextInputGroup
                                        type="textarea"
                                        label="Zusatztext (deutsch)"
                                        name="description.de"
                                        helpTextsVisible={helpTextsVisible}
                                        helpText="Beschreibungstext zu einem Künstler ist im Eventlisting enthalten."
                                    />
                                </Col>
                                <Col className="col-md-4">
                                    <FormikTextInputGroup
                                        type="textarea"
                                        label="Zusatztext (englisch)"
                                        name="description.en"
                                        helpTextsVisible={helpTextsVisible}
                                        helpText="Beschreibungstext zu einem Künstler ist im Eventlisting enthalten."
                                    />
                                </Col>
                                <Col className="col-md-4">
                                    <FormikAsyncTypeaheadInput
                                        id="eventCategories"
                                        label="Eventarten"
                                        onSearch={query => api.getEventCategoriesByQuery(query)}
                                        labelKey={o => o.name.de}
                                        minLength={0}
                                        multiple={true}
                                        helpText=""
                                    />
                                </Col>
                                <Col className="col-md-4">
                                    <FormikAsyncTypeaheadInput
                                        id="tags.de"
                                        label="Künstlertag (deutsch)"
                                        onSearch={() => api.getTagsForArtistByLang('de')}
                                        minLength={0}
                                        testid="tags.de"
                                        multiple={true}
                                        arrayOfStrings={true}
                                        allowNew={true}
                                        helpText="Tags, nach denen gesucht werden kann, um diesen Künstler zu finden."
                                    />
                                </Col>
                                <Col className="col-md-4">
                                    <FormikAsyncTypeaheadInput
                                        id="tags.en"
                                        label="Künstlertag (englisch)"
                                        onSearch={() => api.getTagsForArtistByLang('en')}
                                        minLength={0}
                                        testid="tags.en"
                                        multiple={true}
                                        arrayOfStrings={true}
                                        allowNew={true}
                                        helpText="Tags, nach denen gesucht werden kann, um diesen Künstler zu finden."
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className={styles.formBox}>
                            <h2 className={styles.formTitle}>Vertragsadresse</h2>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <FormikSelect
                                        label="Anrede*"
                                        options={SALUTATION_OPTIONS}
                                        name={`legalContact.salutation`}
                                        isShowEmptyValue={false}
                                        emptyValueLabel="Bitte wählen..."
                                    />
                                </Col>
                                <Col className="col-md-4">
                                    <FormikTextInputGroup
                                        label="Nachname*"
                                        name={`legalContact.lastName`}
                                    />
                                </Col>
                                <Col className="col-md-4">
                                    <FormikTextInputGroup
                                        label="Vorname*"
                                        name={`legalContact.firstName`}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <FormikTextInputGroup
                                        label="Straße & Hausnummer*"
                                        name={`legalContact.address1`}
                                    />
                                </Col>
                                <Col className="col-md-4">
                                    <FormikTextInputGroup
                                        label="Adresszusatz"
                                        name={`legalContact.address2`}
                                    />
                                </Col>
                                <Col className="col-md-4">
                                    <FormikTextInputGroup
                                        label="Firma"
                                        name={`legalContact.companyName`}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <FormikTextInputGroup
                                        label="PLZ*"
                                        name={`legalContact.postalCode`}
                                    />
                                </Col>
                                <Col className="col-md-4">
                                    <FormikTextInputGroup
                                        label="Stadt*"
                                        name={`legalContact.city`}
                                    />
                                </Col>
                                <Col className="col-md-4">
                                    <FormikSelect
                                        label="Land*"
                                        options={COUNTRIES}
                                        name={`legalContact.country`}
                                        isShowEmptyValue={false}
                                        emptyValueLabel="Bitte wählen..."
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <Row>
                                        <Col className="col-md-3">
                                            <MuiAutocomplete
                                                label='Country code*'
                                                prefix={'+'}
                                                options={COUNTRY_CODE}
                                                value={getValueCountryCode('phone')}
                                                error={get(formik.errors.legalContact, 'phone.countryCode')}
                                                onChange={(_, { id }) => formik.setFieldValue(`legalContact.phone.countryCode`, id)}
                                            />
                                        </Col>
                                        <Col className="col-md-9">
                                            <FormikTextInputGroup
                                                label="Telefon*"
                                                name={`legalContact.phone.phoneNumber`}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="col-md-4">
                                    <Row>
                                        <Col className="col-md-3">
                                            <MuiAutocomplete
                                                label='Country code'
                                                prefix={'+'}
                                                options={COUNTRY_CODE}
                                                value={getValueCountryCode('mobile')}
                                                error={get(formik.errors.legalContact, 'mobile.countryCode')}
                                                onChange={(_, { id }) => formik.setFieldValue(`legalContact.mobile.countryCode`, id)}
                                            />
                                        </Col>
                                        <Col className="col-md-9">
                                            <FormikTextInputGroup
                                                label="Mobil"
                                                name={`legalContact.mobile.phoneNumber`}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="col-md-4">
                                    <FormikTextInputGroup
                                        label="E-Mail*"
                                        name={`legalContact.email`}
                                    />
                                </Col>
                            </Row>
                        </div>

                        {!!artist.id && <Events artistId={artist.id} />}

                        <Footer>
                            <FeedbackButton to={`/base/artist`}>
                                Abbrechen
                            </FeedbackButton>
                            <FeedbackButton type="submit"
                                busy={submitPending}>
                                Speichern
                            </FeedbackButton>
                        </Footer>
                    </form>
                    <ListingModal
                        show={isShowBatchModal}
                        title="Bild wählen"
                        initialValues={INITIAL_VALUES}
                        validationSchema={FORM_SCHEMA}
                        onCancel={closeModal}
                        onHandleChooseImage={onHandleChooseImage}
                    />
                </>
            )
        }}
    </Formik>
}

Form.propTypes = {
    onSubmit: PropTypes.any,
    submitPending: PropTypes.any,
    artist: PropTypes.any,
    requestErrors: PropTypes.any
};


const mapStateToProps = (state, props) => {

    const helpTextsVisible = state.helpTextsToggle;

    return {
        helpTextsVisible
    }
};

export default connect(mapStateToProps)(Form);
