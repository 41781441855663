import React, {Component} from 'react';
import Form from "./Form";
import * as block from "../../../../state/entities/block";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";
import * as venuePlan from "../../../../state/entities/venuePlan";
import {LoadingIndicator} from "../../../common/LoadingIndicator";
import Breadcrumbs from "../../../common/breadcrumbs/Breadcrumbs";


class Create extends Component {

    componentDidMount() {
        this.props.loadVenuePlan(this.props.venuePlanId);
    }

    onSubmit = (block, {setErrors}) => {
        if(block.label.en === "" && block.label.de !== ""){
            block.label.en = block.label.de;
        }
        this.props.createBlock(block, setErrors);
    };

    render() {
        const {venuePlan, venuePlanLoading, venuePlanId} = this.props;
        if (venuePlanLoading || !venuePlan) {
            return <LoadingIndicator/>;
        }
        if(this.props.isSeating){
            return <>
                <Row className="my-4">
                    <Breadcrumbs entity={["Spielstätte", "Saalplan", "Blockgruppe", "Block"]} endpoints = {['venue/breadcrumb', `venue/${venuePlan.venue.id}/venue_plan/breadcrumb`, `venue_plan/${venuePlan.id}/block_group/breadcrumb`, `venue_plan/${venuePlan.id}/block/breadcrumb`]} title = {["Blockgruppen", "Blöcke"]} links={['/base/venue/', `/base/venue/${venuePlan.venue.id}/venue-plan/`, `/base/venue-plan/${venuePlan.id}/block-group/`, `/base/venue-plan/${venuePlan.id}/block/`]} active={[venuePlan.venue.id, venuePlan.id]} />
                </Row>
                <Row className="mb-4 mt-4">
                    <Col className="pt-3 col-sm-8">
                        <h1>Erstellen eines neuen Sitzplatzblocks für den Saalplan {venuePlan.name}</h1>
                    </Col>
                </Row>
                <Form block={{}} onSubmit={this.onSubmit} submitPending={this.props.createPending} type="seating"  venuePlanId={venuePlanId} venue={venuePlan.venue} />
            </>;
        }else{
            return <>
                <Row className="my-4">
                    <Breadcrumbs entity={["Spielstätte", "Saalplan", "Blockgruppe", "Block"]} endpoints = {['venue/breadcrumb', `venue/${venuePlan.venue.id}/venue_plan/breadcrumb`, `venue_plan/${venuePlan.id}/block_group/breadcrumb`, `venue_plan/${venuePlan.id}/block/breadcrumb`]} title = {["Blockgruppen", "Blöcke"]} links={['/base/venue/', `/base/venue/${venuePlan.venue.id}/venue-plan/`, `/base/venue-plan/${venuePlan.id}/block-group/`, `/base/venue-plan/${venuePlan.id}/block/`]} active={[venuePlan.venue.id, venuePlan.id]} />
                </Row>
                <Row className="mb-4 mt-4">
                    <Col className="pt-3 col-sm-8">
                        <h1>Erstellen eines neuen Stehplatzblocks für den Saalplan {venuePlan.name}</h1>
                    </Col>
                </Row>
                <Form block={{}} onSubmit={this.onSubmit} submitPending={this.props.createPending} type="standing"  venuePlanId={venuePlanId} venue={venuePlan.venue} />
            </>;
        }

    }
}

const mapStateToProps = (state, props) => {
    const venuePlanId = props.match.params.vpid;
    return {
        venuePlanId,
        venuePlan: state.entities.venuePlan.byId[venuePlanId],
        venuePlanLoading: state.entities.venuePlan.loading[venuePlanId],
        createPending: state.entities.block.createPending
    }

};

const mapDispatchToProps = dispatch => {
    return {
        createBlock: entity => dispatch(block.actions.create({entity})),
        loadVenuePlan: id => dispatch(venuePlan.actions.load({id})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
