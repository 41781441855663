import React, {useEffect, useState, useRef} from 'react';
import {Dropdown} from 'react-bootstrap';
import {defaults} from "lodash";
import {queryBreadcrumbs} from "../../../state/entities/breadcrumbs/actions";
import {connect} from "react-redux";
import {LoadingIndicator} from "../LoadingIndicator";
import DropdownToggle from './dropdown/DropdownToggle';
import DropdownItem from './dropdown/DropdownItem';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import FeedbackButton from "../FeedbackButton";
import copy from "copy-to-clipboard";
import {isEmpty} from "lodash";

export const getFilter = (value, fieldName, filter, rowFilter, seatFilter) => {
    // if seats are searched, the search has to include both row and seat fields
    if(fieldName === 'rowLabel'){
        return `?row=${value}&seat=${seatFilter}`;
    }
    else if(fieldName === 'seatLabel'){
        return `?row=${rowFilter}&seat=${value}`;
    }
    else {
        return (value === '' ? '' : `?s=${value}`);
    }
};

/**
 * Show breadcrumbs on each page
 */
const Breadcrumbs = ({crumbs, entity, endpoints, queryBreadcrumbsAll, title, links, active, onFilterChange}) => {

    useEffect(() => {
        queryBreadcrumbsAll();
        // We want this process to happen only once at "on-mount", therefore we use [] as dependency.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // set filter and index for search
    const [filter, setFilter] = useState('')
    const [rowFilter, setRowFilter] = useState('')
    const [seatFilter, setSeatFilter] = useState('')
    const [index, setIndex] = useState("")

    // set ref so the following useEffect doesn't run at first mount (since the method isn't initialized on mount)
    const didMountRef = useRef(false);
    // call onFilterChange after filter and index are set (useState works asynchronous)
    useEffect(() => {
        if (didMountRef.current && filter !== '' && index !== '') {
            onFilterChange(filter, index);
        } else {
            didMountRef.current = true;
            queryBreadcrumbsAll()
        }
    }, [filter, index]);


    if (!crumbs || crumbs.includes(undefined)) {
        return <LoadingIndicator/>;
    }

    // get items for breadcrumbs
    const getItems = (items, index) => {
        return (items.map(item => {
            return {
                id: item.id,
                // name of displayed field
                name: item.label,
                // if item is active, it should appear as heading of breadcrumb
                active: active[index] === item.id
            }
        }))
    }

    // create a new array of entities out of the result from breadcrumb-endpoint with the needed props
    const breadcrumbs = crumbs.map((bc, index) => (
        {
            link: `${process.env.PUBLIC_URL}${links[index]}`,
            request: endpoints[index],
            items: getItems(bc.items, index)
        }
    ));

    const handleFilterChange = (event, fieldName, index) => {
        if(fieldName === 'rowLabel'){
            setRowFilter(event.target.value);
        }
        if(fieldName === 'seatLabel'){
            setSeatFilter(event.target.value);
        }
        setFilter(getFilter(event.target.value, fieldName, filter, rowFilter, seatFilter));
        setIndex(index)
    }

    return (
        <>
            {
                breadcrumbs.map((option, index) =>
                <div className="breadcrumb-item" key={index}>
                    <Dropdown>
                        <DropdownToggle className="" option={option} title={title} index={index} breadcrumbs={breadcrumbs} />
                        <Dropdown.Menu className="dropdown-menu-list breadcrumb-dropdown">
                            <DropdownItem option={option} entity={entity} index={index} crumbs={crumbs} handleFilterChange={handleFilterChange} />
                        </Dropdown.Menu>
                    </Dropdown>
                    {
                        index === breadcrumbs.length - 1
                        && !isEmpty(active[index])
                        && active[index] != 0
                            ? <div className="id-to-clipboard">
                                <div className="id-to-clipboard-text"> {active[index]}</div>
                                <FeedbackButton
                                    title="ID kopieren"
                                    className="list-link"
                                    onClick={() => copy(active[index])}
                                >
                                    <ContentCopyOutlinedIcon className='table-icon'/>
                                </FeedbackButton>
                            </div>
                            : ''
                    }

                </div>
                )
            }
        </>
    );
};

const mapStateToProps = (state, ownProps) => {
    const query = defaults(state.entities.breadcrumbs.query[ownProps.endpoint], {
        filter: {}
    });

    let crumbs =
    ownProps.endpoints.map(ep =>
        state.entities.breadcrumbs.result[ep]
    );

    return {
        ...query,
        crumbs
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        queryBreadcrumbsAll: query => ownProps.endpoints.map(ep => dispatch(queryBreadcrumbs(ep), query)),
        queryBreadcrumbs: (query, index) => dispatch(queryBreadcrumbs(ownProps.endpoints[index], query))
    }
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        onFilterChange(filter, index) {
            return dispatchProps.queryBreadcrumbs(filter, index);
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Breadcrumbs);
