import React from 'react';
import {Col, Row} from 'react-bootstrap';
import * as PropTypes from 'prop-types';
import FeedbackButton from '../common/FeedbackButton';
import FormikAsyncTypeaheadInput from "../common/formik/FormikAsyncTypeaheadInput";
import Api from "../../api";
import AddIcon from '@mui/icons-material/Add';

const api = new Api();

const FormPlacePoolsAdd = ({subformik, blockIndex}) => {
    return (
        <>
            <FeedbackButton
                className='mt-3 mb-3'
                onClick={() => subformik.submitForm()}
                icon={<AddIcon />}
            >
                hinzufügen
            </FeedbackButton>
            <div>
                <FormikAsyncTypeaheadInput
                    id={'placePool' + blockIndex}
                    label="Platzpools der Plätze"
                    onSearch={query => api.getPlacePoolsByQuery(query)}
                    labelKey={o => o.name}
                    minLength={0}
                />
            </div>
        </>
    )

};

FormPlacePoolsAdd.propTypes = {
    subformik: PropTypes.any,
    blockIndex: PropTypes.any,
};

export default FormPlacePoolsAdd;