import React, {Component} from 'react';
import * as yup from 'yup';
import {Formik} from 'formik';
import {Card, Col, Row} from 'react-bootstrap';
import {FormikTextInputGroup} from '../../../common/formik/FormikTextInputGroup';
import * as PropTypes from 'prop-types';
import FeedbackButton from '../../../common/FeedbackButton';
import FormikAsyncTypeaheadInput from '../../../common/formik/FormikAsyncTypeaheadInput';
import Api from '../../../../api';
import FormPlacePools from './FormPlacePools';
import {memoize} from '../../../../form/fieldValidation';
import debounce from 'debounce-promise';
import BackButton from '../../../common/BackButton';
import Footer from '../../../layout/Footer';
import {connect} from 'react-redux';
import MultiSelectList from '../../../common/form/MultiSelectList';
import styles from "../../../form.module.scss";

const api = new Api();

const getInitialValues = (type, venuePlanId, usedPlacePools) => ({
    label: {
        de: '',
        en: ''
    },
    backendNameShort: '',
    pricingCategory: '',
    entrance: {
        de: '',
        en: ''
    },
    blockType: type,
    venuePlan: {id: venuePlanId},
    usedPlacePools: usedPlacePools,
    placePools: [],
    zukoZones: [],

});


const FORM_SCHEMA = yup.object().shape({
    label: yup.object().shape({
        de: yup.string().required(),
        en: yup.string(),
    }),
    backendNameShort: yup.string().required(),
    zukoZones: yup.array(),
    pricingCategory: yup.object().nullable(),
});

export const getBlockGroups = (block, blockGroups) => {
    if (block.blockGroups && block.blockGroups.length > 0) {
        return (<Row><Col><p>Gehört zu den Blockgruppen {blockGroups.join(', ')}</p></Col></Row>)
    }
};

class Form extends Component {

    render() {
        let {onSubmit, submitPending, block, type, venuePlanId, helpTextsVisible, venue} = this.props;
        const blockGroups = block.blockGroups ? Object.values(block.blockGroups).map(blockGroup => blockGroup.backendNameShort) : [];
        const usedPlacePools = block.usedPlacePools ? block.usedPlacePools : [];
        const getBlockByBackendNameShortDebounced = debounce((backendNameShort, venuePlanId) => api.getBlockByBackendNameShort(backendNameShort, venuePlanId), 500);

        const validateBackendNameShort = memoize({
            initialValue: block.backendNameShort,
            async validate(backendNameShort) {
                // Wenn der backendNameShort leer ist brauchen wir nicht die API zu fragen.
                if (!backendNameShort) return;

                const responseBlock = await getBlockByBackendNameShortDebounced(backendNameShort, venuePlanId);
                if (responseBlock && responseBlock.id !== block.id) {
                    return 'Der Kurztitel des Blocks wird bereits verwendet'
                }
            }
        });

        return (
            <Formik initialValues={{...getInitialValues(type, venuePlanId, usedPlacePools), ...block}}
                    validationSchema={FORM_SCHEMA}
                    onSubmit={onSubmit}
            >
                {formik => {
                    const onSubSubmit = (values, {resetForm}) => {
                        values.placePool
                        && formik.setFieldValue('placePools', [...formik.values.placePools, {
                            placePool: {
                                id: values.placePool.id,
                                name: values.placePool.name
                            },
                            capacity: values.capacityPlacePool
                        }]);
                        // Resetten der form, damit Werte auf Initial Values gesetzt werden
                        resetForm();
                    };

                    const validateCapacity = (capacity) => {
                        if ((formik.values.placePools.reduce((a, b) => a + (b['capacity'] || 0), 0)) > capacity) {
                            return 'Die Anzahl der Stehplätze darf nicht kleiner sein als die Anzahl der Stehplätze in den zugehörigen Platzpools.'
                        }
                    };

                    const zukoZoneSelectFieldMap = {
                        'entry': 'Zuko-Zone',
                        'label': 'Zutrittszone im Ticketing'
                    };

                    const handleZukoZoneSelectionChange = (zukoZoneSelection) => {
                        const selectedZukoZones = zukoZoneSelection.filter(selection => typeof (selection) === 'object');
                        formik.setFieldValue('zukoZones', selectedZukoZones);
                    };

                    return (
                        <form onSubmit={formik.handleSubmit} className={styles.formCotainer}>
                            <div className={styles.formBox}>
                                <h2 className={styles.formTitle}>Allgemein</h2>
                                <Row className="mt-3">
                                    <Col className="col-md-4">
                                        <FormikTextInputGroup label="Titel des Blocks (deutsch)*" name="label.de" testid="name_german"
                                                                helpTextsVisible={helpTextsVisible} helpText="Dieser Name wird auf deutschsprachigen Tickets angezeigt."/>
                                    </Col>
                                    <Col className="col-md-4">
                                        <FormikTextInputGroup label="Titel des Blocks (englisch)" name="label.en" testid="name_english"
                                                                helpTextsVisible={helpTextsVisible} helpText="Dieser Name wird auf englischsprachigen Tickets angezeigt."/>
                                    </Col>
                                    <Col className="col-md-4">
                                        <FormikTextInputGroup label="Kurztitel des Blocks*"
                                                                name="backendNameShort"
                                                                validate={validateBackendNameShort} testid="shortName"
                                                                helpTextsVisible={helpTextsVisible} helpText="Dieser Name wird nur im Backend benutzt."/>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col className="col-md-4">
                                        <FormikTextInputGroup label="Eingangshinweis (deutsch)" name="entrance.de" testid="entrance_german"
                                                                helpTextsVisible={helpTextsVisible} helpText="Hinweis, welcher Eingang verwendet werden kann, wird auf dem Ticket angezeigt."/>
                                    </Col>
                                    <Col className="col-md-4">
                                        <FormikTextInputGroup label="Eingangshinweis (englisch)"
                                                                name="entrance.en" testid="entrance_english"
                                                                helpTextsVisible={helpTextsVisible} helpText="Hinweis, welcher Eingang verwendet werden kann, wird auf dem Ticket angezeigt."/>
                                    </Col>
                                    <Col className="col-md-4">
                                        <FormikAsyncTypeaheadInput
                                            id="pricingCategory"
                                            label={this.props.type === 'standing' ? "Platzkategorie der Stehplätze" : "Platzkategorie für alle Plätze des Blocks ändern"}
                                            onSearch={query => api.getPricingCategoriesByQuery(query)}
                                            labelKey={o => o.name}
                                            minLength={0}
                                            testid="pricingCategory"
                                        />
                                    </Col>
                                </Row>
                            </div>
                            {this.props.type === 'seating' && (
                                <div className={styles.formBox}>
                                    <h2 className={styles.formTitle}>Platzpools</h2>
                                    <Row className="mt-3">
                                        <Col className="col-md-8">
                                            <FormPlacePools formik={formik} onSubSubmit={onSubSubmit}/>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                            {(
                                <div className={styles.formBox}>
                                    <h2 className={styles.formTitle}>Zuko-Steuerung</h2>
                                    <p>Nehmen Sie hier die notwendigen Einstellungen vor, um das Zutrittskontrollsystems für diesen Block zu steuern</p>
                                    <Row className="mt-3">
                                        <Col className="col-md-8">
                                            <h2 className="font-weight-bold pb-3 mb-3">Automatisch gestattete Zutrittszonen für alle Tickets dieses Blocks</h2>
                                            <p className={!helpTextsVisible ? 'hidden' : 'text-muted'}>Absolut jedes Ticket für diesen Block erhält Zutritt zu den hier
                                                eingetragenen Zutrittszonen.</p>
                                            <MultiSelectList fieldMap={zukoZoneSelectFieldMap}
                                                                elements={venue.zukoZones}
                                                                selected={block.zukoZones}
                                                                onChange={selection => handleZukoZoneSelectionChange(selection)}/>
                                        </Col>
                                    </Row>
                                </div>
                            )}

                            {getBlockGroups(block, blockGroups)}

                            <Footer>
                                {/*
                            FIXME: auf Grund der aktuellen Routenstruktur ist es hier nicht möglich den Link Button
                            zu verwenden um in die Edit-Seite eines Saalplans/Listing der Blockgruppen zu gelangen,
                            da die Venue-ID hier nicht bekannt ist und sowohl die VenuePlan-ID als auch die
                            BlockGroup-ID aus der URL gepuhlt werden.
                            Der Browserback ist nicht in allen Fällen korrekt (bspw. nicht bei externen Einsprüngen)
                            und die Problematik sollte im Zuge der "Breadcrums" behoben werden.
                            */}
                                {/*<LinkButton variant="link" to={{pathname: `/base/venue/${venueId}/venue-plan/${venuePlanId}`}} >*/}
                                {/*    Abbrechen*/}
                                {/*</LinkButton>*/}
                                <BackButton>
                                    Abbrechen
                                </BackButton>
                                <FeedbackButton
                                    onClick={() => formik.submitForm()}
                                    busy={submitPending}
                                >
                                    Speichern
                                </FeedbackButton>
                            </Footer>
                        </form>
                    )
                }}
            </Formik>
        )
    }
}

Form.propTypes = {
    onSubmit: PropTypes.any,
    submitPending: PropTypes.any,
    block: PropTypes.any,
    requestErrors: PropTypes.any,
    type: PropTypes.any,
    venuePlanId: PropTypes.any,
};


const mapStateToProps = (state, props) => {

    const helpTextsVisible = state.helpTextsToggle;

    return {
        helpTextsVisible
    }
};

export default connect(mapStateToProps)(Form);
