import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VenuePlanDisplay from './display/VenuePlanDisplay';
import { Seat, SeatColor, SeatInArrangement, SeatRecord } from '../types';
import { initLoad } from './display/resource';
import { useRouteMatch } from 'react-router-dom';
import { InteractionCallback } from './display/interaction/event';
import {switchToSerialClient, actions as venueEditorActions} from '../../state/entities/venueEditor';
import { IState, selectAllSeats, selectBlocks, selectPlaceCategories, selectPlacepoolDefinitions,
    selectSeatingTypes, selectImages, selectInteractionMode, selectFatalAPIError,
    selectVenuePlanSettings, selectIsSeatsLoaded, selectNewAddedSeats, selectVenuePlanIsLoaded,
    selectVenuePlanSettingsIsLoaded, selectAreaFormsIsLoaded, selectAreaForms, selectMoveOnGrid,
    selectIsApiRequestPending, selectNewAddedAreaForms }
    from '../../state/entities/venueEditor/selectors';
import { LoadingIndicator } from "../../components/common/LoadingIndicator";
import BaseLayout from '../baseLayout/BaseLayout';
import { TabPlacepoolChangeFunc } from '../baseLayout/TabPlacePools';
import { determinePrimaryPlacepoolForSeat } from './display/data/common';
import { SEAT_DEFAULT_COLOR } from './display/scene';
import { IPlacepool } from '../types/Placepool';
import { ImageData } from './display/images/ImageData';
import AddSeatsDialog from '../addSeatsDialog/AddSeatsDialog';
import AddBlockAreaDialog from '../addBlockAreaDialog/AddBlockAreaDialog';
import { PointGrid } from './geometry';
import { convert2RowLabelVisibilityMode } from './display/labels/rows';
import InfoBar from '../infoBar/InfoBar';
import ToolbarUtils from '../toolBar/ToolbarUtils';
import ToolbarUtilsForZoom from '../toolBar/ToolbarUtilsForZoom';
import { Polygon } from 'pixi.js';
import { AreaFormData } from './display/areaForms/AreaFormData';
import { InteractionMode } from './display/interaction';



type AddSeatsDialogControlType = {
    isDialogShown: boolean,
    grid: Readonly<PointGrid>
}

type AddAreaFormDialogControlType = {
    isDialogShown: boolean,
    shape: Polygon
}


/**
 * Wandelt einen Hexadezimal-String in einen Integer-Wert um, der als Farbwert verwendet werden kann.
 *
 * @param color Der zu parsende string.
 *
 * @return Der durch den color-string repräsentierte Integer-Wert, oder SEAT_DEFAULT_COLOR,
 *  wenn der string nicht als Hexadezimal-Wert geparsed werden kann.
 */
export function parseSeatColor(color: string): SeatColor {
    return parseInt(color, 16) || SEAT_DEFAULT_COLOR;
}

/**
 * Bestimmte die für einen Platz anzuzeigenden Farbe in Abhängigkeit der zugewiesenen tags.
 *
 * @param seatTags Die zu berücksichtigenden tag.
 * @param tagDefinitions Die zu berücksichtigenden Tag-Definitionen.
 */
export function determineSeatColor(seatTags: string[], tagDefinitions: IPlacepool[]): SeatColor {
    const primaryTag = determinePrimaryPlacepoolForSeat(seatTags, tagDefinitions);
    return parseSeatColor((primaryTag && primaryTag?.color) ?? SEAT_DEFAULT_COLOR.toString(16));
}


const SeatingEditor: React.FC = () => {
    const dispatch = useDispatch();
    const venuePlanIsLoaded = useSelector((state: IState) => selectVenuePlanIsLoaded(state));
    const venuePlanSettingsIsLoaded = useSelector((state: IState) => selectVenuePlanSettingsIsLoaded(state));
    const venuePlanSettings = useSelector((state: IState) => selectVenuePlanSettings(state));
    const isApiRequestPending = useSelector((state: IState) => selectIsApiRequestPending(state));
    const placepoolDefinitions = useSelector((state: IState) => selectPlacepoolDefinitions(state));
    const placeCategories = useSelector((state: IState) => selectPlaceCategories(state));
    const seatingTypes = useSelector((state: IState) => selectSeatingTypes(state));
    const blocks = useSelector((state: IState) => selectBlocks(state));
    const allSeats = useSelector((state: IState) => selectAllSeats(state));
    const newAddedSeats = useSelector((state: IState) => selectNewAddedSeats(state));
    const isSeatsLoaded = useSelector((state: IState) => selectIsSeatsLoaded(state));
    const images = useSelector((state: IState) => selectImages(state));
    const [seatsLoadStarted, setSeatsLoadStarted] = useState(false);
    const [isDisplay, setIsDisplay] = useState(false);
    const interactionMode = useSelector((state: IState) => selectInteractionMode(state));
    const moveOnGrid = useSelector((state: IState) => selectMoveOnGrid(state));
    const areaFormsIsLoaded = useSelector((state: IState) => selectAreaFormsIsLoaded(state));
    const areaForms = useSelector((state: IState) => selectAreaForms(state));
    const fatalAPIError = useSelector((state: IState) => selectFatalAPIError(state));
    const [isAddSeatsDialogShown, setIsAddSeatsDialogShown] = useState({isDialogShown: false, grid: undefined} as AddSeatsDialogControlType);
    const [isAddAreaFormDialogShown, setIsAddAreaFormDialogShown] = useState({isDialogShown: false, shape: new Polygon()} as AddAreaFormDialogControlType)
    const newAddedAreaForms = useSelector((state: IState) => selectNewAddedAreaForms(state));

    const canvas = useRef<HTMLCanvasElement>(null);
    const display = useRef<VenuePlanDisplay>();
    const { vpid } = useRouteMatch<{
        vpid: string;
      }>().params;

    let neededWidthForSideBar = 0;

    // Dieser Callback wird als ref deklariert, da wir diesen innerhalb eines InteractionCallback für VenuePlanDisplay
    // verwenden wollen, welcher dann außerhalb der react render-cycles stattfindet und andernfalls immer nur die
    // initial übergebene Deklaration verwenden würde, ungeachtet zwischenzeitlicher state Änderungen.
    const handleInteractionEvent = useRef<InteractionCallback>(() => {
        // initial ein noop, wird durch useEffect(setupInteractionEventHandler()) initi-/aktualisiert.
    });


    useEffect(() => {
        setIsDisplay(false);
        initLoad(() => {});
        switchToSerialClient();
        //@ts-ignore
        dispatch(venueEditorActions.loadVenuePlan(vpid));
        //@ts-ignore
        dispatch(venueEditorActions.loadVenuePlanSettings(vpid));
        //@ts-ignore
        dispatch(venueEditorActions.loadPlacepoolDefinitions(vpid));
        dispatch(venueEditorActions.loadPlaceCategories());
        dispatch(venueEditorActions.loadSeatingTypes());
        //@ts-ignore
        dispatch(venueEditorActions.loadBlocks(vpid));
        //@ts-ignore
        dispatch(venueEditorActions.loadImages(vpid));
        //@ts-ignore
        dispatch(venueEditorActions.loadAreaForms(vpid));
    }, [dispatch, vpid]);


    useEffect(() => {
        if (
            !venuePlanIsLoaded ||
            !venuePlanSettingsIsLoaded ||
            !placepoolDefinitions ||
            !placeCategories ||
            !seatingTypes ||
            !blocks ||
            !images ||
            !areaFormsIsLoaded ||
            seatsLoadStarted
        ) return;
        setSeatsLoadStarted(true);
        //@ts-ignore
        dispatch(venueEditorActions.loadAllSeats(vpid));
    }, [dispatch, venuePlanIsLoaded, venuePlanSettingsIsLoaded, vpid, placepoolDefinitions,
        placeCategories, seatingTypes, blocks, images, areaFormsIsLoaded, seatsLoadStarted]);


    useEffect(() => {
        if (isDisplay) return;
        if (!isSeatsLoaded) return;
        if (!canvas.current) return;
        display.current = new VenuePlanDisplay(canvas.current);
        display.current.onInteraction = (event) => handleInteractionEvent.current(event);
        window.addEventListener('resize', () => {
            display.current.resizeRenderer(canvas.current.offsetWidth, canvas.current.offsetHeight);
        });
        const seats: SeatRecord = allSeats;
        handleInteractionEvent.current = (event) => {
            switch (event.type) {
                case 'SEATS_ADD_REQUESTED':
                    setIsAddSeatsDialogShown({isDialogShown: true, grid: event.pointGrid});
                    return;
                case 'SEATS_ADDED':
                    //TODO: für neue Sitze relevante Defaultwerte oder eingestellte Werte setzen
                    let newSeats: Seat[] = event.seats.map(seat => {
                        let newSeat = {
                            ...seat,
                            pricingCategoryId: placeCategories[0].id,
                            seatingTypeId: seatingTypes[0].id
                        };
                        return newSeat;
                    });
                    //@ts-ignore
                    dispatch(venueEditorActions.addSeats(newSeats));
                    //@ts-ignore
                    dispatch(venueEditorActions.setInteractionMode("SELECT"));
                    display.current.setSelectedSeats(newSeats);
                    //@ts-ignore
                    dispatch(venueEditorActions.setSelectedSeats(newSeats));
                    return;
                case 'SEATS_UPDATED':
                    // Die Sitzplätze der aktuellen Auswahl haben sich evtl. geändert.
                    //@ts-ignore
                    dispatch(venueEditorActions.updateSomeSeats(event.seats));
                    return;
                case 'SEATS_DELETED':
                    //@ts-ignore
                    dispatch(venueEditorActions.deleteSeats(event.seats));
                    return;
                 case 'SEATS_SELECTED':
                    let selSeats = display.current?.getSelectedSeats();
                    if (selSeats?.length) {
                        console.log("seat: x " + selSeats[0].x + ", y " + selSeats[0].y);
                    }
                    //@ts-ignore
                    dispatch(venueEditorActions.setSelectedSeats(display.current?.getSelectedSeats()?.map(makeSeatInArrangement)));
                    return;
                 case 'SEATS_UNSELECTED':
                    //@ts-ignore
                    dispatch(venueEditorActions.setSelectedSeats(display.current?.getSelectedSeats()?.map(makeSeatInArrangement)));
                    return;
            }
        }

        display.current.addSeats(seats);
        setIsDisplay(true);
        display.current.getImagesManager().initItems(images);
        display.current.getAreaFormsManager().initItems(Object.values(areaForms));
        display.current.zoomToFull(neededWidthForSideBar);
    }, [canvas, allSeats, isSeatsLoaded, isDisplay, dispatch, seatingTypes, placeCategories,
        images, areaForms, neededWidthForSideBar]);



    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (!isApiRequestPending) return;
            event.preventDefault();
            event.returnValue = 'Änderungen werden gerade gespeichert. Diese gehen eventuell verloren.\nWirklich schließen?';
        }
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        };
    }, [isApiRequestPending]);


    useEffect(() => {
        if (!isDisplay) return;  //don't execute on initialization
        display.current.updateSeats(Object.values(allSeats));
    }, [allSeats, isDisplay]);


    // Anzeige der Labels steuern
    useEffect(() => {
        if (venuePlanSettings && display.current) {
            display.current.rowLabelVisibilityMode = convert2RowLabelVisibilityMode(
                venuePlanSettings.backendSettings.showRowNumberAtBeginningOfRow,
                venuePlanSettings.backendSettings.showRowNumberAtEndOfRow);
            display.current.showSeatLabels = venuePlanSettings.backendSettings.showSeatLabels;
        }
    }, [venuePlanSettings, isDisplay]);


    useEffect(() => {
        if (!display.current) return;
        display.current.interactionMode = interactionMode;
    }, [interactionMode, isDisplay]);


    useEffect(() => {
        if (newAddedSeats.length === 0) return;
        const newSeatsMap = newAddedSeats.reduce((allSeats: SeatRecord, seat: Seat) => {
            allSeats[seat.id] = seat;
            return allSeats;
        }, {} as SeatRecord)
        display.current.addSeats(newSeatsMap);
        //@ts-ignore
        dispatch(venueEditorActions.setInteractionMode(InteractionMode.SELECT));
        display.current.setSelectedSeats(Object.values(newAddedSeats));
        dispatch(venueEditorActions.purgeNewAddedSeats());
        setIsAddSeatsDialogShown({isDialogShown: false, grid: undefined});
    }, [newAddedSeats, dispatch]);


    useEffect(() => {
        if (Object.keys(newAddedAreaForms).length === 0) return;
        for (const aF of Object.values(newAddedAreaForms)) display.current.getAreaFormsManager().addItem({...aF});
        //@ts-ignore
        dispatch(venueEditorActions.setInteractionMode(InteractionMode.AREAFORMS));
        //@ts-ignore
        dispatch(venueEditorActions.removeFromNewAddedAreaForms(newAddedAreaForms));
        setIsAddAreaFormDialogShown({isDialogShown: false, shape: undefined});
    }, [newAddedAreaForms, dispatch]);


    useEffect(() => {
        if (!isDisplay) return;
        display.current.snapToGrid = moveOnGrid;
    }, [moveOnGrid, isDisplay])


    useEffect(() => {
        if (!fatalAPIError) return;
        // eslint-disable-next-line no-restricted-globals
        confirm("Folgender Fehler ist aufgetreten:\n" + fatalAPIError + "\n\nDie Seite wird neu geladen.");
        // eslint-disable-next-line no-restricted-globals
        location.reload();        
    }, [fatalAPIError]);



    const makeSeatInArrangement = (seat: Seat): SeatInArrangement => {
        // Über das "Seat" Interface haben wir nur Zugriff auf die hier relevanten Felder,
        // wird jedoch versucht Sitzplätze zu JSON zu serialisieren für den API Request
        // werden auch alle evtl. noch vorhandenen private properties mit betrachtet,
        // wodurch es zu Problemen kommen kann, weil die Struktur (die assoziierten pixi.js Daten)
        // zirkulär ist. Daher hier nur die unmittelbar relevanten Informationen extrahieren.
        const seatInArrangement: SeatInArrangement = {
            id: seat.id,
            x: seat.x,
            y: seat.y,
            tags: seat.tags,
            area: seat.area,
            row: seat.row,
            label: seat.label,
            enabled: seat.enabled,
            available: seat.available,
            pricingCategoryId: seat.pricingCategoryId
        };
        return seatInArrangement;
    }

    const handleSeatsDelete = () => {
        display.current?.deleteSelectedSeats();
    }
    
    /**
     * This is only called by change operations from outside the editor, because it will
     * also send the updates to the editor.
     * Changes from inside the editor will be caught in handleInteractionEvent
     * @param seats 
     */
    const handleSeatsChanged: TabPlacepoolChangeFunc = (seats, tagsChanged) => {
        if (tagsChanged) {
            seats.forEach(seat => {
                seat.color = determineSeatColor(seat.tags, placepoolDefinitions);
            });
        }
        dispatch(venueEditorActions.updateSomeSeats(seats));
    }

    //move these functions also direct into the images panel? or make an ImagesDataHandler class?
    const handleImagesAdded = (images: ImageData[]) => {
        dispatch(venueEditorActions.addImages(images));
    }

    const handleImagesChanged = (images: ImageData[]) => {
        dispatch(venueEditorActions.updateImages(images));
    }

    const handleImagesDeleted = (images: ImageData[]) => {
        dispatch(venueEditorActions.deleteImages(images));
    }

    const handleNeededWidthChanged = (width: number) => {
        neededWidthForSideBar = width;
    }

    const handleZoomToFull = (e) => {
        display.current.zoomToFull(neededWidthForSideBar);
    }


    const handleRequestAddAreaForm = (rect: Polygon) => {
        setIsAddAreaFormDialogShown({isDialogShown: true, shape: rect});
    }


    const handleAddSeatsDialogClose = (okClicked: boolean, newSeats: SeatRecord = undefined) => {
        display.current.removePreliminarySeats();
        if (okClicked) {
            dispatch(venueEditorActions.addSeats(Object.values(newSeats)));
        } else {
            setIsAddSeatsDialogShown({isDialogShown: false, grid: undefined});
        }
    }


    const handleAddAreaFormDialogClose = (okClicked: boolean, data: AreaFormData = undefined) => {
        if (okClicked) {
            dispatch(venueEditorActions.addAreaForm(data));
        } else {
            setIsAddAreaFormDialogShown({isDialogShown: false, shape: undefined});
        }
    }

    if (isDisplay) {
        display.current.getImagesManager().onItemsAdded = handleImagesAdded;
        display.current.getImagesManager().onItemsChanged = handleImagesChanged;
        display.current.getImagesManager().onItemsDeleted = handleImagesDeleted;
        display.current.getAreaFormsManager().onRequestAddAreaForm = handleRequestAddAreaForm;
    }


    if (!isSeatsLoaded) return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
            <LoadingIndicator disableShrink />
        </div>
    );


    return (
        <>
            <div className="display-wrapper">
                <canvas className="display" ref={canvas} />
            </div>
            <BaseLayout
                onSelectedSeatsChanged={handleSeatsChanged}
                imagesManager={display.current?.getImagesManager()}
                areaFormsManager={display.current?.getAreaFormsManager()}
                venuePlanId={vpid}
                onNeededWidthChanged={handleNeededWidthChanged}
            />
            <ToolbarUtils
                venuePlanId={vpid}
                imagesManager={display.current?.getImagesManager()}
                areaFormsManager={display.current?.getAreaFormsManager()}
                onSeatsDelete={handleSeatsDelete}
            />
            {/* <ToolbarUtilsForZoom onZoomToFull={handleZoomToFull} /> Prio changed, work on it later */}
            <InfoBar onZoomToFull={handleZoomToFull}/>
            <AddSeatsDialog
                open={isAddSeatsDialogShown.isDialogShown}
                pointGrid={isAddSeatsDialogShown.grid}
                onConfirm={(newSeats) => handleAddSeatsDialogClose(true, newSeats)}
                onCancel={() => handleAddSeatsDialogClose(false)}
            />
            <AddBlockAreaDialog
                open={isAddAreaFormDialogShown.isDialogShown}
                shape={isAddAreaFormDialogShown.shape}
                onConfirm={(data: AreaFormData) => handleAddAreaFormDialogClose(true, data)}
                onCancel={() => handleAddAreaFormDialogClose(false)}
            ></AddBlockAreaDialog>
        </>
    )
}

export default SeatingEditor;
