import React from 'react';
import {Col, Row} from 'react-bootstrap';
import * as PropTypes from 'prop-types';
import FeedbackButton from '../common/FeedbackButton';
import {FormikSelect} from "../common/formik/FormikSelect";
import AddIcon from '@mui/icons-material/Add';

const FormStandingBlocksAdd = ({formik, subformik, standingBlocks}) => {
    const getItems = (standingBlocks) => {
        if (!standingBlocks || !standingBlocks.venuePlanStandingBlocks) {
            return [];
        }

        const currentBlocks = formik.values.standingBlocks.map(item => {
            return item.block.id;
        })

        return (standingBlocks.venuePlanStandingBlocks.map(item => {
            const pricingCategory = item.pricingCategory ? `(${item.pricingCategory.name})` : "";

            return {
                id: item.id,
                name: `${item.label.de} ${pricingCategory}`,
                blockId: item.de,
            }
        }).filter(item => !currentBlocks.includes(item.id)))
    }

    return (
        <>
            <FeedbackButton
                className='mb-3'
                onClick={() => subformik.submitForm()}
                icon={<AddIcon />}
            >
                hinzufügen
            </FeedbackButton>
            <div>
                <FormikSelect
                    name="standingBlock"
                    label="Stehplatzblock"
                    emptyValueLabel="Bitte wählen..."
                    options={getItems(standingBlocks)}
                />
            </div>
        </>
    )

};

FormStandingBlocksAdd.propTypes = {
    formik: PropTypes.any,
    subformik: PropTypes.any,
    standingBlocks: PropTypes.object,
};

export default FormStandingBlocksAdd;



