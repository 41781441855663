import React from 'react';
import CropFreeOutlinedIcon from '@mui/icons-material/CropFreeOutlined';
import { Divider, IconButton, Tooltip, Typography, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { IState, selectVenuePlan } from '../../state/entities/venueEditor/selectors';
import styles from "./infoBar.module.scss";



interface IInfoBarProps {
  onZoomToFull: Function;
}


const InfoBar: React.FC<IInfoBarProps> = ({onZoomToFull}) => {
  const venuePlan = useSelector((state: IState) => selectVenuePlan(state));

  return (
    <Box className={styles.infoBar}>
      <Typography className={styles.infoText} variant="body1" sx={{flexGrow: 1}}>Venue-Plan: {venuePlan.name}</Typography>
      <Divider orientation="vertical" flexItem />
      <Tooltip title="Vollansicht">
          <IconButton className={styles.zoomButton} onClick={() => onZoomToFull()}>
              <CropFreeOutlinedIcon />
          </IconButton>
      </Tooltip>
    </Box>
  );
}

export default InfoBar;
