import React, {Component} from 'react';
import * as yup from 'yup';
import {Formik} from 'formik';
import { Tab, Tabs } from '@mui/material';
import * as PropTypes from 'prop-types';
import FeedbackButton from '../common/FeedbackButton';
import Footer from '../layout/Footer';
import {connect} from 'react-redux';
import List from './venuePlan/List';
import ListZuko from './zukoZone/List';
import FormGeneralData from './FormGeneralData';
import TabsPanel from '../common/TabsPanel/TabsPanel';
import styles from "../form.module.scss";

const INITIAL_VALUES = {
    name: '',
    logo: null,
    legalContact: {
        salutation: null,
        firstName: null,
        lastName: null,
        address1: null,
        address2: null,
        companyName: null,
        postalCode: null,
        city: null,
        phone: {
            countryCode: null,
            phoneNumber: null
        },
        mobile: {
            countryCode: null,
            phoneNumber: null
        },
        country: 'DE',
        email: null
    },
};

const FORM_SCHEMA = yup.object().shape({
    name: yup.string().required(),
    logo: yup.object().shape({
        id: yup.string(),
        url: yup.string().url(),
        title: yup.string(),
    }).nullable(),
    legalContact: yup.object().shape({
        salutation: yup.string().nullable(),
        firstName: yup.string().max(255).nullable(),
        lastName: yup.string().max(255).nullable(),
        address1: yup.string().required().max(255),
        address2: yup.string().max(255),
        companyName: yup.string().max(255).nullable(),
        postalCode: yup.string().required().max(6),
        city: yup.string().required().max(48),
        country: yup.string().required(),
        phone: yup.object().shape({
            countryCode: yup.string().nullable(),
            phoneNumber: yup.string().matches(/^\d{1,12}$/, 'Invalid phone number').nullable()
        }),
        mobile: yup.object().shape({
            countryCode: yup.string().nullable(),
            phoneNumber: yup.string().matches(/^\d{1,12}$/, 'Invalid phone number').nullable()
        }),
        email: yup.string().email('Invalid email').nullable()
    }),
});


class Form extends Component {

    state = {currentTab: 'home'}


    render() {
        let {onSubmit, submitPending, venue, helpTextsVisible} = this.props;

        const handleSubmit = (values, actions) => {
            const getLegalContact = values.legalContact;
            onSubmit({ ...values, invoiceContact: getLegalContact }, actions);
        }

        return <Formik initialValues={{...INITIAL_VALUES, ...venue}}
                       validationSchema={FORM_SCHEMA}
                       onSubmit={handleSubmit}
        >
            {formik => {
                return (
                    <form onSubmit={formik.handleSubmit} className={styles.formCotainer}>
                        <Tabs
                            id="uncontrolled-tab-example"
                            value={this.state.currentTab}
                            onChange={(event, newTab) => this.setState({ currentTab: newTab})}
                        >
                            <Tab value="home" label="Allgemein" />
                            <Tab value="venuePlanList" label="Saalpläne" />
                            <Tab value="zukoZones" label="Zuko-Zonen" />
                        </Tabs>

                        <div className={styles.tabsContentWrapper}>
                            <TabsPanel value={this.state.currentTab} index="home" data-event-key='home'>
                                <FormGeneralData
                                    formik={formik}
                                    initialValues={INITIAL_VALUES}
                                    validationSchema={FORM_SCHEMA}
                                    helpTextsVisible={helpTextsVisible}
                                />
                            </TabsPanel>
                            <TabsPanel value={this.state.currentTab} index="venuePlanList" data-event-key='venuePlanList'>
                                <List venue={venue} venueId={venue.id}/>
                            </TabsPanel>
                            <TabsPanel value={this.state.currentTab} index="zukoZones" data-event-key='zukoZones'>
                                <ListZuko venue={venue} helpTextsVisible={helpTextsVisible}/>
                            </TabsPanel>
                        </div>
                        <Footer>
                            <FeedbackButton to={`/base/venue`}>
                                Abbrechen
                            </FeedbackButton>
                            <FeedbackButton
                                type="submit"
                                busy={submitPending}
                            >
                                Speichern
                            </FeedbackButton>
                        </Footer>
                    </form>
                )
            }}
        </Formik>
    }
}

Form.propTypes = {
    onSubmit: PropTypes.any,
    submitPending: PropTypes.any,
    venue: PropTypes.any,
    helpTextsVisible: PropTypes.bool
};


const mapStateToProps = (state, props) => {
    const helpTextsVisible = state.helpTextsToggle;

    return {
        helpTextsVisible
    }
};

export default connect(mapStateToProps)(Form);
