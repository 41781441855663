import React from 'react';
import {Col, Row} from 'react-bootstrap';
import * as PropTypes from 'prop-types';
import FeedbackButton from '../common/FeedbackButton';
import AddIcon from '@mui/icons-material/Add';
import {FormikSelect} from "../common/formik/FormikSelect";

const FormVenueEventsAdd = ({formik, subformik, metaEventStatus}) => {
    const getItems = (metaEventStatus) => {
        if (!metaEventStatus || !metaEventStatus.venueEvents) {
            return [];
        }

        return (metaEventStatus.venueEvents.map(item => {
            return {
                id: item.id,
                name: `${item.title.de}`,
                venuePlan: item.venuePlan && item.venuePlan.id ? item.venuePlan.id : null,
                eventSeries: item.eventSeries ? item.eventSeries : null,
                venue: item.venue ? item.venue : null,
            }
        }))
    }

    return (
        <>
            <Row>
                <Col className='col-md-4 mb-2'>
                    <FeedbackButton
                        icon={<AddIcon />}
                        className='mb-2'
                        onClick={() => subformik.submitForm()}
                    >
                        hinzufügen
                    </FeedbackButton>
                </Col>
            </Row>
            <Row>
                <Col className="col-md-4">
                    <FormikSelect
                        name="venueEvent"
                        label="Event hinzufügen"
                        options={getItems(metaEventStatus)}
                        emptyValueLabel="Bitte wählen ..."
                    />
                </Col>
            </Row>
        </>
    )

};

FormVenueEventsAdd.propTypes = {
    formik: PropTypes.any,
    subformik: PropTypes.any,
    metaEventStatus: PropTypes.object,
};

export default FormVenueEventsAdd;



